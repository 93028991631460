import { UserPreferences, setUserPreferences } from "@ewibecom/sdk";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { EwibeSDK as sdk } from "config";
import { AsyncState, defaultAsyncState } from "./utils";

interface AsyncStore {
  setPreferences: (preferences: UserPreferences) => void;
  getPreferences: () => Promise<UserPreferences | false>;
  updatePreferences: (preferences: UserPreferences) => Promise<boolean>;
  setUserPreferences: (preferences: setUserPreferences) => Promise<boolean>;
  reset: () => void;
}

export interface PreferencesStore extends AsyncState, AsyncStore {
  preferences?: UserPreferences;
}

export const usePreferencesStore = create<PreferencesStore>()(
  devtools(
    persist(
      (set) => ({
        error: null,
        isLoading: false,
        setPreferences: (preferences) => set({ preferences }),
        getPreferences: async (): Promise<UserPreferences | false> => {
          try {
            set({ isLoading: true });
            const preferences = await sdk.user.getPreferences();
            set({ preferences, error: null, isLoading: false });
            return preferences;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        updatePreferences: async (
          preferences: UserPreferences
        ): Promise<boolean> => {
          try {
            set({ isLoading: true });
            const resp = await sdk.user.updatePreferences(preferences);
            set({ preferences, error: null, isLoading: false });
            return !!resp;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        setUserPreferences: async (
          preferences: setUserPreferences
        ): Promise<boolean> => {
          try {
            set({ isLoading: true });
            await sdk.user.setUserPreferences(preferences);
            set({ error: null, isLoading: false });
            return true;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        reset: () => set({ ...defaultAsyncState, preferences: undefined }),
      }),
      {
        name: "ewibe-preferences-store",
        partialize: (state) => ({
          preferences: state.preferences,
          data: state.data,
        }),
      }
    )
  )
);
