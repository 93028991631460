import { Box, FileInput } from "@ewibecom/design-system";
import { UploadKYCRequest } from "@ewibecom/sdk";
import { useIsSmall } from "hooks";
import React, { useEffect, useState } from "react";
import useTranslate from "translate";
import * as yup from "yup";

export const schemaDocuments = yup.object().shape({
  identity_proof_recto: yup.string().required(),
  identity_proof_verso: yup.string().required(),
});

const DocumentsForm = ({
  setUploadKYC,
  validSizeRecto,
  validSizeVerso,
  setValidSizeRecto,
  setValidSizeVerso,
}: {
  setUploadKYC: React.Dispatch<
    React.SetStateAction<UploadKYCRequest | undefined>
  >;
  validSizeRecto: boolean;
  validSizeVerso: boolean;
  setValidSizeRecto: React.Dispatch<React.SetStateAction<boolean>>;
  setValidSizeVerso: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const small = useIsSmall();
  const t = useTranslate("verification.");
  const maxSize = 10485760;
  const [identyProofRecto, setIdentityProofRecto] = useState("");
  const [identyProofVerso, setIdentityProofVerso] = useState("");

  useEffect(() => {
    setUploadKYC({
      identity_proof_recto: identyProofRecto.replace(/data:.*base64,/, ""),
      identity_proof_verso: identyProofVerso.replace(/data:.*base64,/, ""),
    });
  }, [identyProofRecto, identyProofVerso, setUploadKYC]);

  const getEventFile = (
    e: React.ChangeEvent<HTMLInputElement> | undefined,
    setter: React.Dispatch<React.SetStateAction<boolean>>
  ): File | undefined => {
    if (e) {
      const files = e.target.files;
      if (files && files.length) {
        const file = files[0];
        if (file.size >= maxSize) {
          setter(false);
        }
        return file;
      }
    }
  };

  const getBase64Content = (
    file: File,
    setter: React.Dispatch<React.SetStateAction<string>>
  ) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setter(reader.result as string);
    };
  };

  return (
    <Box width="100%" gap="small" margin={{ bottom: small ? "1rem" : "0" }}>
      <FileInput
        name="identity_proof_recto"
        maxSize={maxSize}
        onChange={(e) => {
          const file = getEventFile(e, setValidSizeRecto);
          if (file) getBase64Content(file, setIdentityProofRecto);
          else {
            setIdentityProofRecto("");
            setValidSizeRecto(true);
          }
        }}
        uploaded={!!identyProofRecto}
        error={!validSizeRecto ? t("error_messages") : ""}
        messages={{
          dropPrompt: `${t("drag_front")}`,
          browse: `${t("browse")}`,
        }}
      />
      <FileInput
        name="identity_proof_verso"
        maxSize={maxSize}
        onChange={(e) => {
          const file = getEventFile(e, setValidSizeVerso);
          if (file) getBase64Content(file, setIdentityProofVerso);
          else {
            setIdentityProofVerso("");
            setValidSizeVerso(true);
          }
        }}
        uploaded={!!identyProofVerso}
        error={!validSizeVerso ? t("error_messages") : ""}
        messages={{
          dropPrompt: `${t("drag_back")}`,
          browse: `${t("browse")}`,
        }}
      />
    </Box>
  );
};

export { DocumentsForm };
