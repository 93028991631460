import {
  Box,
  Drop,
  DropExtendedProps,
  Icon,
  ResponsiveContext,
  Typography,
} from "@ewibecom/design-system";
import { LanguageSelector } from "components/Custom";
import { PATHS } from "config";
import { useLogout } from "hooks";
import { useContext, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import useTranslate from "translate";

const StyledBox = styled(Box)`
  ${({ theme }) => css`
    background: ${theme.global.colors.secondary.default};
    width: 15rem;
    border-radius: 1rem;
    text-align: right;
    padding: 1.5rem;
    direction: column;
    justify-content: start;
    align-items: center;

    ${(props: { size?: string }) =>
      props.size === "small" &&
      css`
        width: 100vw;
        border-radius: 0rem;
        text-align: center;
      `}
  `}
`;

const LinksBox = styled(Box)`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.global.colors.gray5};
  `}
`;

const StyledLink = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.global.colors.white};
    text-decoration: none;
    line-height: 2.5rem;
    font-size: 1rem;
    font-weight: 400;
    width: 100%;

    ${(props: { size?: string }) =>
      props.size === "small" &&
      css`
        font-size: 1.3rem;
      `};
  `}
`;

const ExitBox = styled(Box)`
  ${({ theme }) => css`
    direction: row;
    padding-top: 1rem;
    color: ${theme.global.colors.primary.default};
    align-self: end;
    margin-bottom: 1rem;

    ${(props: { size?: string }) =>
      props.size === "small" &&
      css`
        align-self: center;
      `}
  `}
`;

const ExitIcon = styled(Icon)`
  ${({ theme }) => css`
    width: 1.3rem;
    height: 1.3rem;
    cursor: pointer;
    & > path {
      fill: ${theme.global.colors.primary.default};
    }
  `}
`;

const menu = [
  {
    name: "route_account",
    href: PATHS.APP.PROFILE,
  },
  {
    name: "route_withdraw",
    href: PATHS.APP.PAYMENTS,
  },
  {
    name: "route_movements",
    href: PATHS.APP.MOVEMENTS,
  },
  {
    name: "route_contact_us",
    href: PATHS.LANDING.CONTACTS,
  },
  {
    name: "route_invite_friend",
    href: `${PATHS.APP.PROFILE}?tab=3`,
  },
  {
    name: "route_faq",
    href: PATHS.LANDING.FAQ,
  },
];

const DropdownMenu = () => {
  const size = useContext(ResponsiveContext);
  let navigate = useNavigate();
  const align: DropExtendedProps["align"] = useMemo(() => ({ top: "bottom", right: "right" }), []);
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const t = useTranslate("shared.");
  const logout = useLogout();

  const handleClick = (event: any) => {
    event.preventDefault();
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleExit = async () => {
    await logout();
    navigate(PATHS.LANDING.HOMEPAGE);
  };

  return (
    <Box ref={ref}>
      <Icon
        type="Profile"
        width="1.5rem"
        height="1.5rem"
        style={{ cursor: "pointer", margin: "0 0.4rem" }}
        onClick={handleClick}
      />
      {ref.current && open && (
        <Drop
          onEsc={handleClose}
          onClickOutside={handleClose}
          plain
          align={align}
          target={ref.current}
          margin={{ horizontal: "small", left: "0rem", top: "1rem" }}
        >
          <StyledBox size={size}>
            <LinksBox fill>
              {menu.map((m) => (
                <StyledLink
                  key={m.name}
                  to={m.href}
                  size={size}
                  onClick={handleClose}
                >
                  {t(m.name)}
                </StyledLink>
              ))}
            </LinksBox>
            <ExitBox direction="row" size={size} onClick={handleExit}>
              <Typography type="body1" margin={{ right: "0.5rem" }}>
                {t("exit")}
              </Typography>
              <ExitIcon type="Exit" />
            </ExitBox>

            <LanguageSelector />
          </StyledBox>
        </Drop>
      )}
    </Box>
  );
};

export default DropdownMenu;
