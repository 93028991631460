import {
  Box,
  Button,
  Checkbox,
  Icon,
  Paginator,
  Typography,
  theme,
} from "@ewibecom/design-system";
import { TooltipRenderProps } from "react-joyride";
import useTranslate from "translate";

interface ExtendedTooltipRenderProps extends TooltipRenderProps {
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  paginatorAction?: ({ page }: { page: number }) => void;
  checked: boolean;
}

const CustomTooltip = ({
  index,
  setIndex,
  setChecked,
  checked,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  size,
  paginatorAction,
}: ExtendedTooltipRenderProps) => {
  const t = useTranslate("tutorial.");

  return (
    <Box
      style={{ borderRadius: "0.75rem" }}
      width="21rem"
      background={(theme as any).global.colors.background}
      {...tooltipProps}
    >
      <Box
        pad="1rem"
        style={{
          borderBottom: `1px solid ${(theme as any).global.colors.gray4}`,
        }}
      >
        <Box direction="row" justify="between" align="start">
          <Typography type="h6" margin={{ vertical: "0rem" }}>
            {step.title}
          </Typography>
          <Box {...closeProps}>
            <Icon type="Close" width="2rem" />
          </Box>
        </Box>
        <Typography margin={{ vertical: "1.5rem" }}>{step.content}</Typography>
        <Checkbox
          label={t("stop")}
          onClick={() => setChecked(!checked)}
          checked={checked}
        />
        <Paginator
          dot
          numberItems={size * 10}
          page={index + 1}
          alignSelf="center"
          onChange={
            paginatorAction ? paginatorAction : ({ page }) => setIndex(page - 1)
          }
        />
      </Box>
      <Box justify="between" direction="row" pad="1rem">
        <Button
          tertiary
          disabled={index === 0}
          label={t("back")}
          width="8rem"
          height="2.5rem"
          {...backProps}
        />
        <Button
          secondary
          label={index === size - 1 ? t("close") : t("forward")}
          width="8rem"
          height="2.5rem"
          {...primaryProps}
        />
      </Box>
    </Box>
  );
};

export default CustomTooltip;
