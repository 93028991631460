export const SEO: Record<string, { title: string; description: string; }> = {
  DASHBOARD: {
    title: "route_dashboard_title",
    description: "route_dashboard_description",
  },
  MARKET: {
    title: "route_market_title",
    description: "route_market_description",
  },
  LISTING: {
    title: "route_listing_title",
    description: "route_listing_description",
  },
  CELLAR: {
    title: "route_cellar_title",
    description: "route_cellar_description",
  },
  PROFILE: {
    title: "route_account_title",
    description: "route_account_description",
  },
  HOMEPAGE: {
    title: "route_home_title",
    description: "route_home_description",
  },
  PRICING: {
    title: "route_pricing_title",
    description: "route_pricing_description",
  },
  INFO: {
    title: "route_info_title",
    description: "route_info_description",
  },
  CONTACTS: {
    title: "route_contacts_title",
    description: "route_contacts_description",
  },
  FAQ: {
    title: "route_faq_title",
    description: "route_faq_description",
  },
  WHYUS: {
    title: "route_discover_us_title",
    description: "route_discover_us_description",
  },
  HOW: {
    title: "route_discover_how_title",
    description: "route_discover_how_description",
  },
  INVESTMENT: {
    title: "route_discover_why_title",
    description: "route_discover_why_description",
  },
};
