import { MarketTrend, Wine, WineQueryParams, WineQueryResponse } from "@ewibecom/sdk";
import { EwibeSDK as sdk } from "config";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { AsyncState, defaultAsyncState } from "./utils";

interface AsyncStore {
  setWine: (wine: Wine) => void;
  getWine: (id: number) => Promise<Wine | false>;
  getWineByIds: (ids: number[]) => Promise<Wine[] | false>;
  getWineByPattern: (pattern: string) => Promise<Wine[] | false>;
  getWineCollection: (id: number) => Promise<Wine[]>;
  getWineQuery: (params: WineQueryParams) => Promise<WineQueryResponse>;
  getMostTradedWines: () => Promise<Wine[]>;
  getNewestWines: () => Promise<Wine[]>;
  getBestYieldWines: () => Promise<Wine[]>;
  getMarketTrend: () => Promise<MarketTrend[]>;
  reset: () => void;
}

export interface WineStore extends AsyncState, AsyncStore {
  wine?: Wine;
  collection?: Wine[];
  mostTraded?: Wine[];
  newest?: Wine[];
  bestYields?: Wine[];
  marketTrend?: MarketTrend[];
  count?: number;
  isSearching?: boolean;
}
export const useWineStore = create<WineStore>()(
  devtools(
    persist(
      (set) => ({
        error: null,
        isLoading: false,
        isSearching: false,
        wine: undefined,
        setWine: (wine: Wine) => set({ wine }),
        getWine: async (id: number): Promise<Wine | false> => {
          try {
            set({ isLoading: true });
            const wine = await sdk.wine.getWine(id);
            set({ wine, error: null, isLoading: false });
            return wine;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        getWineByIds: async (ids: number[]): Promise<Wine[] | false> => {
          try {
            set({ isLoading: true });
            const wines = await sdk.wine.getWineByIds(ids);
            set({ error: null, isLoading: false });
            return wines;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        getWineByPattern: async (pattern: string): Promise<Wine[] | false> => {
          try {
            set({ isSearching: true });
            const { wines } = await sdk.wine.getWineByPattern(pattern);
            set({ error: null, isSearching: false });
            return wines;
          } catch (error) {
            set({ error, isSearching: false });
            return false;
          }
        },
        setCollection: (collection: Wine[]) => set({ collection }),
        getWineCollection: async (id: number): Promise<Wine[]> => {
          try {
            set({ isLoading: true });
            const collection = await sdk.wine.getWineCollection(id);
            set({ collection, error: null, isLoading: false });
            return collection;
          } catch (error) {
            set({ error, isLoading: false });
            return [];
          }
        },
        getWineQuery: async (params: WineQueryParams): Promise<WineQueryResponse> => {
          try {
            set({ isLoading: true });
            const response = await sdk.wine.getWineQuery(params);
            if (response) {
              const { wines, count } = response;
              set({ collection: wines, count: count, error: null, isLoading: false });
              return response;
            }
            set({ collection: [], error: null, isLoading: false });
            return { wines: [], count: 0 };
          } catch (error) {
            set({ error, isLoading: false });
            return { wines: [], count: 0 };
          }
        },
        getMostTradedWines: async (): Promise<Wine[]> => {
          try {
            set({ isLoading: true });
            const mostTraded = (await sdk.wine.getMostTradedWines()) as Wine[];
            set({ mostTraded, error: null, isLoading: false });
            return mostTraded;
          } catch (error) {
            set({ error, isLoading: false });
            return [];
          }
        },
        getNewestWines: async (): Promise<Wine[]> => {
          try {
            set({ isLoading: true });
            const newest = (await sdk.wine.getNewestWines()) as Wine[];
            set({ newest, error: null, isLoading: false });
            return newest;
          } catch (error) {
            set({ error, isLoading: false });
            return [];
          }
        },
        getBestYieldWines: async (): Promise<Wine[]> => {
          try {
            set({ isLoading: true });
            const bestYields = (await sdk.wine.getBestYieldWines()) as Wine[];
            set({ bestYields, error: null, isLoading: false });
            return bestYields;
          } catch (error) {
            set({ error, isLoading: false });
            return [];
          }
        },
        getMarketTrend: async (): Promise<MarketTrend[]> => {
          try {
            set({ isLoading: true });
            const marketTrend =
              (await sdk.wine.getMarketTrend()) as MarketTrend[];
            set({ marketTrend, error: null, isLoading: false });
            return marketTrend;
          } catch (error) {
            set({ error, isLoading: false });
            return [];
          }
        },
        reset: () =>
          set({
            ...defaultAsyncState,
            wine: undefined,
            collection: undefined,
            mostTraded: undefined,
            newest: undefined,
            bestYields: undefined,
          }),
      }),
      {
        name: "ewibe-wine-store",
        partialize: (state) => ({
          wine: state.wine,
          collection: state.collection,
          mostTraded: state.mostTraded,
          newest: state.newest,
          bestYields: state.bestYields,
          marketTrend: state.marketTrend
        }),
      }
    )
  )
);
