import { Page, Spinner } from "@ewibecom/design-system";
import { PATHS } from "config";
import { useHandleSSO } from "hooks";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import useStore, { AuthStore, UserStore } from "store";
import Layout from "./Layout";

const tokenIsValid = (token: string): boolean => {
  const decoded = jwt_decode(token);
  if (decoded) {
    const now = new Date();
    return (decoded as any).exp * 1000 > now.getTime();
  }
  return false;
};

const RequireAuth = () => {
  const location = useLocation();
  const { token, logout, saveFromLocation, from } = useStore(
    (state) => state.auth
  ) as AuthStore;
  const { user } = useStore((state) => state.user) as UserStore;
  const loading = useHandleSSO();

  // This initialization takes care of both normal and sso login. The from location is persisted in the store.
  // Both if user logs in normally or via a social account, the user flow will be redirected to the from location
  // either here in this component on successful login or in Layout after correct social login in. The from in store
  // is cleaned up when the redirection takes place
  useEffect(() => {
    if (
      location &&
      (!from || location.pathname !== from) &&
      location.pathname !== "/app"
    )
      saveFromLocation(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token && !tokenIsValid(token)) {
      (async () => {
        await logout();
      })();
    }
  }, [token, logout]);

  // safe fallback mechanism to avoid the login to be stuck on a loading spinner
  useEffect(() => {
    let timeout: any = null;
    if (loading || !user) {
      timeout = setTimeout(async () => {
        await logout();
      }, 3000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [loading, user, logout]);

  if (!token || !tokenIsValid(token)) {
    // Redirect to /login, but save the current location to be used later,
    // landing the user on the page was redirected from
    return <Navigate to={PATHS.AUTH.LOGIN} />;
  }

  if (loading || !user)
    return (
      <Page align="center" justify="center" height="100vh">
        <Spinner />
      </Page>
    );

  if (!user?.onboarding.tc) {
    return <Navigate to={PATHS.AUTH.TERMS} />;
  }

  return <Layout />;
};

export default RequireAuth;
