export interface AsyncState {
  data?: any;
  error?: any;
  isLoading: boolean;
}

export const defaultAsyncState = {
  data: undefined,
  error: undefined,
  isLoading: false
}

export const getCookie = (name: string) => {
  let ca: Array<string> = document.cookie.split(';');
  let caLen: number = ca.length;
  let cookieName = `${name}=`;
  let c: string;

  for (let i: number = 0; i < caLen; i += 1) {
    c = ca[i].replace(/^\s+/g, '');
    if (c.indexOf(cookieName) === 0) {
      return c.substring(cookieName.length, c.length);
    }
  }
  return '';
}
