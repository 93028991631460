import { Anchor, Box, Typography, theme } from "@ewibecom/design-system";
import { List, Paragraph, Subtitle, Title } from "components/Custom";
import { useIsSmall } from "hooks";
import styled, { css } from "styled-components";

const StyledAnchor = styled(Anchor)`
  ${({ theme }) => {
    const red = (theme as any).global.colors.primary.default;

    return css`
      color: ${red};
      text-decoration: none;
      margin-left: 0.2rem;
      font-weight: 400;
    `;
  }}
`;

export interface TermsAndConditionProps {
  fullscreen?: boolean;
}

const TermsAndConditions: React.FC<TermsAndConditionProps> = (props) => {
  const small = useIsSmall();

  return (
    <Box background={(theme as any).global.colors.background}>
      <Typography
        type={props.fullscreen && !small ? "h2" : "h4"}
        margin={{ vertical: !props.fullscreen || small ? "small" : "s" }}
        color={(theme as any).global.colors.secondary.default}
      >
        Termini e condizioni
      </Typography>

      <Title big={props.fullscreen}>Ultimo Aggiornamento: 05/03/2024</Title>

      <Paragraph>
        Di seguito sono illustrati i termini e le condizioni d'uso per
        <StyledAnchor href="https://www.ewibe.com">
          www.ewibe.com
        </StyledAnchor>{" "}
        (il “Sito”). Il Sito è gestito da eWibe S.r.l. con sede legale in
        Milano, 20123, via Gonzaga n.2, P.IVA 11884500965 (“eWibe”) e comprende
        una piattaforma peer to peer che permette agli utenti di accedere ad un
        marketplace dove ottenere quotazioni di mercato, cercare, acquistare,
        vendere o stoccare bottiglie di vino e alcolici pregiati (la
        “Piattaforma”). I presenti Termini e Condizioni d'Uso, le nostre FAQ
        della Piattaforma (le “FAQ”), e tutti gli altri requisiti pubblicati sui
        nostri siti web, documenti a cui si fa riferimento nei presenti Termini
        e Condizioni d'Uso e come modificati periodicamente (congiuntamente,
        “Termini”) descrivono i termini e le condizioni attraverso cui forniamo
        il nostro sito web (il “Sito”), la Piattaforma, i servizi, i dati, i
        software, e gli strumenti (congiuntamente, i “Servizi”) agli utenti.
      </Paragraph>

      <Paragraph>
        Nei presenti Termini, con “Utente” ci si riferisce (a) all'individuo
        che, creando un account, accede a e/o utilizza i Servizi, (b) qualsiasi
        operatore elettronico che acceda ai Siti e/o utilizzi i Servizi per
        conto di un individuo o società, e (c) la società per conto della quale
        un individuo o un agente elettronico accede al Sito e/o utilizzi i
        Servizi (di seguito, l'“Utente”).
      </Paragraph>

      <Paragraph>
        Nei presenti Termini, con “Utente Verificato” ci si riferisce a
        qualsiasi Utente che abbia richiesto l'accesso a tutte le funzionalità
        della Piattaforma ed ha completato la verifica del proprio account ai
        sensi del successivo Paragrafo 2 (di seguito, l'“Utente Verificato”).
      </Paragraph>

      <Paragraph>
        Utilizzando il Sito e i Servizi o facendo clic per accettare i presenti
        Termini, l'Utente accetta di esserne vincolato e di rispettare i
        presenti Termini in toto. Se l'Utente non accetta i presenti Termini,
        non dovrà utilizzare il Sito o alcuno dei Servizi.
      </Paragraph>

      <Title big={props.fullscreen}>1. Modifiche ai Termini</Title>

      <Paragraph>
        A propria discrezione, eWibe può modificare i presenti Termini (incluse
        le FAQ o qualsiasi altra informativa) in qualsiasi momento, e in quel
        caso ne darà avviso sul nostro Sito. Potrà inoltre inviare un'e-mail e/o
        informare gli Utenti con altri mezzi. Salvo che la legge non richieda la
        modifica dei Termini con effetto immediato, eWibe, ove applicabile,
        comunicherà agli Utenti qualsiasi modifica che li riguardi mediante
        preavviso di almeno 5 giorni. Le modifiche hanno effetto alla data
        stabilita dai Termini. L'Utente si impegna a controllare frequentemente
        i presenti Termini per rimanere aggiornato su qualsiasi cambiamento che
        potrebbe riguardarlo. L'uso continuativo del Sito e/o dei Servizi dopo
        la modifica dei presenti termini costituisce l'accettazione delle
        modifiche da parte dell'Utente. Qualora l'Utente non accetti nessuna
        delle modifiche, dovrà cancellare l'account e non utilizzare alcun
        Servizio.
      </Paragraph>

      <Paragraph>
        L'accesso e utilizzo del Sito sarà regolato dalla versione dei presenti
        Termini pubblicata sul Sito al momento dell'accesso e che rechi la
        relativa data. Ciascuna copia dei presenti Termini e dell'Informativa
        sulla Privacy da noi conservata in forma elettronica o in un'altra
        modalità adeguata dovrà essere considerata copia veritiera, completa,
        valida e autentica della versione dei presenti Termini e
        dell'Informativa sulla Privacy che erano in vigore in ogni rispettiva
        data in cui è stato visitato il Sito. eWibe si riserva il diritto di
        sospendere i presenti Termini, rifiutare, restringere o interrompere
        l'accesso a o l'utilizzo dei Servizi (o qualsiasi loro parte, componente
        o caratteristica) all'Utente o a qualsiasi altra persona o soggetto, in
        caso di violazione dei Termini o per qualsiasi motivo o senza motivo, in
        qualsiasi momento, senza comunicazione o responsabilità.
      </Paragraph>

      <Paragraph>
        Salvo ove diversamente consentito dalla legge applicabile, eWibe
        comunicherà agli Utenti la cessazione di tutti i Servizi mediante
        preavviso di 15 giorni.
      </Paragraph>

      <Title big={props.fullscreen}>2. Iscrizione e Account</Title>

      <Paragraph>
        Per accedere al Sito e ai Servizi, è necessario creare un account
        utilizzando un indirizzo e-mail e una password.
      </Paragraph>

      <Paragraph>
        La creazione dell'account è consentita solamente a persone fisiche
        residenti in un paese membro dell'Unione Europea, con età maggiore degli
        anni 18. Al momento della registrazione l'Utente acconsente a fornire
        copia del documento di identità ed un documento in grado di attestare
        l'indirizzo di residenza per procedere alla verifica dell'account. eWibe
        di riserva di negare e/o limitare l'operatività dell'account a chiunque
        non abbia un account verificato. Ciascuna persona fisica potrà disporre
        di un solo ed unico account.
      </Paragraph>

      <Paragraph>
        Creando un account, l'Utente accetta di fornire informazioni esatte e di
        non registrarsi sotto falso nome o un'età falsa o utilizzare un metodo
        di pagamento non autorizzato. Utilizzando qualsiasi Servizio, l'Utente è
        responsabile del mantenimento della riservatezza del proprio account e
        della propria password e dell'accesso limitato al proprio computer,
        accettando di avere la responsabilità di tutte le attività che si
        verificano con il proprio account o la password. L'Utente si impegna ad
        informare immediatamente eWibe dopo aver appreso di qualsiasi utilizzo
        non autorizzato del proprio account o della password, o qualsiasi altro
        accesso non autorizzato o violazione della sicurezza. Tuttavia, l'Utente
        potrà essere ritenuto responsabile per perdite subite da eWibe o
        qualsiasi altra persona o soggetto a causa di un'altra persona che usi
        l'account o la password. Non è permesso utilizzare, in qualsiasi
        momento, l'account o la password di qualsiasi altro Utente senza
        permesso e autorizzazione esplicita da parte del possessore dell'account
        o della password. Non è consentito trasferire o cedere l'account.
      </Paragraph>

      <Paragraph>
        Per poter usufruire di tutte i Servizi, tra cui inserire offerte di
        acquisto o di vendita per i prodotti sulla Piattaforma, l'Utente che
        abbia creato già creato l'account deve terminare il procedimento di
        verifica, di cui sopra, e depositare fondi tramite il sistema Wallet
        fornito da Mangopay di cui al successivo Paragrafo 10 per diventare un
        Utente Verificato.
      </Paragraph>

      <Title big={props.fullscreen}>3. Membership</Title>

      <Paragraph>
        Per poter sfruttare al meglio le potenzialità offerte dalla Piattaforma
        e accedere ad ulteriori Servizi, eWibe propone agli Utenti Verificati
        tre tipologie di membership:
      </Paragraph>

      <List>
        a. <Subtitle>Base</Subtitle>: all'Utente è consentito accedere in tempo
        reale ai dati sulla Piattaforma, potrà acquistare e vendere i prodotti
        caricati e potrà ricevere la newsletter di eWibe riguardante
        informazioni sulla Piattaforma e contenuti esclusivi sull'andamento del
        mercato di riferimento, senza alcun costo aggiuntivo;
      </List>
      <List>
        b. <Subtitle>Standard</Subtitle>: dietro corrispettivo mensile,
        all'Utente è consentito usufruire di tutti i vantaggi concessi con la
        membership standard, ed in aggiunta potrà ottenere trading fees ridotte
        e maggiori dettagli nella gestione ed analisi del suo account;
      </List>
      <List>
        c. <Subtitle>Professionale</Subtitle>: dietro corrispettivo mensile,
        all'Utente è consentito usufruire di tutti i vantaggi concessi con la
        membership Pro, ed in aggiunta potrà ottenere trading fees ancora più
        vantaggiose e processing fees ridotte, oltre a poter acquistare in
        anteprima determinati prodotti.
      </List>

      <Paragraph>
        Per poter richiedere la membership l'Utente dovrà essere in possesso di
        un wallet Mangopay, che verrà automaticamente impostato come metodo di
        pagamento predefinito per i rinnovi. Si veda il Paragrafo 10 per
        ulteriori approfondimenti.
      </Paragraph>

      <Title big={props.fullscreen}>4. Funzionamento della Piattaforma</Title>

      <Paragraph>
        eWibe consente agli Utenti Verificati di elencare (listing), vendere ed
        acquistare prodotti sulla Piattaforma.
      </Paragraph>
      <Paragraph>
        eWibe agisce come intermediario e la Piattaforma ha come obiettivo
        quello di incrociare le offerte di acquisto con le offerte di vendita
        attraverso un order book strutturato per ciascun singolo prodotto, o
        variante di prodotto (ad esempio, nel caso in cui lo stesso prodotto sia
        disponibile in diverse varianti o quantità).
      </Paragraph>
      <Paragraph>
        Poiché sono gli Utenti Verificati a fissare i prezzi, questi ultimi
        potrebbero essere più alti dei prezzi al dettaglio dei prodotti venduti
        altrove. Qualsiasi valutazione dei prodotti mostrata attraverso la
        Piattaforma o i Servizi costituisce una semplice stima. eWibe non
        garantisce che qualsiasi prodotto sarà venduto.
      </Paragraph>
      <Paragraph>
        Nel momento in cui un Utente Verificato inserisce un ordine di acquisto
        (bid) viene identificato come potenziale compratore del prodotto (il
        “Potenziale Compratore”). Ogni ordine di acquisto può essere inserito,
        modificato e revocato un numero indefinito di volte da parte del
        Potenziale Compratore. Al momento dell'invio dell'ordine di acquisto, il
        Potenziale Compratore potrà definire, tramite l'apposito modulo
        d'ordine, le condizioni da applicare al proprio ordine di acquisto. Il
        prezzo massimo al quale il Potenziale Compratore si impegna a concludere
        la transazione è quello mostrato nella conferma d'ordine, ovvero
        l'importo dell'ordine di acquisto maggiorato delle commissioni ivi
        specificate. Ogni ordine di acquisto è pertanto soggetto a conferma da
        parte del Potenziale Compratore, che acconsente a bloccare il relativo
        importo mostrato nella conferma d'ordine sul metodo di pagamento
        predefinito al fine di garantire l'immediatezza della transazione e
        scoraggiare qualsiasi manipolazione della Piattaforma. Nel momento in
        cui un ordine di acquisto viene accettato tramite la Piattaforma o, nel
        momento in cui un ordine di vendita raggiunge lo stesso prezzo di un
        ordine di acquisto, il Potenziale Compratore viene identificato come
        acquirente del prodotto (il “Compratore”) e l'ordine di acquisto diviene
        definitivo, irrevocabile e vincolante, ai sensi del successivo Paragrafo
        7. Di conseguenza, si applicheranno le successive disposizioni per la
        finalizzazione della transazione e la gestione dei pagamenti da parte di
        eWibe.
      </Paragraph>
      <Paragraph>
        Nel momento in cui un Utente Verificato inserisce un ordine di vendita
        (ask) viene identificato come potenziale venditore del prodotto (il
        “Potenziale Venditore”). Ogni ordine di vendita può essere inserito,
        modificato e revocato un numero indefinito di volte da parte del
        Potenziale Venditore. Al momento dell'invio dell'ordine di vendita, il
        Potenziale Venditore potrà definire, tramite l'apposito modulo d'ordine,
        le condizioni da applicare al proprio ordine di vendita. Ogni ordine di
        vendita è soggetto a conferma da parte del Potenziale Venditore, che
        acconsente a ricevere come corrispettivo della vendita il prezzo
        riportato nella conferma d'ordine comprensivo delle commissioni.
        Pertanto, il corrispettivo minimo che il Potenziale Venditore accetta di
        ricevere per la transazione è quello mostrato nella conferma d'ordine,
        ovvero il prezzo dell'offerta di vendita al netto delle commissioni ivi
        specificate. Nel momento in cui un ordine di vendita viene accettato
        tramite la Piattaforma, o nel momento in cui un ordine di vendita
        raggiunge lo stesso prezzo di un ordine di acquisto, il Potenziale
        Venditore viene identificato come venditore del prodotto (il
        “Venditore”) e l'ordine di vendita diviene definitivo, irrevocabile e
        vincolante, ai sensi del successivo Paragrafo 6. Di conseguenza, si
        applicheranno le successive disposizioni per la finalizzazione della
        transazione e la gestione dei pagamenti da parte di eWibe.
      </Paragraph>
      <Paragraph>
        Le transazioni potranno essere completate anche mediante acquisto
        diretto o vendita diretta, in tal caso l'Utente Verificato potrà
        acquistare direttamente il prodotto al prezzo più basso disponibile
        sull'order book (best ask) senza necessità di dover formulare un ordine
        di acquisto, o potrà vendere il prodotto al prezzo più alto disponibile
        sull'order book (best bid). In tal caso, la transazione sarà definitiva,
        irrevocabile e vincolante per Compratore e Venditore. Una volta
        completata la transazione ogni acquisto diretto ed ogni vendita diretta
        saranno soggetti al pagamento di commissioni calcolate in misura
        percentuale sul valore della transazione, che vengono mostrate nella
        pagina di conferma della transazione che, in caso di conferma da parte
        dell'Utente Verificato, saranno addebitate immediatamente sul metodo di
        pagamento predefinito del Compratore e del Venditore. Le commissioni
        saranno sempre le stesse mostrate nella conferma d'ordine per l'Utente
        Verificato che abbia inserito un ordine di vendita o di acquisto che sia
        stato soggetto ad acquisto o vendita diretta.
      </Paragraph>
      <Paragraph>
        La Piattaforma non consente di inserire contemporaneamente un ordine di
        vendita ed un ordine di acquisto per uno stesso prodotto. In tutti i
        casi sopra descritti, il contratto viene concluso sempre tra Compratore
        e Venditore a condizione che l'autenticità del prodotto sia stata
        preventivamente approvata da eWibe. Qualora il prodotto non sia già
        disponibile presso il magazzino di eWibe potrà essere comunque spedito
        dal Venditore ed in tal caso la transazione verrà conclusa con successo
        solo a seguito della ricezione e della avvenuta autenticazione. Per
        tutti i dettagli sul processo di autenticazione si rimanda al successivo
        Paragrafo 8. Un'ulteriore descrizione dei procedimenti e delle regole
        della Piattaforma applicabili ai Venditori e Compratori è disponibile
        sulla nostra pagina FAQ, aggiornata periodicamente. Mentre eWibe, come
        fornitore della Piattaforma, aiuta a facilitare le operazioni che
        vengono effettuate sulla Piattaforma, eWibe non è né l'acquirente né il
        venditore dei prodotti. eWibe fornisce un luogo per i Venditori e
        Compratori per negoziare e completare le transazioni. Di conseguenza, il
        contratto concluso al completamento di una vendita per tali prodotti di
        terzi avviene esclusivamente tra il Compratore e il Venditore. Sebbene
        eWibe possa fornire dati sullo storico dei prezzi agli Utenti, non fissa
        i prezzi per i prodotti e non è un banditore d'asta.
      </Paragraph>

      <Title big={props.fullscreen}>5. Obblighi di eWibe</Title>

      <Paragraph>
        eWibe si impegna a fornire i Servizi e gestire la Piattaforma garantendo
        la diligenza professionalmente richiesta e compiendo ogni sforzo
        commercialmente ragionevole al fine di garantire l'esattezza delle
        transazioni e delle informazioni sul Sito.
      </Paragraph>
      <Paragraph>
        eWibe è terzo in ogni transazione, e pertanto l'Utente lo esonera da
        qualsiasi responsabilità in merito all'impossibilità di portare a
        termine una qualsiasi di dette transazioni. Nel caso in cui una
        transazione non possa essere completata con successo, anche a seguito di
        avvenuta conferma, eWibe si impegna a ripristinare immediatamente i
        fondi sul metodo di pagamento predefinito dell'Utente.
      </Paragraph>
      <Paragraph>
        eWibe si impegna inoltre ad aggiornare costantemente i presenti Termini
        per garantire il più corretto svolgimento delle operazioni sulla
        Piattaforma e un livello adeguato agli standard richiesti dagli Utenti
        nella fornitura dei Servizi. All'Utente è quindi richiesto di
        controllare periodicamente i presenti Termini, sulla base delle
        comunicazioni di volta in volta inviate da eWibe. eWibe si impegna a
        fornire assistenza su tutte le tematiche relative al funzionamento della
        Piattaforma, alla fornitura dei Servizi e all'interpretazione dei
        presenti Termini, mettendo a disposizione degli Utenti un servizio
        clienti all'indirizzo:{" "}
        <StyledAnchor href="mailto:help@ewibe.com">help@ewibe.com</StyledAnchor>
        .
      </Paragraph>

      <Title big={props.fullscreen}>6. Obblighi del Venditore</Title>

      <Paragraph>
        Inserendo una offerta di vendita per un prodotto il Potenziale Venditore
        formula un'offerta vincolante per la vendita di quello specifico
        prodotto a un Utente, che acquisterà il prodotto al prezzo che hai
        specificato. Quando un Compratore accetta l'offerta acquistando il
        prodotto attraverso la Piattaforma, il Venditore vincolato
        contrattualmente e legalmente a vendere quell'esatto prodotto al prezzo
        specificato nella conferma d'ordine. Ogni transazione sarà istantanea e
        gestita automaticamente dalla Piattaforma.
      </Paragraph>
      <Paragraph>
        Il Venditore è tenuto ad assicurarsi che il prodotto della offerta di
        vendita corrisponda esattamente all'immagine sulla pagina del prodotto e
        rispetti le condizioni applicabili. eWibe darà istruzione per
        l'accredito del corrispettivo della transazione al Venditore entro un
        termine ragionevole dalla conclusione della vendita, che avviene in modo
        istantaneo ed automatico quando il Compratore riceve l'addebito sul suo
        metodo di pagamento. Per visualizzare le commissioni applicabili alle
        operazioni di vendita, si prega di fare riferimento alla conferma
        d'ordine, anche sull'area riservata del tuo account, e alle nostre FAQ.
      </Paragraph>
      <Paragraph>
        Al momento della conclusione della vendita, il prodotto venduto non sarà
        più disponibile nell'area riservata del Venditore ed il passaggio di
        proprietà del prodotto sarà automatico. eWibe agisce esclusivamente come
        intermediario tra i Venditori e i Compratori. eWibe non si assume alcuna
        responsabilità per quanto riguarda la corretta esecuzione del contratto
        di compravendita che il Venditore dovesse concludere con il Compratore
        attraverso la Piattaforma, salvo che per gli obblighi specifici di eWibe
        descritti nel Paragrafo 5 dei presenti Termini.
      </Paragraph>

      <Title big={props.fullscreen}>7. Obblighi del Compratore</Title>

      <Paragraph>
        Ogni Utente Verificato può inserire una offerta di acquisto per un
        prodotto disponibile sulla Piattaforma. Quando una offerta di acquisto
        corrisponde ad una offerta di vendita, o viene effettuata una vendita
        diretta da parte di un Venditore, il Compratore è obbligato a pagare
        quel determinato prodotto. Quando il Potenziale Compratore formula
        un'offerta di acquisto si impegna ad acquistare il prodotto appena la
        sua offerta corrisponderà al prezzo di richiesta di un Venditore e
        riconosce che il pagamento del prezzo sarà automaticamente addebitato
        sul metodo di pagamento predefinito. Per essere un Compratore idoneo è
        necessario disporre di un indirizzo di fatturazione e di un indirizzo di
        spedizione in uno dei Paesi supportati da eWibe. In generale,
        addebitiamo il metodo di pagamento immediatamente dopo l'acquisto e al
        completamento della transazione paghiamo il Venditore. Il passaggio di
        proprietà sarà quindi istantaneo al completamento della transazione ed
        il Compratore potrà visualizzare il prodotto acquistato nell'area
        riservata del suo account.
      </Paragraph>
      <Paragraph>
        Per garantire il corretto funzionamento della Piattaforma e la
        immediatezza delle transazioni, evitando offerte fittizie, al momento
        dell'inserimento di una offerta di acquisto, verrà temporaneamente
        bloccata sul metodo di pagamento predefinito la liquidità equivalente
        all'importo dell'offerta di acquisto. L'Utente Verificato potrà pertanto
        visualizzare all'interno dell'area riservata del proprio account tutte
        le offerte di acquisto effettuate e la liquidità vincolata. Al momento
        della cancellazione o modifica di un ordine di acquisto, la liquidità
        vincolata verrà immediatamente svincolata, ridotta o aumentata in base
        alla modifica effettuata. Non è dovuto alcun interesse o commissione
        sull'importo di acquisto, mentre l'offerta di acquisto è in corso.
      </Paragraph>
      <Paragraph>
        Se il Compratore riscontra problemi con l'ordine di acquisto o il
        prodotto acquistato, è pregato di contattare il servizio clienti
        all'indirizzo:{" "}
        <StyledAnchor href="mailto:help@ewibe.com">help@ewibe.com</StyledAnchor>
        .
      </Paragraph>
      <Paragraph>
        Resta inteso che i prezzi visualizzati sulle schede dei prodotti non
        includono le spese di trasporto e tutte le eventuali commissioni che
        verranno applicate all'ordine di acquisto, e non includono il costo del
        servizio. Il prezzo finale comprensivo di spese e costi è sempre quello
        riportato nella conferma d'ordine.
      </Paragraph>

      <Title big={props.fullscreen}>8. Listing e Autenticazione</Title>

      <Paragraph>
        Ogni prodotto disponibile per la vendita, tramite la Piattaforma è
        soggetto inderogabilmente al processo di autenticazione condotto da
        eWibe.
      </Paragraph>
      <Paragraph>
        Ai sensi del presente paragrafo, per “listing” si intende il
        procedimento con il quale un Utente Verificato procede ad inserire un
        prodotto nella Piattaforma sia con riguardo allo stoccaggio presso la
        cantina online che per la vendita sulla piattaforma (di seguito, il
        “Listing”). Per maggiori informazioni sullo stoccaggio di un prodotto
        tramite la Piattaforma si rimanda al Paragrafo 11.
      </Paragraph>
      <Paragraph>
        All'Utente Verificato è richiesto di controllare attentamente il
        prodotto prima di procedere al Listing sulla Piattaforma, e di
        assicurarsi di essere in possesso di tutta la eventuale documentazione
        che eWibe potrà riservarsi di richiedere durante la procedura di
        autenticazione. Pertanto, l'Utente Verificato accetta che il processo di
        autenticazione condotto da eWibe non sarà soggetto a ricorso e/o
        condizioni.
      </Paragraph>
      <Paragraph>
        Come parte del Listing, l'Utente Verificato dovrà spedire il prodotto a
        eWibe secondo le modalità concordate con quest'ultimo e secondo le
        istruzioni di volta in volta aggiornate sulle FAQ. eWibe compirà ogni
        sforzo che sia ragionevole da un punto di vista commerciale per
        confermare l'autenticità del prodotto e che lo stesso soddisfi le
        condizioni standard per il Listing, come definite nelle FAQ. Se eWibe
        non sarà in grado di confermare ragionevolmente l'autenticità del
        prodotto o stabilirà che non è autentico o che non soddisfa le
        condizioni standard, comunicherà all'Utente Verificato, che il prodotto
        non ha superato l'esame di autenticità e addebiterà all'Utente
        Verificato le spese della spedizione di ritorno. Inoltre, eWibe ha il
        diritto di rifiutare qualsiasi prodotto per qualsiasi motivo inclusi, a
        titolo esemplificativo ma non esaustivo, la non autenticità, il mancato
        soddisfacimento delle condizioni standard o la presenza di danni al
        prodotto tra i quali, a titolo esemplificativo e non esaustivo,
        alterazioni e/o danni alle etichette e/o confezioni.
      </Paragraph>
      <Paragraph>
        Prima di procedere al Listing l'Utente Verificato dovrà accettare la
        valutazione preventiva fornita dalla Piattaforma per quel medesimo
        prodotto, che rappresenterà la base imponibile per l'applicazione di
        tutte le commissione relative al Listing.
      </Paragraph>
      <Paragraph>
        Al fine di garantire l'integrità della Piattaforma, la correttezza degli
        scambi e scoraggiare eventuali pratiche scorrette, nel caso in cui il
        processo di autenticazione porti ad un esito negativo, all'Utente
        Verificato potrà essere addebitata sul metodo di pagamento predefinito
        una penale pari al maggiore tra l'importo di Euro 50,00 ed il 15%
        calcolato sul prezzo di mercato, come risultante dalla valutazione
        preventiva fornita da eWibe al momento del Listing. Per ulteriori
        sanzioni e/o provvedimenti che potranno essere posti in essere da eWibe,
        in caso di accertata o presunta contraffazione o tentata manipolazione
        della Piattaforma, si rimanda al Paragrafo 13.
      </Paragraph>
      <Paragraph>
        Il tempo richiesto per il processo di autenticazione è compreso tra un
        minimo di 2 ore dal ricevimento del prodotto ad un massimo di 72 ore.
      </Paragraph>

      <Title big={props.fullscreen}>9. Pagamenti</Title>

      <Paragraph>
        Per poter effettuare e ricevere i pagamenti sulla Piattaforma, l'Utente
        dovrà attivare un wallet Mangopay come meglio specificato nel successivo
        Paragrafo 10.
      </Paragraph>
      <Paragraph>
        Una volta attivato il wallet Mangopay verrà automaticamente impostato
        come metodo di pagamento predefinito per tutti i pagamenti effettuati
        e/o ricevuto dagli utenti.
      </Paragraph>
      <Paragraph>
        L'Utente riconosce che eWibe agisce esclusivamente come intermediario e
        quindi, tramite la Piattaforma provvede alla gestione dei flussi
        relativi all'acquisto e/o alla vendita dei prodotti nonché al blocco ed
        allo sblocco degli importi relativi ad una ordine di acquisto o ad
        ordine di vendita. L'unica eccezione attiene esclusivamente ai pagamenti
        per la membership, le commissioni e le penali che verranno incassati
        direttamente da eWibe. Di conseguenza, eWibe si impegna a provvedere nel
        termine massimo di 24 ore da ciascuna singola transazione ad inviare le
        istruzioni per l'addebito e/o l'accredito sul wallet Mangopay.
      </Paragraph>
      <Paragraph>
        L'Utente riconosce espressamente che eWibe nella sua qualità di
        intermediario, non può essere ritenuto responsabile per il pagamento di
        qualsiasi importo nei confronti dell'Utente, e non potrà garantire il
        perfezionamento di alcuna operazione di vendita o di acquisto se non nei
        limiti di una corretta gestione della Piattaforma, ai sensi dei presenti
        Termini.
      </Paragraph>

      <Title big={props.fullscreen}>10. Wallet Mangopay</Title>

      <Paragraph>
        Per poter utilizzare tutte le funzionalità della Piattaforma e procedere
        alla verifica dell'account, e quindi pubblicare offerte di vendita o di
        acquisto di un qualsiasi prodotto, l'Utente dovrà aprire un wallet con
        Mangopay e depositare i fondi, accettando i Termini di Servizio di
        Mangopay disponibili al{" "}
        <StyledAnchor href="https://mangopay.com/terms/payment-services_IT_2023.pdf">
          seguente indirizzo
        </StyledAnchor>
        .
      </Paragraph>
      <Paragraph>
        Tutti i fondi e i pagamenti effettuati tramite il wallet di Mangopay
        saranno gestiti in completa autonomia da Mangopay. Accettando i Termini
        di Servizio di Mangopay l'Utente autorizza eWibe a condividere con
        Mangopay i suoi dati personali per procedere all'apertura del conto, ed
        è responsabile della correttezza e della completezza di tali dati.
        L'Utente è consapevole che avrà accesso al wallet Mangopay
        esclusivamente tramite il Sito e gestirà il proprio wallet Mangopay
        attraverso l'account creato con eWibe.
      </Paragraph>
      <Paragraph>
        Nei limiti della legge applicabile, eWibe è esonerato da qualsiasi
        responsabilità per dispute relative all'utilizzo del wallet Mangopay o
        problemi con i metodi di pagamento prescelti.
      </Paragraph>
      <Paragraph>
        I fondi disponibili depositati sul wallet Mangopay potranno essere
        prelevati dall'Utente Verificato in qualsiasi momento utilizzando la
        apposita procedura fornita da Mangopay ed accessibile sull'area
        riservata del proprio account.
      </Paragraph>

      <Title big={props.fullscreen}>11. Stoccaggio e Trasporto</Title>
      <Paragraph>
        Tra i vari Servizi offerti all'Utente tramite la Piattaforma, eWibe
        propone lo stoccaggio ed il trasporto dei prodotti che possono essere
        venduti e/o acquistati sulla Piattaforma medesima.
      </Paragraph>
      <Paragraph>
        Lo stoccaggio dei prodotti acquistati o da vendere sulla Piattaforma è
        facoltativo, ed è soggetto al pagamento di commissioni da parte
        dell'Utente Verificato (le “Commissioni di Deposito”) che devono essere
        accettate da parte dell'Utente e vengono addebitate sul metodo di
        pagamento predefinito al momento del Listing. I prodotti stoccati presso
        il deposito di eWibe sono sempre coperti da assicurazione su furto e
        danni accidentali. In caso di furto o distruzione del prodotto,
        all'Utente verrà riconosciuto un importo pari al valore di mercato del
        prodotto mostrato al momento del Listing o, nel caso in cui il prodotto
        sia stato acquistato sulla Piattaforma un importo pari al prezzo di
        acquisto del medesimo prodotto.
      </Paragraph>
      <Paragraph>
        La spedizione dei prodotti acquistati sulla Piattaforma è obbligatoria
        qualora l'Utente Verificato non abbia optato per lo stoccaggio dei
        prodotti. La spedizione verrà effettuata dal fornitore di eWibe presso
        l'indirizzo indicato dall'Utente Verificato in fase di registrazione,
        nonché presso altro indirizzo che dovrà essere preventivamente
        verificato da eWibe come indicato nel precedente Paragrafo 2. La
        spedizione dei prodotti acquistati o in stoccaggio è sempre soggetta al
        pagamento di commissioni (le “Commissioni di Svincolo”) che vengono
        mostrate all'Utente sulla pagina di conferma dell'ordine di acquisto, o
        anche sulla propria area riservata, e che l'Utente dovrà accettare per
        completare l'operazione. La spedizione dei prodotti acquistati sulla
        Piattaforma non è assicurata. Qualora un Utente voglia richiedere il
        servizio di assicurazione, soggetto ad un costo aggiuntivo, dovrà
        contattare preventivamente il servizio clienti di eWibe all'indirizzo:
        <StyledAnchor href="mailto:help@ewibe.com">help@ewibe.com</StyledAnchor>
        , per ottenere una stima.
      </Paragraph>
      <Paragraph>
        Se l'Utente Verificato riscontra un qualsiasi problema con un prodotto
        consegnato, dovrà contattare eWibe con una descrizione dettagliata del
        problema. Le etichette e gli adesivi di verifica di eWibe attaccati ai
        prodotti non devono essere rimossi.
      </Paragraph>
      <Paragraph>
        L'Utente esonera eWibe, ora per allora, da qualsiasi responsabilità per
        alterazione del gusto del prodotto consegnato.
      </Paragraph>

      <Title big={props.fullscreen}>12. Proprietà Intellettuale</Title>
      <Paragraph>
        L'Utente riconosce e accetta che (i) i brevetti, diritti d'autore,
        marchi registrati, marchi di prodotti, segreti commerciali e altre
        proprietà intellettuali di eWibe (in sintesi, “Proprietà Intellettuale”)
        sono di esclusiva proprietà di eWibe, e (ii) nulla nei presenti Termini
        conferirà in alcun modo all'Utente qualsiasi diritto di proprietà o
        diritto di licenza sulla Proprietà Intellettuale. Inoltre, l'Utente non
        dovrà contestare la validità della Proprietà Intellettuale ora o in
        futuro. Fatto salvo quanto precede, i Servizi, inclusi a titolo
        esemplificativo ma non esaustivo tutti i testi, le grafiche, i loghi, i
        pulsanti, le icone, le immagini, le clip audio, e i programmi per il
        computer, sono di proprietà di eWibe o dei suoi licenzianti o fornitori
        e sono protetti dalle leggi sulla proprietà intellettuale italiane e
        internazionali (incluse le leggi sul diritto d'autore e sui marchi
        registrati). La compilazione (vale a dire la raccolta, la disposizione e
        l'assemblaggio) di tutti i contenuti associati ai Servizi è di esclusiva
        proprietà di eWibe ed è protetta dalle leggi sulla proprietà
        intellettuale italiane e internazionali (inclusa la legge sul diritto
        d'autore). Qualsiasi riproduzione, modifica, distribuzione,
        trasmissione, ripubblicazione, visualizzazione o esecuzione non
        autorizzata del software o dei contenuti attraverso i nostri Servizi è
        severamente vietata.
      </Paragraph>
      <Paragraph>
        eWibe concede agli Utenti una licenza personale, non esclusiva, non
        trasferibile, revocabile e limitata per utilizzare la Piattaforma e i
        Servizi esclusivamente per l'uso personale o interno, soggetta alla
        condizione che l'Utente (che non permetterà ad alcuna terza parte di
        farlo) non copi, modifichi, crei un'opera derivata, re-ingegnerizzi e
        re-assembli o in altro modo cerchi di scoprire qualsiasi codice
        sorgente, venda, ceda, conceda in sub-licenza, ceda in garanzia o in
        altro modo trasferisca qualsiasi diritto su o accesso al Sito, ai
        Servizi, o qualsiasi altro contenuto disponibile attraverso il Sito o i
        Servizi. Tutti i diritti non espressamente concessi agli Utenti nei
        presenti Termini sono riservati e detenuti da eWibe.
      </Paragraph>

      <Title big={props.fullscreen}>
        13. Contraffazioni e Manipolazioni della Piattaforma
      </Title>
      <Paragraph>
        eWibe si impegna a scoraggiare contraffazioni, frodi e manipolazione
        della Piattaforma con il massimo impegno. Se un Utente fornisce un
        prodotto contraffatto o tenta di truffare altri Utenti o eWibe, eWibe si
        riserva il diritto di intraprendere alcune o tutte le seguenti azioni, a
        propria esclusiva discrezione: (i) rimuovere tutte le offerte di vendita
        e acquisto dell'Utente dalla Piattaforma; (ii) cancellare tutti gli
        ordini in corso sulla Piattaforma; (iii) sospendere qualsiasi pagamento
        dovuto all'Utente; (iv) porre limiti ai privilegi di vendita e acquisto
        dell'Utente Verificato; (v) rispedire il prodotto all'Utente a spese di
        quest'ultimo (compresi eventuali dazi e spese doganali sostenute da
        eWibe); (vi) fornire i prodotti contraffatti alle forze dell'ordine o
        distruggerli, (vii) sospendere in modo temporaneo o definitivo l'account
        dell'Utente, e (viii) addebitare sul metodo di pagamento dell'Utente
        Verificato un importo minimo di € 100,00 o un importo fino al 15% del
        totale dell'operazione a esclusiva discrezione di eWibe, come tassa di
        servizio. Con la presente, l'Utente accetta che ciascuno dei rimedi
        sopracitati è ragionevole e giustificato al momento della scoperta da
        parte di eWibe di prodotti contraffatti o azioni fraudolente, anche se
        in quel momento tali prodotti o azioni non erano noti all'Utente come
        contraffatti o fraudolenti (ad esempio, per dolo). L'Utente comprende e
        accetta che se eWibe fornirà il prodotto contraffatto alle forze
        dell'ordine, le comunicazioni dell'Utente e qualsiasi rivendicazione
        della proprietà relative a quel prodotto devono avvenire solo presso le
        forze dell'ordine e non con eWibe.
      </Paragraph>
      <Paragraph>
        Per garantire l'integrità dei Servizi, eWibe potrà richiedere agli
        Utenti ulteriori dati di identificazione qualora vengano effettuate
        determinate transazioni di valore elevato o volumi di pagamento elevati
        tramite eWibe.
      </Paragraph>
      <Paragraph>
        Se un Compratore riceve un prodotto che crede sia contraffatto, il
        Compratore dovrà comunicarlo per iscritto a eWibe entro 3 giorni dalla
        ricezione del prodotto ed eWibe intraprenderà una ulteriore indagine sul
        prodotto. Il Compratore dovrà cooperare con eWibe nell'indagine e nel
        trattamento finale del prodotto, inclusa la fornitura di fotografie e di
        altre prove del prodotto, fornendo il prodotto alle forze dell'ordine,
        distruggendo il prodotto, o rispedendolo, su indicazione di eWibe. Se
        decidiamo che sia il Compratore a distruggere il prodotto, il Compratore
        fornirà ad eWibe una prova ragionevole della distruzione. Rimborseremo
        tutte le spese e i costi sostenuti dal Compratore per il prodotto
        (inclusi la spedizione e il trasporto). In nessun caso un Compratore può
        rivendere un prodotto (su eWibe o altrove) che si ritenga
        ragionevolmente contraffatto.
      </Paragraph>
      <Paragraph>
        eWibe può monitorare l'integrità della Piattaforma e può intervenire per
        proteggere la correttezza degli scambi e scoraggiare eventuali usi
        fraudolenti o lesivi della reputazione di eWibe, come stabilito da eWibe
        a propria esclusiva discrezione (ad esempio, se eWibe crede che un
        Venditore o un Compratore stia effettuando manipolazioni o frodi di
        mercato, incluso creando account falsi o “fittizi”). Se eWibe crede che
        un Utente tenti di interferire con le operazioni libere ed eque sulla
        Piattaforma, o crea informazioni artificiali, false o ingannevoli, o
        informazioni presumibilmente ingannevoli relative al prezzo o alla
        domanda di un prodotto sulla Piattaforma (tutto quanto sopra a esclusiva
        discrezione di eWibe), allora, senza limitare alcun altro diritto di
        eWibe, eWibe si riserva il diritto di intraprendere una o tutte le
        azioni che seguono, a propria esclusiva discrezione: (i) rimuovere
        alcune o tutte le inserzioni dell'Utente dalla Piattaforma; (ii)
        cancellare alcuni o tutti gli ordini in corso dell'Utente sulla
        Piattaforma; (iii) sospendere alcuni o tutti i pagamenti dovuti
        all'Utente; (iv) porre limiti ai privilegi di vendita e acquisto
        dell'Utente; (v) segnalare l'attività fraudolenta alle forze
        dell'ordine; (vi) sospendere in modo temporaneo o definitivo l'account
        dell'Utente, e (vii) addebitare sul metodo di pagamento dell'Utente un
        ulteriore importo fino a €1.000 per coprire le spese d'analisi da parte
        di eWibe e altri costi e spese relativi.
      </Paragraph>

      <Title big={props.fullscreen}>14. Limitazioni di responsabilità</Title>
      <Paragraph>
        Nella misura consentita dalla legge applicabile, l'Utente impegni a non
        citare in giudizio eWibe, e accetta di non ritenere eWibe responsabile
        per i contenuti, azioni o mancate azioni da parte di altri Utenti.
        L'Utente di acquistare prodotti da un soggetto terzo, non da eWibe.
        Mentre in qualità di intermediario eWibe potrà aiutare gli Utenti a
        facilitare la risoluzione delle controversie, non ne ha il controllo e
        non garantisce l'esattezza, qualità, sicurezza, veridicità o legalità di
        quanto compiuto da altri Utenti sulla Piattaforma, delle inserzioni e/o
        dei prodotti elencati o venduti.
      </Paragraph>
      <Paragraph>
        Nella misura consentita dalla legge applicabile, eWibe e/o i nostri
        fornitori terzi o licenzianti non dichiarano, garantiscono o si
        impegnano affinché la Piattaforma e i Servizi siano o saranno accurati,
        attuali, liberi da errori tecnici o tipografici, sicuri, affidabili o
        appropriati per qualsivoglia uso particolare l'Utente o qualsiasi
        soggetto terzo scelga di farne, che sono o saranno disponibili su una
        base ininterrotta o libera da errori, che i difetti saranno corretti o
        che il sistema e i servizi saranno liberi da virus o altri dispositivi
        disabilitanti o componenti dannose. eWibe periodicamente migliora,
        modifica, aggiunge, cancella, aggiorna o corregge la Piattaforma e i
        Servizi senza comunicarlo. Inoltre, nella misura consentita dalla legge
        applicabile, eWibe non si assume alcuna responsabilità per eventuali
        errori o omissioni nel contenuto della Piattaforma o dei Servizi. eWibe
        declina specificamente qualsiasi dovere di aggiornare i contenuti o
        qualsiasi altra informazione sui Servizi.
      </Paragraph>
      <Paragraph>
        Nella misura consentita dalla legge applicabile, l'Utente accetta di
        utilizzare i Servizi a suo rischio e che i Servizi sono forniti “così
        come sono” e “secondo disponibilità”. eWibe (compreso qualsiasi suo
        fornitore di servizi o licenziante) non fornisce garanzie di alcun tipo
        rispetto ai nostri Servizi, a qualsiasi prodotto a qualsiasi contenuto
        dell'Utente, o che i Compratori e i Venditori si comporteranno come
        promesso. Di conseguenza, nella misura consentita dalla legge
        applicabile, eWibe esclude qualsiasi garanzia implicita o esplicita,
        termini e condizioni inclusi, a titolo esemplificativo ma non esaustivo,
        garanzie implicite di commerciabilità, idoneità per uno scopo
        particolare, titolarità, non-violazione dei diritti di soggetti terzi, e
        qualsiasi garanzia sottintesa dall'esecuzione del contratto, dalle
        trattative o dagli usi commerciali.
      </Paragraph>
      <Paragraph>
        La responsabilità di eWibe o delle sue società collegate, e dei nostri e
        dei loro rispettivi funzionari, dirigenti, agenti, partecipanti a joint
        venture, dipendenti, rappresentanti legali e fornitori sarà limitata in
        conformità ai presenti termini d'uso nella misura massima consentita
        dalla legge.
      </Paragraph>

      <Title big={props.fullscreen}>15. Manleva</Title>
      <Paragraph>
        Nella misura consentita dalla legge applicabile, l'Utente si impegna a
        manlevare e tenere indenne eWibe, gli affiliati e i rispettivi
        funzionari, dirigenti, agenti e impiegati da qualsiasi reclamo,
        richiesta, causa o altri procedimenti e dalle conseguenti perdite,
        danni, responsabilità, costi, interessi e spese (inclusi i ragionevoli
        costi legali) avanzati da soggetti terzi o a seguito di richieste
        governative o comunque richieste che coinvolgano, riguardino o si
        riferiscano a (a) le inserzioni o la vendita di eventuali prodotti
        contraffatti, rubati o illegali, (b) la violazione di qualsiasi
        disposizione dei presenti Termini, (c) l'uso improprio dei Servizi, (d)
        la violazione di qualsiasi legge o dei diritti di una terza parte, (e)
        accesso non autorizzato al Sito, o con account di terzi (f) obblighi
        fiscali o importi dovuti o da corrispondere in base a qualsiasi
        normativa fiscale, legge, ordine o decreto. eWibe si riserva il diritto,
        di assumere la difesa e il controllo esclusivi di qualsiasi questione
        soggetta a una manleva da parte dell'Utente. Tutti i diritti e le
        manleve stabiliti nel presente documento sopravvivranno alla cessazione
        dei presenti Termini.
      </Paragraph>

      <Title big={props.fullscreen}>
        16. Legge applicabile e foro competente
      </Title>
      <Paragraph>
        I presenti Termini sono regolati dalla legge italiana, a prescindere
        dalle disposizioni sul conflitto di leggi. L'Utente e eWibe accettano
        che qualsiasi reclamo o controversia secondo i principi della legge o
        dell'equità, che si è verificata o potrebbe verificarsi tra noi, che in
        qualsiasi modo sia relativa o che si verifichi in base ai Termini o
        all'utilizzo o l'accesso ai Servizi da parte tua, sarà rimessa alla
        competenza esclusiva del Foro di Milano.
      </Paragraph>
      <Paragraph>
        L'Utente potrà anche richiedere agli organi competenti un meccanismo
        stragiudiziale per risolvere qualsiasi controversia relativa ai Termini.
        Ad esempio, la piattaforma europea di risoluzione delle controversie
        online:{" "}
        <StyledAnchor href="https://ec.europa.eu/consumers/odr">
          https://ec.europa.eu/consumers/odr
        </StyledAnchor>{" "}
        fornisce informazioni sui sistemi di risoluzione alternativa delle
        controversie nell'Unione Europea che possono essere di interesse
        dell'Utente, che rimane comunque libero di scegliere un meccanismo
        stragiudiziale alternativo.
      </Paragraph>
      <Paragraph>
        I Venditori che intendano presentare un reclamo in relazione ai presenti
        Termini e/o ai Servizi potranno contattare il servizio clienti
        all'indirizzo:{" "}
        <StyledAnchor href="mailto:help@ewibe.com">help@ewibe.com</StyledAnchor>
        . Il reclamo sarà poi sottoposto al team di eWibe competente per essere
        esaminato. eWibe farà del suo meglio per fornire una prima risposta
        entro 48 ore e, in ogni caso, entro un tempo ragionevole (tenendo conto
        dell'importanza e della complessità del reclamo). eWibe tenterà di
        risolvere il reclamo entro 30 giorni dal ricevimento.
      </Paragraph>
      <Paragraph>
        Un Venditore può richiedere che un reclamo venga trattato e registrato
        come un reclamo di natura regolamentare in conformità al Regolamento
        (UE) 2019/1150 (il “Regolamento Platform to Business”) notificando per
        iscritto tale richiesta (come parte dell'avviso di reclamo) al servizio
        clienti, a condizione che il reclamo abbia per oggetto una o più delle
        seguenti questioni: (a) presunta inosservanza da parte di eWibe di uno
        qualsiasi degli obblighi previsti dal Regolamento Platform to Business;
        (b) questioni tecnologiche che si riferiscono direttamente alla
        fornitura dei Servizi; e/o (c) misure adottate da eWibe o il
        comportamento di eWibe che si riferiscono direttamente alla fornitura
        dei Servizi.
      </Paragraph>
      <Paragraph>
        Nulla di quanto previsto dalla presente sezione impedirà ai Venditori o
        a eWibe di intraprendere o proseguire qualsiasi procedimento legale in
        qualsiasi momento in relazione a qualsiasi controversia o reclamo.
      </Paragraph>

      <Title big={props.fullscreen}>17. Privacy</Title>
      <Paragraph>
        L'utilizzo dei dati personali inviati a o tramite il Sito è regolato
        dalla Informativa sulla Privacy disponibile cliccando il{" "}
        <StyledAnchor href="https://www.ewibe.com/privacy">
          seguente link
        </StyledAnchor>
        . eWibe utilizzerà i tuoi dati personali solo in conformità con la
        Informativa sulla Privacy. L'Utente è pregato di leggere attentamente
        questo documento, in quanto contiene informazioni importanti sulle
        modalità di raccolta e utilizzo dei dati personali.
      </Paragraph>

      <Title big={props.fullscreen}>18. Accesso ai dati non personali</Title>
      <Paragraph>
        eWibe avrà accesso anche a dati non personali degli utenti registrati
        che sono generati attraverso l'uso del Sito o dei Servizi, e che
        verranno utilizzati in modo da garantire l'anonimato degli stessi
        Utenti, per svolgere ed offrire report e analisi di mercato sulla
        Piattaforma. Ciò include, ad esempio, informazioni sui prodotti dei
        Venditori da esporre per l'acquisto sul Sito, le informazioni fornite da
        Compratori e Venditori al momento della creazione di un account e le
        informazioni su tutti gli acquisti di prodotti sui Sito. eWibe condivide
        le informazioni a cui ha accesso con i fornitori di servizi terzi al
        fine di fornire i Servizi e migliorare l'esperienza degli utenti
        registrati. eWibe conserverà tali informazioni anche dopo che un Utente
        avrà disattivato o chiuso il proprio account, in conformità alla legge
        applicabile.
      </Paragraph>
      <Paragraph>
        I Venditori avranno accesso a determinati dati attraverso il loro
        profilo online. Ciò include, ad esempio, i dati sulla loro storia di
        vendita, comprese le richieste e le offerte precedenti. I Venditori non
        avranno accesso ai dati personali forniti dai Compratori. I Venditori
        avranno accesso anche ad alcuni dati resi disponibili al pubblico sul
        Sito. Ciò include, ad esempio, i prezzi di domanda e offerta, le
        descrizioni dei prodotti e la relativa notorietà dei prodotti venduti
        sulla Piattaforma.
      </Paragraph>

      <Title big={props.fullscreen}>19. Clausole generali</Title>
      <Paragraph>
        I presenti Termini e tutti i termini e le informative pubblicate
        attraverso i nostri Servizi (in quanto ciascuno può essere rivisto e
        modificato periodicamente in base alle rispettive condizioni)
        costituiscono l'intero contratto tra l'Utente e eWibe relativo
        all'utilizzo dei Servizi, e sostituiscono tutti i precedenti accordi e
        parti del contratto. L'Utente acconsente a ricevere comunicazioni via
        e-mail all'indirizzo fornito in fase di registrazione.
      </Paragraph>
      <Paragraph>
        Se una disposizione dei presenti Termini è ritenuta non valida, nulla o
        non eseguibile in qualsiasi circostanza, tale disposizione sarà
        considerata separabile e non avrà effetti sulla validità ed eseguibilità
        delle rimanenti disposizioni dei Termini.
      </Paragraph>
      <Paragraph>
        Eventuali rinunce a qualsiasi disposizione contenuta nei presenti
        Termini da parte di eWibe non saranno considerate come rinuncia a
        qualsiasi altro diritto, termine o disposizione dei presenti Termini.
        Qualsiasi diritto non espressamente concesso nel presente documento è
        riservato.
      </Paragraph>
      <Paragraph>
        eWibe può assegnare o trasferire i diritti o obblighi ai sensi dei
        presenti Termini in qualsiasi momento. L'Utente non può assegnare o
        trasferire i diritti od obblighi ai sensi dei presenti Termini o
        qualsiasi diritto o obbligo, senza il previo consenso scritto di eWibe,
        che potrà essere negato a sua esclusiva discrezione.
      </Paragraph>
      <Paragraph>
        Nessuna relazione di agenzia, partnership, joint venture, rapporto tra
        datore di lavoro-dipendente o franchisor-franchisee è intesa o creata da
        questi Termini, dall'uso dei Servizi o dalla fornitura dei nostri
        Servizi. L'Utente non potrà stipulare alcun contratto per conto di eWibe
        o vincolare eWibe in alcun modo. I presenti Termini sono solo a
        beneficio degli Utenti e non a beneficio di qualsiasi altra terza parte,
        fatta eccezione per i successori e assegnatari autorizzati di eWibe.
      </Paragraph>
    </Box>
  );
};

export default TermsAndConditions;
