import { FormTelephone, FormTelephoneProps } from "@ewibecom/design-system";
import { Controller, Control } from "react-hook-form";

export interface ControlledFormTelephoneProps extends FormTelephoneProps {
  control: Control<any, any>;
  setDialCode: React.Dispatch<React.SetStateAction<string>>;
}

const ControlledFormTelephone: React.FC<ControlledFormTelephoneProps> = (
  props
) => {
  return (
    <Controller
      name={props.fieldName || (props.placeholder as string)}
      control={props.control}
      render={({ field }) => (
        <FormTelephone
          fieldName={field.name}
          value={field.value}
          onPhoneNumberChange={(_isValid, number, fullNumber) => {
            field.onChange(number.replace(/ /g, ""));
            props.setDialCode(fullNumber?.dialCode ?? "");
          }}
          onPhoneNumberBlur={field.onBlur}
          placeholder={props.placeholder}
          {...props}
        />
      )}
    />
  );
};

export default ControlledFormTelephone;
