import { Button, ButtonProps } from "@ewibecom/design-system";
import { useState } from "react";
import useStore, { UserStore } from "store";
import AlertModal from "./AlertModal";

const VerifyButton: React.FC<ButtonProps> = (props) => {
  const { user } = useStore((state) => state.user) as UserStore;
  const [show, setShow] = useState(false);

  return (
    <>
      <AlertModal show={show} setShow={setShow} wallet />
      <Button
        {...props}
        onClick={(e) => {
          if (user?.wallet?.ewallet?.kycUploadState !==2) setShow(true);
          else if (props.onClick) {
            props.onClick(e as any);
          }
        }}
      />
    </>
  );
};

export { VerifyButton };
