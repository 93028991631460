import { LimitOrderRequest, MarketOrderRequest, Option, OrderBook, OrderBookUnderlying } from '@ewibecom/sdk';
import { EwibeSDK as sdk } from 'config';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { AsyncState } from './utils';

interface AsyncStore {
  getOrderBook: (wineId: number) => Promise<OrderBook | false>;
  getUnderlyingOrderBooks: (wineId: number) => Promise<OrderBookUnderlying[] | false>;
  buyMarket: (params: MarketOrderRequest) => Promise<boolean>;
  buyLimit: (params: LimitOrderRequest) => Promise<boolean>;
  sellMarket: (params: MarketOrderRequest) => Promise<boolean>;
  sellLimit: (params: LimitOrderRequest) => Promise<boolean>;
  deleteOrder: (orderId: number) => Promise<boolean>;
}

export interface TradingStore extends AsyncState, AsyncStore {
  orderbook?: OrderBook;
  underlying?: OrderBookUnderlying[];
}

export const useTradingStore = create<TradingStore>()(
  devtools(
    persist(
      ((set, get) => ({
        error: null,
        isLoading: false,
        getOrderBook: async (wineId: number): Promise<OrderBook | false> => {
          try {
            set({ isLoading: true });
            const orderbook = await sdk.trading.getOrderBook(wineId);
            set({ orderbook, error: null, isLoading: false });
            return orderbook;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        getUnderlyingOrderBooks: async (wineId: number): Promise<OrderBookUnderlying[] | false> => {
          try {
            set({ isLoading: true });
            const underlying = await sdk.trading.getUnderlyingsOrderBook(wineId);
            set({ underlying, error: null, isLoading: false });
            return underlying;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        buyMarket: async (params: MarketOrderRequest): Promise<boolean> => {
          try {
            set({ isLoading: true });
            await sdk.trading.buyMarket(params);

            let underlying = JSON.parse(JSON.stringify(get().underlying)) as OrderBookUnderlying[];
            if (underlying?.length) {
              let orderbook = underlying?.find(u => u.child.id === params.wine_id);
              if (orderbook) {
                if (orderbook?.book?.sellingOptions) {
                  const match = orderbook?.book?.sellingOptions.findIndex((s: Option) => {
                    return s.unitPrice <= params.unit_price
                  });
                  if (match !== -1) {
                    if (orderbook?.book?.sellingOptions[match].amount <= params.amount) {
                      let update = orderbook?.book?.sellingOptions.filter((_, i) => i !== match);
                      orderbook.book.sellingOptions = update;
                    } else {
                      let update = orderbook?.book?.sellingOptions.map(
                        (s, i) => i !== match ? s : { ...s, amount: s.amount - params.amount });
                      orderbook.book.sellingOptions = update;
                    }
                  } else {
                    throw new Error("no available offer");
                  }
                } else {
                  throw new Error("unexpected error");
                }

                set({
                  underlying: underlying?.map(u => u.child.id === params.wine_id ? orderbook as OrderBookUnderlying : u),
                  error: null,
                  isLoading: false
                })
                return true;
              }
            }

            set({
              error: null,
              isLoading: false
            })

            return false;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        buyLimit: async (params: LimitOrderRequest): Promise<boolean> => {
          try {
            set({ isLoading: true });
            await sdk.trading.buyLimit(params);
            set({ error: null, isLoading: false });
            return true;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        sellMarket: async (params: MarketOrderRequest): Promise<boolean> => {
          try {
            set({ isLoading: true });
            await sdk.trading.sellMarket(params);

            let underlying = JSON.parse(JSON.stringify(get().underlying)) as OrderBookUnderlying[];
            if (underlying?.length) {
              let orderbook = underlying?.find(u => u.child.id === params.wine_id);
              if (orderbook) {
                if (orderbook?.book?.buyingOptions) {
                  const match = orderbook?.book?.buyingOptions.findIndex((b: Option) => {
                    return b.unitPrice >= params.unit_price
                  });
                  if (match !== -1) {
                    if (orderbook?.book?.buyingOptions[match].amount <= params.amount) {
                      let update = orderbook?.book?.buyingOptions.filter((_, i) => i !== match);
                      orderbook.book.buyingOptions = update;
                    } else {
                      let update = orderbook?.book?.buyingOptions.map(
                        (b, i) => i !== match ? b : { ...b, amount: b.amount - params.amount });
                      orderbook.book.buyingOptions = update;
                    }
                  } else {
                    throw new Error("no available offer");
                  }
                } else {
                  throw new Error("unexpected error");
                }

                set({
                  underlying: underlying?.map(u => u.child.id === params.wine_id ? orderbook as OrderBookUnderlying : u),
                  error: null,
                  isLoading: false
                })
                return true;
              }
            }

            set({
              error: null,
              isLoading: false
            })

            return false;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        sellLimit: async (params: LimitOrderRequest): Promise<boolean> => {
          try {
            set({ isLoading: true });
            await sdk.trading.sellLimit(params);
            set({ error: null, isLoading: false });
            return true;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        deleteOrder: async (orderId: number): Promise<boolean> => {
          try {
            set({ isLoading: true });
            await sdk.trading.deleteOrder(orderId);
            set({ error: null, isLoading: false });
            return true;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
      })), {
      name: "ewibe-trading-store",
      partialize: (state) => ({ orderbook: state.orderbook, underlying: state.underlying })
    })
  )
)