import { Box } from "@ewibecom/design-system";
import { notifySuccess } from "components/Custom/Toast";

import { useCopyToClipboard } from "react-use";
import useTranslate from "translate";

export interface CopyBoxProps {
  content: string;
  children: React.ReactNode;
}

const CopyBox: React.FC<CopyBoxProps> = (props) => {
  const t = useTranslate("shared.");
  const [, copyToClipboard] = useCopyToClipboard();

  return (
    <Box
      onClick={() => {
        copyToClipboard(props.content);
        notifySuccess(t("copy_clipboard"));
      }}
    >
      {props.children}
    </Box>
  );
};

export default CopyBox;
