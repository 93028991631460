import { Box, Button, Modal, Spinner } from "@ewibecom/design-system";
import { useIsSmall } from "hooks";
import { useCallback, useEffect, useState } from "react";
import { useKey } from "react-use";
import useStore, { UserStore } from "store";
import useTranslate from "translate";
import { formattedPriceToNumber } from "utils";
import {
  AlertModal,
  FundsForm,
  MangoPayFrame,
  PaymentMethodForm,
} from "./Verify";

export interface AddFundsModalProps {
  show?: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddFundsModal: React.FC<AddFundsModalProps> = ({ show, setShow }) => {
  const t = useTranslate("verification.");
  const small = useIsSmall();
  const { user, isLoading } = useStore((state) => state.user) as UserStore;
  const [step, setStep] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [showFunds, setShowFunds] = useState(false);
  const [showFrame, setShowFrame] = useState(false);
  const [errorFormPrice, setErrorFormPrice] = useState(false);
  const [cardCheck, setCardCheck] = useState(false);
  const [transferCheck, setTransferCheck] = useState(false);

  const [formPriceValue, setFormPriceValue] = useState("");

  useEffect(() => {
    if (show) {
      if (user?.wallet?.ewallet?.kycUploadState === 2) {
        setShowFunds(true);
      } else {
        setShowAlert(true);
      }
    } else {
      setShowAlert(false);
      setShowFunds(false);
    }
  }, [show, user]);

  const handlePrevious = async () => {
    if (!step) {
      setShow(false);
    } else {
      setStep(0);
    }
  };

  const handleNext = async () => {
    if (step === 0) {
      if (formattedPriceToNumber(formPriceValue) >= 50) {
        setStep(1);
      } else {
        setErrorFormPrice(true);
      }
    } else if (step === 1) {
      if (cardCheck) {
        setShowFrame(true);
      } else {
        setShow(false);
      }
    } else {
      setShow(false);
    }
  };
  useKey("Enter", handleNext, undefined, []);

  const onPaymentResult = useCallback(() => {
    setShow(false);
  }, [setShow]);

  if (!show) return <></>;

  if (showAlert) {
    return <AlertModal show={showAlert} setShow={setShow} wallet />;
  }

  return (
    <>
      {showFrame && (
        <MangoPayFrame
          price={formattedPriceToNumber(formPriceValue)}
          currency={"EUR"}
          open={showFrame}
          setOpen={setShowFrame}
          onComplete={onPaymentResult}
        />
      )}
      <Modal
        title={t("add_funds")}
        subtitle={t(
          step === 0
            ? ""
            : step === 1
            ? "payment_method"
            : "successful_operation"
        )}
        body={
          <Box
            justify="center"
            align="center"
            fill
            pad={{ vertical: "small", horizontal: "0" }}
            gap="medium"
          >
            {isLoading ? (
              <Spinner />
            ) : step === 0 ? (
              <FundsForm
                value={formPriceValue}
                setValue={setFormPriceValue}
                errorFormPrice={errorFormPrice}
              />
            ) : step === 1 ? (
              <PaymentMethodForm
                checked={cardCheck}
                setChecked={setCardCheck}
                checked2={transferCheck}
                setChecked2={setTransferCheck}
              />
            ) : (
              false
            )}
          </Box>
        }
        leftAction={
          <Button
            label={t("back")}
            tertiary
            onClick={handlePrevious}
            width={ small ? "7.5rem" : "12.5rem"}
            height={small ? "3rem" : "3.5rem"}
          />
        }
        rightAction={
          <Button
            label={t(
              step === 1 && transferCheck ? "transfer_executed" : "continue"
            )}
            disabled={
              (step === 0 && formPriceValue === "") ||
              (errorFormPrice &&
                formattedPriceToNumber(formPriceValue) <= 50) ||
              (step === 1 && !cardCheck && !transferCheck)
            }
            secondary
            width={small && step === 5 ? "7.5rem" : small ? "7.5rem" : "12.5rem"}
            height={small ? "3rem" : "3.5rem"}
            onClick={handleNext}
          />
        }
        show={showFunds}
        setShow={setShow}
      />
    </>
  );
};

export { AddFundsModal };
