import {
  Box,
  Button,
  Grid,
  Tooltip,
  Typography,
  theme,
} from "@ewibecom/design-system";
import { BlueBottleBackground, Glass } from "assets/icons";
import { PATHS } from "config";
import { useIsSmall } from "hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import useTranslate from "translate";
import { Section } from "./Section";

const StyledGrid = styled(Grid)`
  ${({ theme }) => css`
    border-radius: 1rem;
    background: ${theme.global.colors.background};
    box-shadow: ${theme.global.boxShadow.default};
    padding: 2rem;
    ${(props: PlanCardProps) =>
      props.selected &&
      css`
        border: 1px solid ${theme.global.colors.secondary.default};
      `}
    ${(props: PlanCardProps) =>
      props.noCard &&
      css`
        background: ${theme.global.colors.white};
        box-shadow: none;
      `}
  `}
`;

const features = [
  {
    feature: "transaction_fees",
    tooltip: "transaction_tooltip",
    basic: "trans_fee_basic",
    standard: "trans_fee_standard",
    professional: "trans_fee_professional",
  },
  {
    feature: "deposit_fees",
    tooltip: "deposit_tooltip",
    basic: "dep_fee_basicstandard",
    standard: "dep_fee_basicstandard",
    professional: "dep_fee_professional",
  },
  {
    feature: "release_fees",
    tooltip: "release_tooltip",
    basic: "rel_fee_basicstandard",
    standard: "rel_fee_basicstandard",
    professional: "rel_fee_professional",
  },
  {
    feature: "historical_data",
    tooltip: "data_tooltip",
    basic: "last_months",
    standard: "up_to",
    professional: "up_to",
  },
  {
    feature: "additional_analysis",
    tooltip: "analysis_tooltip",
    basic: "no",
    standard: "no",
    professional: "yes",
  },
  {
    feature: "offline_cellar",
    tooltip: "offline_cellar_tooltip",
    basic: "no",
    standard: "yes",
    professional: "yes",
  },
  {
    feature: "auction",
    tooltip: "auction_tooltip",
    basic: "no",
    standard: "no",
    professional: "yes",
  },
  {
    feature: "pre_market",
    tooltip: "pre_market_tooltip",
    basic: "no",
    standard: "no",
    professional: "yes",
  },
  {
    feature: "dedicated_consultancy",
    tooltip: "consultancy_tooltip",
    basic: "no",
    standard: "no",
    professional: "yes",
  },
];

type Plan = "basic" | "standard" | "professional";

export interface PlanCardProps {
  selected?: boolean;
  noCard?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
}

const PlanCard: React.FC<PlanCardProps> = (props) => {
  const small = useIsSmall();

  return (
    <StyledGrid
      style={{ cursor: "pointer" }}
      rows={small ? ["5rem", "flex", "xsmall"] : ["18rem", "flex", "xsmall"]}
      columns={["flex"]}
      areas={[
        { name: "header", start: [0, 0], end: [0, 0] },
        { name: "body", start: [0, 1], end: [0, 1] },
        { name: "footer", start: [0, 2], end: [0, 2] },
      ]}
      justify="center"
      align={small ? "start" : "center"}
      width={small ? "100%" : "25%"}
      onClick={() => {
        if (props.onClick) props.onClick();
      }}
      selected={props.selected}
      pad="2rem"
      noCard={props.noCard}
    >
      {props.children}
    </StyledGrid>
  );
};

export interface InfoTooltipProps {
  content: string;
}

const InfoTooltip: React.FC<InfoTooltipProps> = (props) => {
  const t = useTranslate("landing.");
  return (
    <Tooltip
      dropProps={{
        background: (theme as any).global.colors.gray6,
        round: "small",
        width: "15rem",
        margin: "0.5rem",
      }}
      content={
        <Box width="15rem" style={{ borderRadius: "0.5rem" }} fill>
          <Typography fill>{t(props.content)}</Typography>
        </Box>
      }
    />
  );
};

export interface HeaderProps {
  icon: React.ReactNode;
  plan: string;
  rate: string;
}

const Header: React.FC<HeaderProps> = (props) => {
  const small = useIsSmall();
  const t = useTranslate("landing.");

  return (
    <Box gridArea="header" align={small ? "start" : "center"} fill>
      {!small && props.icon}
      <Typography
        type={small ? "subtitle1" : "h6"}
        color={(theme as any).global.colors.primary.default}
        margin={{
          top: small ? "0" : "2rem",
          bottom: small ? "1rem" : "2rem",
        }}
      >
        {t(props.plan).toUpperCase()}
      </Typography>
      <Typography
        type={small ? "h5" : "h3"}
        margin={{ top: "0", bottom: "2rem" }}
      >
        {t(props.rate)}
      </Typography>
    </Box>
  );
};

export interface FooterProps {
  button: string;
  disabledButton?: boolean;
  onClick?: () => void;
}

const Footer: React.FC<FooterProps> = (props) => {
  const t = useTranslate("landing.");

  return (
    <Box gridArea="footer" fill>
      <Button
        secondary
        disabled={props.disabledButton}
        label={t(props.button)}
        width="100%"
        margin={{ top: "2rem" }}
        height="3rem"
        onClick={() => {
          if (props.onClick) props.onClick();
        }}
      />
    </Box>
  );
};

const PricingBanner: React.FC = () => {
  const t = useTranslate("landing.");
  const small = useIsSmall();
  const [plan, setPlan] = useState<Plan>("basic");
  const navigate = useNavigate();

  return (
    <Section direction={small ? "column" : "row"} gap="medium" small={small}>
      {!small && (
        <PlanCard noCard>
          <Box gridArea="header" height="100%" fill />
          <Box
            gridArea="body"
            fill
            style={{
              borderTop: `1px solid ${(theme as any).global.colors.gray4}`,
            }}
          >
            {features.map((e) => (
              <Box key={e.feature} width="100%">
                <Box
                  direction="row"
                  justify="between"
                  width="100%"
                  align="center"
                >
                  <Typography
                    type="subtitle1"
                    margin={{ top: "1rem", bottom: "1rem" }}
                  >
                    {t(e.feature)}
                  </Typography>
                  <InfoTooltip content={e.tooltip} />
                </Box>
                <Box
                  height="1px"
                  background={(theme as any).global.colors.gray4}
                  width="100%"
                />
              </Box>
            ))}
          </Box>
          <Box gridArea="footer" fill />
        </PlanCard>
      )}

      {/* Basic Plan */}
      <PlanCard selected={plan === "basic"} onClick={() => setPlan("basic")}>
        <Header icon={<Glass />} plan="basic" rate="rate_basic" />
        <Box
          gridArea="body"
          fill
          style={{
            borderTop: `1px solid ${(theme as any).global.colors.gray4}`,
          }}
        >
          {features.map((e) => (
            <Box width="100%" key={e.feature}>
              {!small ? (
                <Box align="start">
                  <Typography margin={{ top: "1rem", bottom: "1rem" }}>
                    {t(e.basic)}
                  </Typography>
                </Box>
              ) : (
                <Box justify="between" direction="row">
                  <Box
                    direction="row"
                    justify="between"
                    width="50%"
                    margin={{ right: "1rem" }}
                  >
                    <Typography
                      type="subtitle2"
                      margin={{ top: "1rem", bottom: "1rem" }}
                    >
                      {t(e.feature)}
                    </Typography>
                  </Box>
                  <Box align="start" justify="center" width="10%">
                    <InfoTooltip content={e.tooltip} />
                  </Box>
                  <Typography
                    type="subtitle2"
                    margin={{ top: "1rem", bottom: "1rem" }}
                    width="30%"
                    textAlign="end"
                  >
                    {t(e.basic)}
                  </Typography>
                </Box>
              )}
              <Box
                height="1px"
                background={(theme as any).global.colors.gray4}
                width="100%"
              />
            </Box>
          ))}
        </Box>
        <Footer button="active" disabledButton />
      </PlanCard>

      {/* Standard Plan */}
      <PlanCard
        selected={plan === "standard"}
        onClick={() => setPlan("standard")}
      >
        <Header
          icon={<BlueBottleBackground />}
          plan="standard"
          rate="coming_soon"
        />
        <Box
          gridArea="body"
          fill
          style={{
            borderTop: `1px solid ${(theme as any).global.colors.gray4}`,
          }}
        >
          {features.map((e) => (
            <Box width="100%" key={e.feature}>
              {!small ? (
                <Box align="start">
                  <Typography margin={{ top: "1rem", bottom: "1rem" }}>
                    {t(e.standard)}
                  </Typography>
                </Box>
              ) : (
                <Box justify="between" direction="row">
                  <Box
                    direction="row"
                    justify="between"
                    width="50%"
                    margin={{ right: "1rem" }}
                  >
                    <Typography
                      type="subtitle2"
                      margin={{ top: "1rem", bottom: "1rem" }}
                    >
                      {t(e.feature)}
                    </Typography>
                  </Box>
                  <Box align="start" justify="center" width="10%">
                    <InfoTooltip content={e.tooltip} />
                  </Box>
                  <Typography
                    type="subtitle2"
                    margin={{ top: "1rem", bottom: "1rem" }}
                    width="30%"
                    textAlign="end"
                  >
                    {t(e.standard)}
                  </Typography>
                </Box>
              )}
              <Box
                height="1px"
                background={(theme as any).global.colors.gray4}
                width="100%"
              />
            </Box>
          ))}
        </Box>
        <Footer
          button="tell_us_yours"
          onClick={() => navigate(PATHS.LANDING.CONTACTS)}
        />
      </PlanCard>

      {/* Professional Plan */}
      <PlanCard
        selected={plan === "professional"}
        onClick={() => setPlan("professional")}
      >
        <Header
          icon={<BlueBottleBackground />}
          plan="professional"
          rate="coming_soon"
        />
        <Box
          gridArea="body"
          fill
          style={{
            borderTop: `1px solid ${(theme as any).global.colors.gray4}`,
          }}
        >
          {features.map((e) => (
            <Box width="100%" key={e.feature}>
              {!small ? (
                <Box align="start">
                  <Typography margin={{ top: "1rem", bottom: "1rem" }}>
                    {t(e.professional)}
                  </Typography>
                </Box>
              ) : (
                <Box justify="between" direction="row">
                  <Box
                    direction="row"
                    justify="between"
                    width="50%"
                    margin={{ right: "1rem" }}
                  >
                    <Typography
                      type="subtitle2"
                      margin={{ top: "1rem", bottom: "1rem" }}
                    >
                      {t(e.feature)}
                    </Typography>
                  </Box>
                  <Box align="start" justify="center" width="10%">
                    <InfoTooltip content={e.tooltip} />
                  </Box>
                  <Typography
                    type="subtitle2"
                    margin={{ top: "1rem", bottom: "1rem" }}
                    width="30%"
                    textAlign="end"
                  >
                    {t(e.professional)}
                  </Typography>
                </Box>
              )}
              <Box
                height="1px"
                background={(theme as any).global.colors.gray4}
                width="100%"
              />
            </Box>
          ))}
        </Box>
        <Footer
          button="tell_us_yours"
          onClick={() => navigate(PATHS.LANDING.CONTACTS)}
        />
      </PlanCard>
    </Section>
  );
};

export default PricingBanner;
