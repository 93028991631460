import { Box, Button, Typography } from "@ewibecom/design-system";
import { useIsSmall } from "hooks";
import { useTheme } from "styled-components";
import useTranslate from "translate";
import { Section } from "./Section";

interface StartNowBannerProps {
  title?: string;
  description?: string;
  button?: string;
  inverse?: boolean;
  background?: string;
  onClick?: () => void;
}

const StartNowBanner: React.FC<StartNowBannerProps> = (props) => {
  const t = useTranslate("landing.");
  const theme = useTheme();
  const small = useIsSmall();

  return (
    <Section
      small={small}
      direction={small ? "column" : "row"}
      justify={small ? "center" : "between"}
      background={props.background}
      gap="medium"
    >
      <Box width={small ? "100%" : "50%"} gap="medium">
        <Typography
          type={small ? "h6" : "h4"}
          textAlign={small ? "center" : "start"}
          margin="0"
          weight={600}
          maxWidth="100%"
        >
          {props.title || t("not_miss")}
        </Typography>
        {!small && props.inverse && (
          <Button
            secondary
            label={props.button || t("start_now_button")}
            height="3rem"
            onClick={() => {
              if (props.onClick) props.onClick();
            }}
          />
        )}
      </Box>
      <Box
        width={small ? "100%" : "50%"}
        align={small ? "center" : "start"}
        gap="medium"
      >
        <Typography
          textAlign={small ? "center" : "start"}
          type={small ? "body1" : "h5"}
          margin={small ? { vertical: "small" } : "0"}
          maxWidth="100%"
          weight={600}
          color={(theme as any).global.colors.secondary.default}
        >
          {props.description || t("not_miss_description")}
        </Typography>
        {(!props.inverse || small) && (
          <Button
            secondary
            label={props.button || t("start_now")}
            height="3rem"
            onClick={() => {
              if (props.onClick) props.onClick();
            }}
          />
        )}
      </Box>
    </Section>
  );
};

export { StartNowBanner };
