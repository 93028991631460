import { Carousel, Typography } from "@ewibecom/design-system";
import {
  GQ,
  GQWebp,
  LaRepubblica,
  LaRepubblicaWebp,
  Sole24Ore,
  Sole24OreWebp,
  Wealth,
  WealthWebp,
  Corriere,
  CorriereWebp,
} from "assets/images";
import { ImageFallback } from "components/Custom";
import useTranslate from "translate";
import { Section } from "../Section";

const AboutUsBanner: React.FC = () => {
  const t = useTranslate("landing.");

  return (
    <Section>
      <Typography type="h3" maxWidth="100%" margin={{ top: "0" }}>
        {t("about_us")}
      </Typography>
      <Carousel dots={false} show={3}>
        <ImageFallback src={WealthWebp} fallback={Wealth} alt="wealth" />
        <ImageFallback src={GQWebp} fallback={GQ} alt="GQ" />
        <ImageFallback
          src={Sole24OreWebp}
          fallback={Sole24Ore}
          alt="Sole24Ore"
        />
        <ImageFallback
          src={LaRepubblicaWebp}
          fallback={LaRepubblica}
          alt="LaRepubblica"
        />
        <ImageFallback
          src={CorriereWebp}
          fallback={Corriere}
          alt="Corriere"
        />
      </Carousel>
    </Section>
  );
};

export default AboutUsBanner;
