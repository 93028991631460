
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import {
  Box,
  Checkbox,
  FormDynamic,
  Icon,
  Typography,
  theme,
} from "@ewibecom/design-system";
import { WhiteAutonomy, WhitePassion } from "assets/icons";
import { Toast } from "components/Custom";
import { useIsSmall } from "hooks";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useStore, { MarketingStore } from "store";
import styled, { css } from "styled-components";
import useTranslate, { useTranslateState } from "translate";
import * as yup from "yup";

const StyledBox = styled(Box)`
  ${({ theme }) => css`
    background: ${theme.global.colors.secondary.default};
    box-shadow: 5px 5px 15px 0px #00000080;
    box-shadow: -5px -5px 15px 0px #333e5c80;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    z-index: 200;

    position: fixed;
    cursor: pointer;
    right: 0;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  `}
`;

const Guide: React.FC = () => {
  const sendDataToGTM = useGTMDispatch();
  const location = useLocation();
  const small = useIsSmall();
  const t = useTranslate("landing.");
  const language = useTranslateState();
  const [state, setState] = useState(0);
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState("");
  const [removeTooltip, setRemoveTooltip] = useState(false);
  const emailSchema = yup.string().required().email();

  const { postRequestGuide, error, showRequestGuide } = useStore(
    (state) => state.marketing
  ) as MarketingStore;

  const handleRequestGuide = async () => {
    if (emailSchema.isValidSync(email)) {
      const resp = await postRequestGuide(email);
      if (resp && checked) {
        setState(2);

        const gtmTag = {
          event: "guide_download",
          device: navigator.userAgent,
          user_mail: email,
          website_language: language,
          page_type: location
        };
        sendDataToGTM(gtmTag);
      }
    } else {
      Toast.notifyError(t("error mail"));
    }
  };

  useEffect(() => {
    Toast.notifyError(error);
  }, [error]);

  return (
    <>
      {!removeTooltip && state !== 2 ? (
        !state ? (
          <StyledBox
            gap="small"
            width="min-content"
            onClick={() => setState(1)}
          >
            <WhitePassion />
            <Typography
              type="h6"
              light
              margin={{ vertical: "0", horizontal: "0" }}
              textAlign="center"
              maxWidth="100%"
              color={(theme as any).global.colors.white}
            >
              {t("guide")}
            </Typography>
          </StyledBox>
        ) : (
          <StyledBox gap="medium" width={small && state ? "90%" : "26rem"}>
            <Icon
              type="Close"
              width="1.5rem"
              style={{ alignSelf: "start" }}
              color={(theme as any).global.colors.white}
              onClick={() => setState(0)}
            />
            <WhitePassion
              style={{ alignSelf: "center" }}
              height="4rem"
              width="4rem"
            />
            <Typography
              type="h6"
              light
              margin={{ vertical: "1rem", horizontal: "0" }}
              textAlign="center"
              maxWidth="70%"
              color={(theme as any).global.colors.white}
            >
              {t("request_guide")}
            </Typography>
            <FormDynamic
              width="80%"
              actionLabel={t("request")}
              placeholder={t("email")}
              buttonProps={{
                primary: true,
                disabled: email && checked ? false : true,
              }}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              onSubmit={handleRequestGuide}
            />
            <Checkbox
              label={
                <Typography color={(theme as any).global.colors.white}>
                  {t("privacy")}
                </Typography>
              }
              onClick={() => setChecked(!checked)}
              checked={checked}
            />
          </StyledBox>
        )
      ) : !removeTooltip && !showRequestGuide && state === 2 ? (
        <StyledBox gap="small" width={small && state ? "90%" : "26rem"}>
          <Icon
            type="Close"
            width="1.5rem"
            style={{ alignSelf: "start" }}
            color={(theme as any).global.colors.white}
            onClick={() => {
              setState(0);
              setRemoveTooltip(true);
            }}
          />
          <WhiteAutonomy
            style={{ alignSelf: "center" }}
            height="4rem"
            width="4rem"
          />
          <Typography
            type="h6"
            light
            margin={{ vertical: "1rem", horizontal: "0" }}
            textAlign="center"
            maxWidth="70%"
            color={(theme as any).global.colors.white}
          >
            {t("sent_email")}
          </Typography>
        </StyledBox>
      ) : (
        <></>
      )}
    </>
  );
};

export default Guide;
