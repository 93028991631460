import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import {
  Box,
  Checkbox,
  FormDynamic,
  FormDynamicProps,
  Typography,
} from "@ewibecom/design-system";
import { Toast } from "components/Custom";
import { HS_FORM_ID_NEWSLETTER, PATHS } from "config";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useStore, { MarketingStore, UserStore } from "store";
import useTranslate, { useTranslateState } from "translate";
import { getAge } from "utils";

export interface NewsletterFormProps extends FormDynamicProps {
  formId: string;
  buttonLabel?: string;
  event?: string;
  labelColor?: string;
}

const NewsletterForm: React.FC<Partial<NewsletterFormProps>> = (props) => {
  const sendDataToGTM = useGTMDispatch();
  const location = useLocation();
  const t = useTranslate("shared.");
  const language = useTranslateState();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const { subscribeNewsletter, error } = useStore(
    (state) => state.marketing
  ) as MarketingStore;
  const { user } = useStore((state) => state.marketing) as UserStore;

  const [checked, setChecked] = useState(false);
  const [checkError, setCheckError] = useState(false);
  const preSignup: boolean = props.event === "pre_signup";

  const handleSubscribe = async () => {
    if (checked && email) {
      const resp = await subscribeNewsletter(
        email,
        props.formId ?? HS_FORM_ID_NEWSLETTER
      );
      if (resp) {
        Toast.notifySuccess(t("subscribe_ack"));
        const gtmTag =
          props.event === "pre_signup"
            ? {
                event: props.event,
                user_mail: email,
                website_language: language,
              }
            : {
                event: "newsletter",
                device: navigator.userAgent,
                user_mail: email,
                age: user?.birthdate && getAge(user.birthdate),
                subscribed: user ? true : false,
                gender: user?.gender,
                kyc_status: user?.onboarding.kyc,
                website_language: language,
                user_id: user?.id,
                page_type: location,
              };
        await sendDataToGTM(gtmTag);

        const params = new URLSearchParams();
        params.set("email", email);
        preSignup && navigate(PATHS.AUTH.SIGNUP + `?${params.toString()}`);
      }
    } else {
      !checked && setCheckError(true);
    }
  };

  useEffect(() => {
    Toast.notifyError(error);
  }, [error]);

  return (
    <Box direction="column" gap="xsmall" width="100%">
      <FormDynamic
        actionLabel={preSignup ? t("sign_up") : t("subscribe")}
        placeholder={t("email")}
        buttonProps={{
          primary: true,
        }}
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        onSubmit={handleSubscribe}
        {...props}
      />
      <Checkbox
        error={checkError}
        small
        label={
          <Typography
            type="caption"
            light
            style={{ alignSelf: "center", fontSize: "0.7rem" }}
            color={checkError ? "error" : props.labelColor}
          >
            {t("accept_policy")}
          </Typography>
        }
        checked={checked}
        onClick={() => {
          setChecked(!checked);
          setCheckError(false);
        }}
      />
    </Box>
  );
};

export { NewsletterForm };
