import { Box, Typography } from "@ewibecom/design-system";
import useTranslate from "translate";
import { Section } from "./Section";
import { useIsSmall } from "hooks";

const PricingHero: React.FC = () => {
  const t = useTranslate("landing.");
  const small = useIsSmall();

  return (
    <Section small={small}>
      <Box align="center">
        <Typography
          type={small ? "h5" : "h3"}
          margin={{ bottom: "medium" }}
          textAlign={small ? "start" : "center"}
          maxWidth="100%"
        >
          {t("pricing_plan")}
        </Typography>
        <Typography
          type={small ? "body1" : "h5"}
          light
          textAlign={small ? "start" : "center"}
          maxWidth={small ? "100%" : "80%"}
        >
          {t("pricing_plan_sub")}
        </Typography>
      </Box>
    </Section>
  );
};

export default PricingHero;
