import { createContext, useContext, useReducer } from "react";
import { Languages } from "./locales";
import { setLanguage, t } from "./translate";

const fallBacklanguage: Languages = "it";
const storedLang = localStorage.getItem("lang")?.replaceAll('"', "");
const initialState = {
  language: (storedLang as Languages) ?? fallBacklanguage,
};

setLanguage(initialState.language);

export enum Actions {
  CHANGE_LANGUAGE = "CHANGE_LANGUAGE",
}

interface Context {
  t?: typeof t;
  language?: Languages;
  dispatch?: React.Dispatch<{
    type: Actions;
    language: Languages;
  }>;
}

// Contexts
const TranslateContext = createContext<Context>({});

// Reducers
function translateReducer(
  state: { language: Languages },
  action: { type: string; language: Languages }
) {
  switch (action.type) {
    case "CHANGE_LANGUAGE": {
      setLanguage(action.language);
      return { ...state, language: action.language };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export const TranslateProvider = (props: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(translateReducer, initialState);
  const value = { t, language: state.language, dispatch };

  return (
    <TranslateContext.Provider value={value}>
      {props.children}
    </TranslateContext.Provider>
  );
};

const useTranslate = (prefix?: string) => {
  const { t } = useContext(TranslateContext);
  if (!t) {
    throw new Error("useTranslate must be used within a TranslateProvider");
  }

  return prefix
    ? (label: string, params?: string | string[]) => {
        return t(prefix + label, params);
      }
    : t;
};

export const useTranslateState = () => {
  const { language } = useContext(TranslateContext);
  if (!language) {
    throw new Error(
      "useTranslateState must be used within a TranslateProvider"
    );
  }
  return language;
};

export const useTranslateDispatch = () => {
  const { dispatch } = useContext(TranslateContext);
  if (!dispatch) {
    throw new Error(
      "useTranslateDispatch must be used within a TranslateProvider"
    );
  }
  return dispatch;
};

export type { Languages };

export default useTranslate;
