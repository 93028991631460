import { Anchor, Box, Footer, Icon, Typography } from "@ewibecom/design-system";
import { LogoWhite } from "assets/icons";
import { AppleBadge, AppleBadgeWebp, GoogleBadge, GoogleBadgeWebp, QrCode, QrCodeWebp } from "assets/images";
import { ImageFallback, LanguageSelector } from "components/Custom";
import { NewsletterForm } from "components/Custom/NewsletterForm";
import { PATHS } from "config";
import { useIsSmall } from "hooks";
import { useMemo } from "react";
import { useNavigate } from "react-router";
import useTranslate from "translate";

const icons = [
  <Anchor
    key="facebook"
    href={"https://www.facebook.com/ewibe.it/"}
    target="_blank"
  >
    <Icon type="Facebook" width="1.5rem" color="white" />
  </Anchor>,
  <Anchor
    key="instagram"
    href={"https://www.instagram.com/ewibe.it/"}
    target="_blank"
  >
    <Icon type="Instagram" width="1.5rem" color="white" />
  </Anchor>,
];

const RoutedFooter: React.FC = () => {
  const t = useTranslate("shared.");
  const small = useIsSmall();
  const navigate = useNavigate();
  const downloadLink = "https://onelink.to/qbkv7h";

  const menu = useMemo(
    () => [
      { label: t("route_dashboard"), href: PATHS.APP.DASHBOARD },
      { label: t("route_market"), href: PATHS.APP.MARKET },
      { label: t("route_cellar"), href: PATHS.APP.CELLAR },
      { label: t("route_transfer"), href: PATHS.APP.TRANSFERS },
      { label: t("route_info"), href: PATHS.LANDING.INFO },
      { label: t("route_contacts"), href: PATHS.LANDING.CONTACTS },
    ],
    [t]
  );

  const badges = [{ image: AppleBadge, webp: AppleBadgeWebp, alt: "AppleBadge" }, { image: GoogleBadge, webp: GoogleBadgeWebp, alt: "GoogleBadge" }];
  return (
    <Footer
      menu={menu}
      icons={icons}
      logo={<LogoWhite
        width="8rem"
        height={small ? "4rem" : "5rem"}
        onClick={() => navigate(PATHS.LANDING.HOMEPAGE)}
        style={{ cursor: "pointer" }} />}
      newsletter={
      <Box
        gap={small ? "medium" : "small"}
        align={small ? "start" : "end"}
        fill
      >
        <Typography type="caption" light color="white">
          {t("newsletter_subscribe")}
        </Typography>
        <NewsletterForm labelColor="white" />
      </Box>}
      selector={<LanguageSelector />}
      terms={{
        label: t("route_tos"),
        href: PATHS.LANDING.TOS
      }}
      privacy={{
        label: t("route_privacy"),
        href: PATHS.LANDING.PRIVACY
      }}
      app={
        <Box style={{ gap: 30 }}>
          <Typography type="body1" color="white">
          {t("download_now")}
          </Typography>
          <Box direction="row" style={{ gap: 20, alignItems: "center" }}>
            <ImageFallback
              src={QrCodeWebp}
              fallback={QrCode}
              alt={"QrCode"}
              height="129px"
              width="129px"
            />
            <Box style={{ gap: 20 }}>
              {badges.map((e) => (
                <Anchor href={downloadLink}>
                  <ImageFallback
                    src={e.webp}
                    fallback={e.image}
                    alt={e.alt}
                    height="44px"
                    width="133px"
                  />
                </Anchor>
              ))}
            </Box>
          </Box>
        </Box>
      } />
  );
};

export default RoutedFooter;
