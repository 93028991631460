import { Box } from "@ewibecom/design-system";
import { PersonalData, User } from "@ewibecom/sdk";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ControlledDatePicker,
  ControlledDropdown,
  ControlledFormTelephone,
  ControlledFormText,
} from "components/Custom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import useTranslate from "translate";
import { capitalize, getDateAddYears, getMonths } from "utils";
import * as yup from "yup";

export const defaultValuesData = {
  name: undefined,
  family_name: undefined,
  birthdate: undefined,
  gender: undefined,
  phone_number: "",
};

export const schemaData = yup.object().shape({
  name: yup.string().required(),
  family_name: yup.string().required(),
  birthdate: yup.date().nullable().min(new Date(1900, 0, 1)),
  gender: yup.string().required(),
  phone_number: yup.string().required().min(8).max(15),
});

const PersonalDataForm = ({
  setPersonalData,
  user,
  setIsDirty,
}: {
  setPersonalData: React.Dispatch<
    React.SetStateAction<PersonalData | undefined>
  >;
  user: User | undefined;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const t = useTranslate("verification.");
  const ts = useTranslate("shared.");
  const { control, watch, reset, formState } = useForm<PersonalData>({
    defaultValues: defaultValuesData,
    resolver: yupResolver(schemaData),
  });
  const name = watch("name");
  const family_name = watch("family_name");
  const birthdate = watch("birthdate");
  const gender = watch("gender");
  const phone_number = watch("phone_number");
  const [dialCode, setDialCode] = useState("");

  const personalData: PersonalData = useMemo(() => {
    return {
      name,
      family_name,
      birthdate,
      gender,
      phone_number,
    };
  }, [name, family_name, birthdate, gender, phone_number]);

  useEffect(() => {
    if (
      user?.name &&
      user?.family_name &&
      user?.birthdate &&
      user?.gender &&
      user?.phone_number
    ) {
      reset({
        name: user.name,
        family_name: user.family_name,
        birthdate: user.birthdate,
        gender: user.gender as "M" | "F",
        phone_number: user.phone_number,
      });
    } else {
      reset({
        name: user?.name,
        family_name: user?.family_name,
      });
    }
  }, [user, reset]);

  useEffect(() => {
    setPersonalData({
      ...personalData,
      phone_number: `+${dialCode}${personalData.phone_number}`,
    });
  }, [personalData, dialCode, setPersonalData]);

  const valuesUpdated = useCallback(() => {
    if (!user || !user.birthdate) return true;
    return ["name", "family_name", "birthdate", "gender", "phone_number"].some(
      (field) => {
        return field === "phone_number"
          ? `+${dialCode}${personalData[field as keyof PersonalData]}` !==
              user[field as keyof User]
          : personalData[field as keyof PersonalData] !==
              user[field as keyof User];
      }
    );
  }, [user, personalData, dialCode]);

  useEffect(() => {
    if (setIsDirty && valuesUpdated()) setIsDirty(formState.isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.isDirty, personalData]);

  return (
    <>
      <ControlledFormText
        name="name"
        placeholder={t("name")}
        width="100%"
        control={control}
        error={formState.errors.name?.message || ""}
      />
      <ControlledFormText
        name="family_name"
        placeholder={t("family_name")}
        width="100%"
        control={control}
        error={formState.errors.family_name?.message || ""}
      />
      <Box width="100%">
        <ControlledDatePicker
          name="birthdate"
          placeholder={t("birthdate")}
          control={control}
          formatWeekDay={(dayOfWeekLabel: string) =>
            ts(dayOfWeekLabel.substring(0, 3).toLowerCase())
              .substring(0, 1)
              .toUpperCase()
          }
          months={getMonths().map((m) => capitalize(ts(m)))}
          includeDateIntervals={[
            {
              start: 0,
              end: getDateAddYears(-18),
            },
          ]}
          width="100%"
        />
      </Box>
      <ControlledDropdown
        name="gender"
        options={["M", "F", "ND"]}
        placeholder={t("gender")}
        width="100%"
        control={control}
        margin={{ top: "1rem" }}
      />
      <Box width="100%" margin={{ top: "1rem" }}>
        <ControlledFormTelephone
          fieldName="phone_number"
          placeholder={t("phone")}
          control={control}
          setDialCode={setDialCode}
          error={formState.errors.phone_number?.message || ""}
        />
      </Box>
    </>
  );
};

export { PersonalDataForm };
