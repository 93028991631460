import { Loadable } from "components/Custom";
import Layout from "components/Layout";
import { PATHS } from "config";
import { Navigate, Route, Routes } from "react-router-dom";

const AboutUs = Loadable({
  loader: () => import("./pages/Landing/AboutUs"),
});
const Contacts = Loadable({
  loader: () => import("./pages/Landing/Contacts"),
});
const HomePage = Loadable({
  loader: () => import("./pages/Landing/HomePage"),
});
const HowItWorks = Loadable({
  loader: () => import("./pages/Landing/HowItWorks"),
});
const MarketLanding = Loadable({
  loader: () => import("./pages/Landing/Market"),
});
const Pricing = Loadable({
  loader: () => import("./pages/Landing/Pricing"),
});
const WhyUs = Loadable({
  loader: () => import("./pages/Landing/WhyUs"),
});
const WineInvestment = Loadable({
  loader: () => import("./pages/Landing/WineInvestment"),
});
const Bottle = Loadable({
  loader: () => import("./pages/Bottle"),
});
const Cellar = Loadable({
  loader: () => import("./pages/Cellar"),
});
const Dashboard = Loadable({
  loader: () => import("./pages/Dashboard"),
});
const Login = Loadable({
  loader: () => import("./pages/Login"),
});
const Market = Loadable({
  loader: () => import("./pages/Market"),
});
const NotFound = Loadable({
  loader: () => import("./pages/NotFound"),
});
const PasswordReset = Loadable({
  loader: () => import("./pages/PasswordReset"),
});
const PasswordResetSubmit = Loadable({
  loader: () => import("./pages/PasswordResetSubmit"),
});
const PrivacyPolicy = Loadable({
  loader: () => import("./pages/Privacy"),
});
const Profile = Loadable({
  loader: () => import("./pages/Profile"),
});
const Signup = Loadable({
  loader: () => import("./pages/Signup"),
});
const Terms = Loadable({
  loader: () => import("./pages/Terms"),
});
const ToS = Loadable({
  loader: () => import("./pages/ToS"),
});

const RequireAuth = Loadable({
  loader: () => import("components/Layout/RequireAuth"),
});

const Router = () => (
  <Routes>
    {/* public landing */}
    <Route path="/" element={<Layout landing />}>
      <Route index path={PATHS.LANDING.HOMEPAGE} element={<HomePage />} />
      <Route path={PATHS.LANDING.MARKET} element={<MarketLanding />} />
      <Route path={PATHS.LANDING.PRICING} element={<Pricing />} />
      <Route path={PATHS.LANDING.INFO} element={<AboutUs />} />
      <Route path={PATHS.LANDING.CONTACTS} element={<Contacts />} />
      <Route path={PATHS.LANDING.WHYUS} element={<WhyUs />} />
      <Route path={PATHS.LANDING.HOW} element={<HowItWorks />} />
      <Route path={PATHS.LANDING.INVESTMENT} element={<WineInvestment />} />
      <Route path={PATHS.LANDING.PRIVACY} element={<PrivacyPolicy />} />
      <Route path={PATHS.LANDING.TOS} element={<ToS />} />
      <Route path="*" element={<NotFound />} />
    </Route>

    {/* authentication flows */}
    <Route path={PATHS.AUTH.LOGIN} element={<Login />} />
    <Route path={PATHS.AUTH.SIGNUP} element={<Signup />} />
    <Route path={PATHS.AUTH.REFERRAL} element={<Signup />} />
    <Route path={PATHS.AUTH.TERMS} element={<Terms />} />
    <Route path={PATHS.AUTH.FORGOT} element={<PasswordReset />} />
    <Route path={PATHS.AUTH.FORGOT_SUBMIT} element={<PasswordResetSubmit />} />

    {/* authenticated routes */}
    <Route path={PATHS.APP.BASE} element={<RequireAuth />}>
      <Route index element={<Navigate to={PATHS.APP.DASHBOARD} />} />
      <Route path={PATHS.APP.DASHBOARD} element={<Dashboard />} />
      <Route path={PATHS.APP.PROFILE} element={<Profile />} />
      <Route path={PATHS.APP.CELLAR} element={<Cellar />} />
      <Route path={PATHS.APP.MARKET} element={<Market />} />
      <Route path={PATHS.APP.MARKET + "/:id"} element={<Bottle />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  </Routes>
);

export default Router;
