import SDK from '@ewibecom/sdk';

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const VAT_ENABLED = process.env.REACT_APP_VAT_ENABLED === "true" || process.env.REACT_APP_VAT_ENABLED === "1";
const ENV = process.env.REACT_APP_ENV || "development";
const BASE_URL = process.env.REACT_APP_BASE_URL || "https://api-dev.ewibe.com/api/v1";
const AWS_REGION = process.env.REACT_APP_AWS_REGION || "eu-central-1";
const AWS_USER_POOL = process.env.REACT_APP_AWS_USER_POOL || "eu-central-1_HsCtsSYJf";
const AWS_USER_POOL_WEB_CLIENT = process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT || "11fpciek4p7ilqbhebtah8u5m4";
const AWS_MANDATORY_SIGNIN = process.env.REACT_APP_AWS_MANDATORY_SIGNIN || "true";
const OAUTH_DOMAIN = process.env.REACT_APP_OAUTH_DOMAIN || "ewibe-test.auth.eu-central-1.amazoncognito.com";
const OAUTH_REDIRECT_SIGNIN = process.env.REACT_APP_OAUTH_REDIRECT_SIGNIN || "http://localhost:3000";
const OAUTH_REDIRECT_SIGNOUT = process.env.REACT_APP_OAUTH_REDIRECT_SIGNOUT || "http://localhost:3000";
const OAUTH_CLIENT_ID = process.env.REACT_APP_OAUTH_CLIENT_ID || "11fpciek4p7ilqbhebtah8u5m4";
const OAUTH_RESPONSE_TYPE = process.env.REACT_APP_OAUTH_RESPONSE_TYPE || "token";
const OAUTH_SCOPE = process.env.REACT_APP_OAUTH_SCOPE || "[]";
const DISCORD_WEBHOOK_SUPPORT = process.env.REACT_APP_DISCORD_WEBHOOK_SUPPORT || "";
const GCP_MAPS_API_KEY = process.env.REACT_APP_GCP_MAPS_API_KEY || "";
const GTM_ID = process.env.REACT_APP_GTM_ID || "GTM-T3TWSS9";
const GTM_AUTH = process.env.REACT_APP_GTM_AUTH;
const GTM_PREVIEW = process.env.REACT_APP_GTM_PREVIEW;
const HS_FORM_ID_HOMEPAGE = process.env.REACT_APP_HS_FORM_ID_HOMEPAGE
const HS_FORM_ID_NEWSLETTER = process.env.REACT_APP_HS_FORM_ID_NEWSLETTER;
const HS_FORM_ID_GUIDE = process.env.REACT_APP_HS_FORM_ID_GUIDE;
const HS_FORM_ID_SIGNUP = process.env.REACT_APP_HS_FORM_ID_SIGNUP;
const HS_FORM_ID_CONTACTUS = process.env.REACT_APP_HS_FORM_ID_CONTACTUS;
const HS_PORTAL_ID = process.env.REACT_APP_HS_PORTAL_ID || "25592758";
const AUTO_PROMOCODE = process.env.REACT_APP_AUTO_PROMOCODE?.toLocaleLowerCase() || false;
const REACT_APP_RELEASE_ID = process.env.REACT_APP_RELEASE_ID  || "none";
const TUTORIAL_ENABLED = process.env.REACT_APP_TUTORIAL_ENABLED === "true";
const FAB_ENABLED = process.env.REACT_APP_FAB_ENABLED === "true";
const FREE_DEADLINE_FEE = process.env.REACT_APP_FREE_DEADLINE_FEE || "31/12/2023"

const EwibeSDK = new SDK({
  api: {
    baseUrl: BASE_URL,
  },
  auth: {
    awsAmplify: {
      region: AWS_REGION,
      userPoolId: AWS_USER_POOL,
      userPoolWebClientId: AWS_USER_POOL_WEB_CLIENT,
      mandatorySignIn: AWS_MANDATORY_SIGNIN === "true",
    },
    awsOauth: {
      domain: OAUTH_DOMAIN,
      redirectSignIn: OAUTH_REDIRECT_SIGNIN,
      redirectSignOut: OAUTH_REDIRECT_SIGNOUT,
      clientId: OAUTH_CLIENT_ID,
      responseType: OAUTH_RESPONSE_TYPE,
      scope: JSON.parse(OAUTH_SCOPE),
    },
  },
});

export {
  DISCORD_WEBHOOK_SUPPORT, ENV,
  EwibeSDK, GCP_MAPS_API_KEY, SENTRY_DSN,
  VAT_ENABLED, GTM_ID, GTM_AUTH, GTM_PREVIEW,
  HS_FORM_ID_HOMEPAGE, HS_FORM_ID_NEWSLETTER, HS_FORM_ID_GUIDE, HS_PORTAL_ID,
  HS_FORM_ID_SIGNUP, HS_FORM_ID_CONTACTUS,
  REACT_APP_RELEASE_ID,
  AUTO_PROMOCODE, FREE_DEADLINE_FEE,
  TUTORIAL_ENABLED,
  FAB_ENABLED
};
