import { ToastContainer, toast } from "react-toastify";

const defaultSettings = {
  position: "bottom-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
}

const notifyError = (error: string, options?: any) => {
  return toast.error(error, {
    ...defaultSettings,
    ...options
  });
}

const notifySuccess = (message: string, options?: any) => {
  return toast.success(message, {
    ...defaultSettings,
    ...options
  });
}

export { ToastContainer, notifyError, notifySuccess };
