import { Box, Icon, Typography, theme } from "@ewibecom/design-system";
import { useIsSmall } from "hooks";
import useTranslate from "translate";
import { Section } from "../Section";

const ValuesBanner: React.FC = () => {
  const t = useTranslate("landing.");
  const small = useIsSmall();

  const list = [
    {
      icon: "Recipe",
      title: " 98%",
      subtitle: "customers_satisfied",
      background: `${(theme as any).global.colors.secondary.light}`,
    },
    {
      icon: "Cellar",
      title: "€ 5,0 mln",
      subtitle: "platform_value",
      background: `${(theme as any).global.colors.tertiary.light}`,
    },
    {
      icon: "Profile_2",
      title: "11.000",
      subtitle: "active_users",
      background: `${(theme as any).global.colors.secondary.light}`,
    },
  ];

  return (
    <Section small={small}>
      <Typography
        type={small ? "h5" : "h3"}
        maxWidth="100%"
        margin={{ top: "0" }}
      >
        {t(small ? "values_title_mobile" : "values_title")}
      </Typography>
      <Box direction={small ? "column" : "row"} justify="between">
        {list.map((e) => (
          <Box
            key={e.title}
            align="center"
            background={e.background}
            pad="3rem"
            width={small ? "100%" : "32%"}
            height={small ? "18rem" : "26rem"}
            style={{ borderRadius: "2rem" }}
            margin={{ bottom: small ? "2rem" : "" }}
          >
            {/* @ts-ignore */}
            <Icon type={e.icon} width="3rem" />
            <Typography
              type={small ? "h4" : "h2"}
              margin={{
                top: small ? "1.5rem" : "3rem",
                bottom: small ? "0" : "2rem",
              }}
              textAlign="center"
            >
              {e.title}
            </Typography>
            <Typography
              type={small ? "h5" : "h4"}
              margin={{ top: "1rem", bottom: small ? "0" : "1rem" }}
              textAlign="center"
            >
              {t(e.subtitle)}
            </Typography>
          </Box>
        ))}
      </Box>
    </Section>
  );
};

export default ValuesBanner;
