import { Box, SelectRadioButton, Typography } from "@ewibecom/design-system";
import { Mastercard, Visa } from "assets/icons";
import { CopyText } from "components/Custom";
import { useIsSmall } from "hooks";
import React from "react";
import useStore, { UserStore } from "store";
import useTranslate from "translate";

export interface PaymentMethodFormProps {
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  checked2: boolean;
  setChecked2: React.Dispatch<React.SetStateAction<boolean>>;
}

const PaymentMethodForm: React.FC<PaymentMethodFormProps> = (props) => {
  const t = useTranslate("verification.");
  const small = useIsSmall();
  const { user } = useStore((state) => state.user) as UserStore;

  const icons = [
    <Mastercard style={{ height: "1.5rem", marginLeft: "1rem" }} />,
    <Visa
      style={{
        height: small ? "1.3rem" : "1.2rem",
        marginLeft: "1rem",
        marginRight: small ? "0.5rem" : "0",
      }}
    />,
  ];

  return (
    <Box margin={{ bottom: small ? "1rem" : "0rem" }} width="100%">
      <Box margin={{ bottom: "1rem" }}>
        <SelectRadioButton
          name="CreditCard"
          label={t("credit_card")}
          icons={icons}
          checked={props.checked}
          onClick={() => {
            props.setChecked(true);
            props.setChecked2(false);
          }}
        />
      </Box>
      <SelectRadioButton
        name="Transfer"
        label={t("transfer")}
        checked={props.checked2}
        onClick={() => {
          props.setChecked2(true);
          props.setChecked(false);
        }}
        content={
          props.checked2 && (
            <>
              {[
                ["from", "your_bank_account"],
                ["to", "mango_pay"],
              ].map((e) => (
                <Box direction="row">
                  <Typography type={small ? "caption" : "body1"} light>
                    {t(e[0])}
                  </Typography>
                  <Typography
                    type={small ? "caption" : "subtitle1"}
                    margin={{ left: "0.5rem" }}
                  >
                    {t(e[1])}
                  </Typography>
                </Box>
              ))}
              <Typography
                margin={{ bottom: small ? "1rem" : "2rem", top: "0.5rem" }}
                type={small ? "caption" : "body1"}
                light
              >
                {t("description_transfer")}
              </Typography>
              <Box
                direction={small ? "column" : "row"}
                align={small ? "baseline" : "center"}
              >
                <Typography
                  margin={{ right: "0.5rem" }}
                  type={small ? "caption" : "body1"}
                  light
                >
                  {t("iban").toUpperCase()}
                </Typography>

                {user?.wallet?.ewallet?.aliasIban && (
                  <CopyText content={user?.wallet?.ewallet?.aliasIban} />
                )}
              </Box>
              <Box
                direction={small ? "column" : "row"}
                align={small ? "baseline" : "center"}
              >
                <Typography
                  margin={{ right: "0.5rem" }}
                  type={small ? "caption" : "body1"}
                  light
                >
                  {t("cause")}
                </Typography>
                <CopyText
                  content={
                    user?.wallet?.ewallet?.accountId
                      ? user.wallet.ewallet.accountId
                      : "ERROR"
                  }
                />
              </Box>
            </>
          )
        }
      />
    </Box>
  );
};

export { PaymentMethodForm };
