const tutorial = {
  dashboard: {
    welcome: "welcome",
    performance: "performance",
    index: "index",
    proposal: "proposal",
  },
  bottle: {
    welcome: "welcome",
    trade: "trade",
    price: "price",
    chart: "chart",
    cycle: "cycle",
  },
  cellar: {
    trend: "trend",
    bottles: "bottles",
    breakdown: "breakdown",
    movements: "movements",
    orders: "orders",
    transactions: "transactions",
    funds: "funds",
  },
  market: {
    market: "market",
    filter: "filter",
    smallFilter: "smallFilter",
    bottles: "bottles",
  },
  orderbook: {
    formats: "formats",
    bookcard: "bookcard",
    switch: "switch",
    offer: "offer",
  },
};

export { tutorial };
