import {
  ChangePasswordParameters,
  CognitoIdentityProvider,
  ForgotPasswordParameters,
  ForgotPasswordSubmitParameters,
  SessionData,
  SignInParameters,
  SignInResponse,
  SignUpParameters
} from '@ewibecom/sdk';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { EwibeSDK as sdk } from 'config';
import { AsyncState } from './utils';

interface AsyncStore {
  setToken: (token: string) => Promise<void>;
  setError: (err: string) => void;
  setLoading: (isLoading?: boolean) => void;
  login: (req: SignInParameters) => Promise<SignInResponse | false>;
  ssoLogin: (provider: CognitoIdentityProvider) => Promise<void>;
  saveFromLocation: (from: string) => void;
  register: (req: SignUpParameters) => Promise<boolean>;
  changePassword: (req: ChangePasswordParameters) => Promise<boolean>;
  forgotPassword: (req: ForgotPasswordParameters) => Promise<boolean>;
  forgotPasswordSubmit: (req: ForgotPasswordSubmitParameters) => Promise<boolean>;
  getCurrentUser: () => Promise<SignInResponse | false>;
  logout: () => Promise<void>;
  getSession: () => Promise<SessionData>
}

export interface AuthStore extends AsyncState, AsyncStore {
  token?: string | null;
  from?: string;
}

export const useAuthStore = create<AuthStore>()(
  devtools(
    persist(
      ((set) => ({
        token: null,
        data: null,
        error: null,
        isLoading: false,
        setToken: async (token: string): Promise<void> => {
          set({ token });
          await sdk.auth.setToken(token);
        },
        setError: (err: string): void => {
          set({ error: { message: err } });
        },
        setLoading: (isLoading: boolean = true): void => {
          set({ isLoading });
        },
        login: async (req: SignInParameters): Promise<SignInResponse | false> => {
          try {
            set({ isLoading: true });
            const data = await sdk.auth.signIn(req);
            const token = data.signInUserSession.accessToken ?? "";
            await sdk.auth.setToken(token);
            set({ token, error: null, isLoading: false });
            return data;
          } catch (error) {
            set({ token: "", error, isLoading: false });
            return false;
          }
        },
        ssoLogin: async (provider: CognitoIdentityProvider): Promise<void> => {
          set({ isLoading: true });
          // nothing to handle because signInSocial will navigate to a different
          // window and the context is lost. returned access token is managed
          // when coming back from the page
          await sdk.auth.signInSocial(provider);
        },
        saveFromLocation: (from: string): void => {
          set({ from });
        },
        getCurrentUser: async (): Promise<false | SignInResponse> => {
          try {
            set({ isLoading: true });
            const user = await sdk.auth.getCurrentUser();
            if (user) {
              const token = user?.signInUserSession?.accessToken ?? "";
              await sdk.auth.setToken(token);
              set({ token, error: null, isLoading: false });
            }
            return user;
          } catch (error) {
            set({ token: "", error, isLoading: false });
            return false;
          }
        },
        register: async (req: SignUpParameters): Promise<boolean> => {
          try {
            set({ isLoading: true });
            const data = await sdk.auth.signUp(req);
            set({ data, error: null, isLoading: false });
            return true;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        changePassword: async (req: ChangePasswordParameters): Promise<boolean> => {
          try {
            set({ isLoading: true });
            const res = await sdk.auth.changePassword(req);
            set({ error: null, isLoading: false });
            return res;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        forgotPassword: async (req: ForgotPasswordParameters): Promise<boolean> => {
          try {
            set({ isLoading: true });
            const res = await sdk.auth.forgotPassword(req);
            set({ error: null, isLoading: false });
            return res;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        forgotPasswordSubmit: async (req: ForgotPasswordSubmitParameters): Promise<boolean> => {
          try {
            set({ isLoading: true });
            const res = await sdk.auth.forgotPasswordSubmit(req);
            set({ error: null, isLoading: false });
            return res;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        logout: async () => {
          try {
            set({ error: null, token: "", from: "" });
            await sdk.auth.signOut()
            await sdk.auth.setToken("");
          } catch (error) {
            set({ error });
          }
        },
        getSession: async () => {
          return await sdk.auth.getSession();
        }
      })), {
      name: "ewibe-auth-store",
      partialize: (state) => ({ token: state.token, from: state.from }),
      onRehydrateStorage: () => async (state) => {
        if (state && state.token) {
          await sdk.auth.setToken(state.token);
        }
      }
    })
  )
)