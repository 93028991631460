import { Guide, IndexBar } from "components/Landing";
import { useHydrateFetch } from "hooks";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import useStore, { AuthStore } from "store";
import Footer from "./Footer";
import Header from "./Header";
import LandingHeader from "./LandingHeader";
import { DownloadApp } from "components/Landing/HomePage";

export interface LayoutProps {
  landing?: boolean;
}

const Layout: React.FC<LayoutProps> = (props) => {
  const hydrate = useHydrateFetch();
  const navigate = useNavigate();
  const { from, saveFromLocation } = useStore(
    (state) => state.auth
  ) as AuthStore;

  useEffect(() => {
    if (!props.landing) {
      hydrate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.landing]);

  useEffect(() => {
    if (!props.landing && from) {
      saveFromLocation("");
      navigate(from);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.landing]);

  return (
    <>
      {props.landing && <IndexBar />}
      {props.landing ? <LandingHeader /> : <Header />}
      {props.landing && <DownloadApp />}
      {props.landing && <Guide />}
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
