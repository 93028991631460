import Router from "Router";
import { PATHS, SEO } from "config";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import useTranslate from "translate";
import { GTM_ID, GTM_AUTH, GTM_PREVIEW } from "config";
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import { ISnippetsParams } from '@elgorditosalsero/react-gtm-hook/dist/models/GoogleTagManager'

const App = () => {
  const gtmParams: ISnippetsParams = { id: GTM_ID }
  if (GTM_AUTH && GTM_PREVIEW) {
    gtmParams.environment = {
      gtm_auth: GTM_AUTH,
      gtm_preview: GTM_PREVIEW
    };
  }
  const t = useTranslate("shared.");
  const location = useLocation();

  const [title, setTitle] = useState("eWibe");
  const [description, setDescription] = useState(
    "Investimenti in vino pregiato alla portata di tutti"
  );

  useEffect(() => {
    let platform = false;
    if (location.pathname.includes(PATHS.APP.BASE)) {
      platform = true;
    }
    const [key] = Object.entries(platform ? PATHS.APP : PATHS.LANDING).find(
      ([_, value]) => {
        return (
          value !== PATHS.APP.BASE &&
          location.pathname.includes(value as string)
        );
      }
    ) ?? [""];

    if (key && SEO[key]) {
      setTitle(`eWibe${platform ? " platform" : ""} | ${t(SEO[key].title)}`);
      setDescription(t(SEO[key].description));
    }
  }, [location, t]);

  return (
    <GTMProvider state={gtmParams}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Router />
    </GTMProvider>
  );
};

export default App;
