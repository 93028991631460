import { Box, Carousel, Typography, theme } from "@ewibecom/design-system";
import { Quotes } from "assets/icons";
import { useIsSmall } from "hooks";
import useTranslate from "translate";
import { Section } from "../Section";

const list = [
  { title: "Warren Buffet", description: "opinion_WBuffet" },
  { title: "Bloomberg", description: "opinion_Bloomberg" },
  { title: "Telegraph", description: "opinion_Telegraph" },
];

export interface QuoteBoxProps {
  title: string;
  description: string;
  small?: boolean;
}

const QuoteBox: React.FC<QuoteBoxProps> = (props) => {
  const t = useTranslate("landing.");

  return (
    <Box width={props.small ? "90%" : "30%"} justify="between">
      <Box>
        <Quotes width={props.small ? "2rem" : "3rem"} />
        <Typography
          type={props.small ? "h6" : "h5"}
          light={props.small}
          color={(theme as any).global.colors.white}
          margin={{
            top: props.small ? "1rem" : "small",
            bottom: props.small ? "1rem" : "small",
          }}
        >
          {props.title}
        </Typography>
        <Typography
          type={props.small ? "subtitle1" : "body1"}
          light
          color={(theme as any).global.colors.white}
          margin={{ vertical: props.small ? "1rem" : "medium" }}
        >
          {t(props.description)}
        </Typography>
      </Box>
      <Box
        height={props.small ? "1px" : "3px"}
        background={(theme as any).global.colors.white}
        width="100%"
      />
    </Box>
  );
};

const QuotesBanner: React.FC = () => {
  const t = useTranslate("landing.");
  const small = useIsSmall();

  return (
    <Section
      small={small}
      background={(theme as any).global.colors.secondary.default}
      align="center"
    >
      <Typography
        type={small ? "h5" : "h4"}
        color={(theme as any).global.colors.white}
        maxWidth="100%"
        textAlign="center"
        margin={{ bottom: small ? "1rem" : "4rem", top: "0" }}
      >
        {t("quotes")}
      </Typography>
      <Box direction="row" justify="between" width="100%" gap="small">
        {small ? (
          <Carousel arrows dots={false} show={1}>
            {list.map((e) => (
              <QuoteBox
                small={true}
                title={e.title}
                description={e.description}
                key={e.title}
              />
            ))}
          </Carousel>
        ) : (
          list.map((e) => (
            <QuoteBox
              title={e.title}
              description={e.description}
              key={e.title}
            />
          ))
        )}
      </Box>
    </Section>
  );
};

export default QuotesBanner;
