import ReactJoyride, { Props, TooltipRenderProps } from "react-joyride";
import CustomTooltip from "./CustomTooltip";

export interface CustomJoyRideProps extends Props {
  checked: boolean;
  startModal?: boolean;
  finalModal?: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  handleClose: () => Promise<void>;
  paginatorAction?: ({ page }: { page: number }) => void;
}

const CustomJoyRide: React.FC<CustomJoyRideProps> = (props) => {
  return (
    <ReactJoyride
      steps={props.steps}
      continuous
      stepIndex={props.stepIndex}
      disableCloseOnEsc
      disableOverlayClose
      tooltipComponent={
        props.tooltipComponent
          ? props.tooltipComponent
          : (tooltipProps: TooltipRenderProps) => {
              return (
                <CustomTooltip
                  {...tooltipProps}
                  primaryProps={{
                    ...tooltipProps.primaryProps,
                    onClick: (e: React.MouseEvent<HTMLElement>) => {
                      e.preventDefault();
                      if (props.stepIndex === props.steps.length - 1) {
                        props.handleClose();
                      } else {
                        props.setIndex((props.stepIndex as number) + 1);
                      }
                    },
                  }}
                  backProps={{
                    ...tooltipProps.backProps,
                    onClick: (e: React.MouseEvent<HTMLElement>) => {
                      e.preventDefault();
                      props.setIndex((props.stepIndex as number) - 1);
                    },
                  }}
                  closeProps={{
                    ...tooltipProps.closeProps,
                    onClick: (e: React.MouseEvent<HTMLElement>) => {
                      e.preventDefault();
                      props.handleClose();
                    },
                  }}
                  index={props.stepIndex as number}
                  setIndex={props.setIndex}
                  setChecked={props.setChecked}
                  checked={props.checked}
                  paginatorAction={props.paginatorAction}
                />
              );
            }
      }
      floaterProps={{
        hideArrow:
          (props.startModal && props.stepIndex === 0) ||
          (props.finalModal && props.stepIndex === props.steps.length - 1)
            ? true
            : false,
        styles: {
          arrow: {
            spread: 18,
          },
        },
      }}
      styles={{
        spotlight: {
          borderRadius: "1rem",
          display:
            (props.startModal && props.stepIndex === 0) ||
            (props.finalModal && props.stepIndex === props.steps.length - 1)
              ? "none"
              : "",
        },
      }}
    />
  );
};

export default CustomJoyRide;
