import { Box, ResponsiveContext, Typography } from "@ewibecom/design-system";
import { PATHS } from "config";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "styled-components";
import useTranslate from "translate";

const ModalSupportFooter = () => {
  const size = useContext(ResponsiveContext);
  const theme = useTheme();
  const t = useTranslate("verification.");

  return (
    <Box
      direction="row"
      justify="center"
      align="center"
      margin={{ top: size === "small" ? "1rem" : "0rem" }}
    >
      <Typography type="caption" light margin={{ right: "0.5rem" }}>
        {t("help")}
      </Typography>
      <Link
        to={PATHS.LANDING.CONTACTS}
        color={(theme as any).global.colors.text}
        style={{ fontSize: "0.8rem" }}
      >
        {t("contact_support")}
      </Link>
    </Box>
  );
};

export { ModalSupportFooter };
