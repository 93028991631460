export const PATHS = {
  APP: {
    BASE: "/app/",
    DASHBOARD: "https://app.ewibe.com/dashboard",
    MARKET: "mercato",
    LISTING: "listing",
    CELLAR: "lamiacantina",
    MOVEMENTS: "lamiacantina?tab=1",
    PROFILE: "https://app.ewibe.com/profile/personalData",
    PAYMENTS: "https://app.ewibe.com/profile/eWallet",
    TRANSFERS: "https://app.ewibe.com/profile/eWallet",
  },
  LANDING: {
    HOMEPAGE: "/",
    MARKET: "/mercato-vini-pregiati",
    PRICING: "/abbonamenti-prezzi",
    INFO: "/chi-siamo",
    CONTACTS: "/contatti",
    FAQ: "https://risorse.ewibe.com/knowledge",
    WHYUS: "/perché-investire-su-ewibe",
    HOW: "/come-funziona-investire",
    INVESTMENT: "/investimenti-in-vino",
    PRIVACY: "/privacy",
    TOS: "/tos",
  },
  AUTH: {
    LOGIN: "https://app.ewibe.com",
    SIGNUP: "https://app.ewibe.com/signup",
    REFERRAL: "https://app.ewibe.com/referral/:code",
    TERMS: "/terms",
    FORGOT: "https://app.ewibe.com/forgot-password",
    FORGOT_SUBMIT: "https://app.ewibe.com/forgot-password",
  },
  NOTFOUND: "/notfound",
};
