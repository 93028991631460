import { Dropdown, DropdownProps } from "@ewibecom/design-system";
import { Controller, Control } from "react-hook-form";

export interface ControlledDropdownProps extends DropdownProps {
  control: Control<any, any>;
}

const ControlledDropdown: React.FC<ControlledDropdownProps> = (props) => {
  return (
    <Controller
      name={props.name || (props.placeholder as string)}
      control={props.control}
      render={({ field }) => (
        <Dropdown
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          placeholder={props.placeholder}
          {...props}
        />
      )}
    />
  );
};

export default ControlledDropdown;
