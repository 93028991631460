import { Box, BoxExtendedProps } from "@ewibecom/design-system";

interface SectionProps extends BoxExtendedProps {
  small?: boolean;
  children?: React.ReactNode;
  vertical?: string;
  width?: string;
}

const Section: React.FC<SectionProps> = (props) => {
  return (
    <Box
      width={props.width || "100%"}
      pad={
        props.small
          ? "large"
          : { vertical: props.vertical || "large", horizontal: "xlarge" }
      }
      {...props}
    >
      {props.children}
    </Box>
  );
};

export { Section };
