import {
  Anchor,
  Box,
  Button,
  Modal,
  Typography,
} from "@ewibecom/design-system";
import { AddFundsModal } from "components/AddFundsModal";
import { PATHS } from "config";
import { useIsSmall } from "hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import useTranslate from "translate";

export interface AlertModalProps {
  noAvailableFunds?: boolean;
  funds?: number;
  show?: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  wallet?: boolean;
}

const AlertModal: React.FC<AlertModalProps> = (props) => {
  const small = useIsSmall();
  const theme = useTheme();
  const navigate = useNavigate();
  const t = useTranslate("verification.");
  const [showFunds, setShowFunds] = useState(false);

  return (
    <>
      <AddFundsModal show={showFunds} setShow={setShowFunds} />
      <Modal
        title={t(props.noAvailableFunds ? "insufficient_funds" : "warning")}
        body={
          props.noAvailableFunds ? (
            <Box align="center" margin={{ bottom: "2rem" }}>
              <Typography
                type={small ? "h5" : "h2"}
                color={(theme as any).global.colors.error}
                light={!small}
                margin={{ vertical: "1rem" }}
              >
                € {props.funds}
              </Typography>
              <Typography
                textAlign="center"
                light
                margin={{ vertical: "1rem" }}
                type={small ? "body1" : "h5"}
              >
                {t("complete_order")}
              </Typography>
            </Box>
          ) : (
            <Box align="center" margin={{ top: "2rem", bottom: "4rem" }}>
              <Typography>
                {t(props.wallet ? "create_wallet" : "place_order")}
              </Typography>
            </Box>
          )
        }
        leftAction={
          <Button
            label={t(
              props.noAvailableFunds ? "add_funds_lowercase" : "verify_button"
            )}
            secondary={props.noAvailableFunds}
            width="12.3rem"
            height="3.5rem"
            onClick={() => {
              if (props.noAvailableFunds) {
                props.setShow(false);
                setShowFunds(true);
              } else {
                props.setShow(false);
                navigate(PATHS.APP.BASE + PATHS.APP.PROFILE);
              }
            }}
          />
        }
        footer={
          <Box
            direction="row"
            justify="center"
            margin={{ top: small ? "1rem" : "0rem" }}
          >
            <Typography type="caption" light margin={{ right: "0.5rem" }}>
              {t("help")}
            </Typography>
            <Anchor
              color={(theme as any).global.colors.text}
              size="0.8rem"
              onClick={() => navigate(PATHS.LANDING.CONTACTS)}
            >
              {t("contact_support")}
            </Anchor>
          </Box>
        }
        show={props.show}
        setShow={props.setShow}
      />
    </>
  );
};

export default AlertModal;
