import {
  Box,
  Button,
  Icon,
  Modal,
  Spinner,
  Typography,
} from "@ewibecom/design-system";
import { Address, PersonalData, UploadKYCRequest } from "@ewibecom/sdk";
import { ModalSupportFooter, Toast } from "components/Custom";
import { countries } from "config";
import { useIsSmall } from "hooks";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useStore, { UserStore } from "store";
import styled, { css, useTheme } from "styled-components";
import useTranslate from "translate";
import { formattedPriceToNumber } from "utils";
import { FundsForm, MangoPayFrame, PaymentMethodForm } from ".";
import {
  AddressForm,
  defaultValuesAddress,
  schemaAddress,
} from "./AddressForm";
import { DocumentsForm, schemaDocuments } from "./DocumentsForm";
import {
  PersonalDataForm,
  defaultValuesData,
  schemaData,
} from "./PersonalDataForm";
import { State } from "./types";

export interface VerifyModalProps {
  show?: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  state: State;
}

const StyledBox = styled(Box)`
  ${({ theme }) => css`
    border: 1px solid ${theme.global.colors.gray4};
    border-radius: 1rem;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 1rem;
  `}
`;

const SuccessContent = () => {
  const small = useIsSmall();
  const theme = useTheme();

  return (
    <Box align="center" margin={{ bottom: "2rem", top: small ? "1rem" : "0" }}>
      <Icon
        type="Check"
        color={(theme as any).global.colors.green}
        width={small ? "5rem" : "6rem"}
      />
    </Box>
  );
};

const VerifyModal: React.FC<VerifyModalProps> = ({ state, show, setShow }) => {
  const theme = useTheme();
  const t = useTranslate("verification.");
  const small = useIsSmall();
  const [step, setStep] = useState(0);
  const [validSizeRecto, setValidSizeRecto] = useState(true);
  const [validSizeVerso, setValidSizeVerso] = useState(true);
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [formPriceValue, setFormPriceValue] = useState("");
  const [errorFormPrice, setErrorFormPrice] = useState(false);
  const [closeContent, setCloseContent] = useState(false);
  const [personalDataState, setPersonalDataState] = useState<PersonalData>();
  const [personalDataDirty, setPersonalDataDirty] = useState(false);
  const [addressState, setAddressState] = useState<Address>();
  const [addressDirty, setAddressDirty] = useState(false);
  const [uploadKYC, setUploadKYCState] = useState<UploadKYCRequest>();
  const [open, setOpen] = useState(false);
  const { user, error, setPersonalData, setAddress, isLoading, setUploadKYC } =
    useStore((state) => state.user) as UserStore;

  const colors: { green: string; text: string; gray4: string; error: string } =
    useMemo(
      () => ({
        green: (theme as any).global.colors.green,
        text: (theme as any).global.colors.text,
        gray4: (theme as any).global.colors.gray4,
        error: (theme as any).global.colors.error,
      }),
      [theme]
    );

  const handlePrevious = async () => {
    if (
      (!step && !user?.phone_number) ||
      (!step && user?.phone_number && state.error)
    ) {
      setShow(false);
    } else if (!step && user?.phone_number && !user.address) {
      setStep(1);
    } else if (!step && user?.address && !uploadKYC?.identity_proof_recto) {
      setStep(2);
    } else {
      setStep(0);
    }
  };

  const handleNext = async () => {
    if (!step) {
      if (!user?.phone_number) {
        setStep(1);
      } else if (!user.address) {
        setStep(2);
      } else if (
        (!uploadKYC?.identity_proof_recto && state.unverified) ||
        state.error
      ) {
        setStep(3);
      } else if (state.verified) {
        setStep(4);
      }
    } else {
      if (step === 1) {
        if (schemaData.isValidSync(personalDataState)) {
          if (personalDataDirty) {
            const resp = await setPersonalData({
              ...personalDataState,
              birthdate: personalDataState.birthdate,
            });
            if (resp) {
              setStep(0);
            } else {
              // @ts-ignore
              setPersonalData(defaultValuesData);
              setStep(0);
              Toast.notifyError(t("invalid_data"));
            }
          } else {
            setStep(0);
          }
        } else {
          Toast.notifyError(t("invalid_data"));
        }
      } else if (step === 2) {
        if (schemaAddress.isValidSync(addressState)) {
          if (addressDirty) {
            const resp = await setAddress({
              ...addressState,
              country:
                countries.filter((c) => c.name === addressState.country)?.[0]
                  ?.code ?? addressState.country,
            });
            if (resp) setStep(0);
            else {
              setAddress(defaultValuesAddress);
              setStep(0);
              Toast.notifyError(t("invalid_data"));
            }
          } else {
            setStep(0);
          }
        } else {
          Toast.notifyError(t("invalid_data"));
        }
      } else if (step === 3) {
        if (schemaDocuments.isValidSync(uploadKYC)) {
          const resp = await setUploadKYC(uploadKYC);
          if (resp) setStep(0);
          else {
            setUploadKYC({
              identity_proof_recto: "",
              identity_proof_verso: "",
            });
            setStep(0);
            Toast.notifyError("couldn't set documents");
          }
        }
      } else if (step === 4) {
        if (formattedPriceToNumber(formPriceValue) >= 50) {
          setStep(5);
        } else {
          setErrorFormPrice(true);
        }
      } else if (step === 5) {
        if (checked) {
          setOpen(true);
        } else {
          setStep(6);
        }
      } else if (step === 6) {
        setShow(false);
      } else {
        setStep(0);
      }
    }
  };

  useEffect(() => {
    if (error) Toast.notifyError(error.message);
  }, [error]);

  const onPaymentResult = useCallback(() => {
    setShow(false);
  }, [setShow]);

  return (
    <>
      {open && (
        <MangoPayFrame
          price={formattedPriceToNumber(formPriceValue)}
          currency={"EUR"}
          open={open}
          setOpen={setOpen}
          onComplete={onPaymentResult}
        />
      )}
      <Modal
        title={t(
          step === 4 || step === 5
            ? "add_funds"
            : closeContent
            ? "warning"
            : "verify_account2"
        )}
        subtitle={t(
          closeContent
            ? ""
            : step === 0
            ? "complete_process2"
            : step === 1
            ? "personal_data_subtitle"
            : step === 2
            ? "address_subtitle"
            : step === 3
            ? "IDcard_subtitle"
            : step === 4
            ? ""
            : step === 5
            ? "payment_method"
            : "successful_operation"
        )}
        body={
          closeContent ? (
            <Box
              height="10rem"
              justify="center"
              style={{ textAlign: "center" }}
            >
              {t("closeContent_body")}
            </Box>
          ) : (
            <Box
              justify="center"
              align="center"
              fill
              pad={{ vertical: "small", horizontal: "0" }}
              gap="medium"
            >
              {isLoading ? (
                <Spinner />
              ) : step === 0 ? (
                <>
                  {/* Personal Data */}
                  <StyledBox
                    direction="row"
                    onClick={() => {
                      !state.verifying && setStep(1);
                    }}
                    fill
                    style={{
                      border:
                        user?.phone_number && !state.error
                          ? ` 1px solid ${colors.green}`
                          : state.error
                          ? ` 1px solid ${colors.error}`
                          : `1px solid ${colors.gray4}`,
                    }}
                  >
                    <Box direction="row" align="center">
                      <Typography
                        margin={{ right: "0.5rem" }}
                        color={
                          user?.phone_number && !state.error
                            ? colors.green
                            : state.error
                            ? colors.error
                            : colors.text
                        }
                        type={
                          user?.phone_number && !state.error
                            ? "subtitle1"
                            : "body1"
                        }
                      >
                        {t("personal_data")}
                      </Typography>
                      {user?.phone_number && !state.error && (
                        <Icon
                          type="Check_2"
                          width="1.5rem"
                          color={colors.green}
                        />
                      )}
                    </Box>
                    <Icon
                      type="Arrow_2_left"
                      width="1rem"
                      color={
                        user?.phone_number && !state.error
                          ? colors.green
                          : state.error
                          ? colors.error
                          : colors.text
                      }
                    />
                  </StyledBox>

                  {/* Address */}
                  <StyledBox
                    direction="row"
                    onClick={() => {
                      if (user?.phone_number && !state.verifying) {
                        setStep(2);
                      } else return false;
                    }}
                    fill
                    style={{
                      border:
                        user?.address && !state.error
                          ? `1px solid ${(theme as any).global.colors.green}`
                          : state.error
                          ? `1px solid ${(theme as any).global.colors.error}`
                          : `1px solid ${colors.gray4}`,
                    }}
                  >
                    <Box direction="row" align="center">
                      <Typography
                        margin={{ right: "0.5rem" }}
                        color={
                          user?.address && !state.error
                            ? colors.green
                            : state.error
                            ? colors.error
                            : colors.text
                        }
                        type={
                          user?.address && !state.error ? "subtitle1" : "body1"
                        }
                      >
                        {t("address")}
                      </Typography>
                      {user?.address && !state.error && (
                        <Icon
                          type="Check_2"
                          width="1.5rem"
                          color={colors.green}
                        />
                      )}
                    </Box>
                    <Icon
                      type={user?.phone_number ? "Arrow_2_left" : "Lock"}
                      width="1rem"
                      color={
                        user?.address && !state.error
                          ? colors.green
                          : state.error
                          ? colors.error
                          : colors.text
                      }
                    />
                  </StyledBox>

                  {/* ID card documents */}
                  <StyledBox
                    direction="row"
                    onClick={() => {
                      if (user?.address && !state.verifying) {
                        setStep(3);
                      } else return false;
                    }}
                    fill
                    style={{
                      border:
                        state.verifying || state.verified
                          ? `1px solid ${(theme as any).global.colors.green}`
                          : state.error
                          ? `1px solid ${(theme as any).global.colors.error}`
                          : `1px solid ${colors.gray4}`,
                    }}
                  >
                    <Box direction="row" align="center">
                      <Typography
                        margin={{ right: "0.5rem" }}
                        color={
                          state.verifying || state.verified
                            ? colors.green
                            : state.error
                            ? colors.error
                            : colors.text
                        }
                        type={
                          state.verifying || state.verified
                            ? "subtitle1"
                            : "body1"
                        }
                      >
                        {t("id_card")}
                      </Typography>
                      {(state.verifying || state.verified) && (
                        <Icon
                          type="Check_2"
                          width="1.5rem"
                          color={colors.green}
                        />
                      )}
                    </Box>
                    <Icon
                      type={user?.address ? "Arrow_2_left" : "Lock"}
                      width="1rem"
                      color={
                        state.verifying || state.verified
                          ? colors.green
                          : state.error
                          ? colors.error
                          : colors.text
                      }
                    />
                  </StyledBox>

                  {/* Add funds */}
                  <StyledBox
                    direction="row"
                    fill
                    onClick={() => {
                      if (uploadKYC && state.verified) {
                        handleNext();
                      } else return false;
                    }}
                  >
                    <Typography>{t("funds")}</Typography>
                    <Icon
                      type={state.verified ? "Arrow_2_left" : "Lock"}
                      width="1rem"
                    />
                  </StyledBox>
                </>
              ) : step === 1 ? (
                <PersonalDataForm
                  setPersonalData={setPersonalDataState}
                  user={user}
                  setIsDirty={setPersonalDataDirty}
                />
              ) : step === 2 ? (
                <AddressForm
                  setAddress={setAddressState}
                  address={user?.address}
                  setIsDirty={setAddressDirty}
                />
              ) : step === 3 ? (
                <DocumentsForm
                  setUploadKYC={setUploadKYCState}
                  validSizeRecto={validSizeRecto}
                  validSizeVerso={validSizeVerso}
                  setValidSizeRecto={setValidSizeRecto}
                  setValidSizeVerso={setValidSizeVerso}
                />
              ) : step === 4 ? (
                <FundsForm
                  value={formPriceValue}
                  setValue={setFormPriceValue}
                  errorFormPrice={errorFormPrice}
                />
              ) : step === 5 ? (
                <PaymentMethodForm
                  checked={checked}
                  setChecked={setChecked}
                  checked2={checked2}
                  setChecked2={setChecked2}
                />
              ) : (
                <SuccessContent />
              )}
            </Box>
          )
        }
        leftAction={
          (step <= 3 || step === 5 || closeContent) && (
            <Button
              disabled={state.verifying}
              label={t(closeContent ? "not_close" : "back")}
              tertiary={!closeContent}
              width={
                small && step === 5 ? "7.5rem" : small ? "6rem" : "12.5rem"
              }
              height={small ? "3rem" : "3.5rem"}
              onClick={() => {
                if (closeContent) {
                  setCloseContent(false);
                } else if (step <= 3) {
                  handlePrevious();
                } else {
                  setStep(4);
                }
              }}
            />
          )
        }
        rightAction={
          <Button
            label={t(
              closeContent || step === 6
                ? "close"
                : step <= 3
                ? "forward"
                : step === 5 && checked2
                ? "transfer_executed"
                : "continue"
            )}
            disabled={
              (step === 5 && !checked && !checked2) ||
              (step === 4 && formPriceValue === "") ||
              (step === 3 && (!validSizeRecto || !validSizeVerso)) ||
              (errorFormPrice &&
                formattedPriceToNumber(formPriceValue) <= 50) ||
              (!step && state.verifying)
            }
            secondary
            width={small && step === 5 ? "7.5rem" : small ? "6rem" : "12.5rem"}
            height={small ? "3rem" : "3.5rem"}
            onClick={() => {
              if (closeContent) {
                setShow(false);
                setCloseContent(false);
              } else {
                handleNext();
              }
            }}
          />
        }
        footer={step <= 3 && <ModalSupportFooter />}
        show={show}
        setShow={setShow}
        setCloseContent={setCloseContent}
        closeContent={
          step >= 4 || (!step && state.verifying) ? undefined : closeContent
        }
      />
    </>
  );
};

export default VerifyModal;
