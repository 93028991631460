import { Card, Icon, Typography } from "@ewibecom/design-system";
import { notifySuccess } from "components/Custom/Toast";

import { useIsSmall } from "hooks";
import { useCopyToClipboard } from "react-use";
import styled, { css } from "styled-components";
import useTranslate from "translate";

export interface CopyTextProps {
  content: string;
  button?: boolean;
}

const CopyTextBox = styled(Card)`
  ${({ theme }) => css`
    background: ${theme.global.colors.white};
    min-width: 8rem;
    min-height: 2rem;
    align-items: center;
    padding: 0rem;
    justify-content: center;
    border-radius: 0.8rem;
    box-shadow: none;

    ${(props: { button?: boolean }) =>
      props.button &&
      css`
        padding: 0.5rem;
        border-radius: 0.8rem;
        :hover {
          box-shadow: ${theme.global.boxShadow.dark};
        }

        :focus {
          box-shadow: ${theme.global.boxShadow.default};
        }
        height: 3.5rem;
      `};
  `}
`;

const CopyText: React.FC<CopyTextProps> = (props) => {
  const small = useIsSmall();
  const t = useTranslate("shared.");
  const [, copyToClipboard] = useCopyToClipboard();

  return (
    <CopyTextBox
      direction="row"
      onClick={() => {
        copyToClipboard(props.content);
        notifySuccess(t("copy_clipboard"));
      }}
      button={props.button}
    >
      <Typography
        type={small ? "caption" : "body2"}
        margin={{ right: "0.5rem" }}
        style={{ padding: "0.5rem" }}
      >
        {props.content}
      </Typography>
      <Icon type="Copy" width={small ? "1.3rem" : "2rem"} />
    </CopyTextBox>
  );
};

export default CopyText;
