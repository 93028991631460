import { Spinner } from "@ewibecom/design-system";
import BaseLoadable from "react-loadable";

export default function Loadable(opts: any) {
  return BaseLoadable(
    Object.assign(
      {
        loading: Spinner,
        delay: 200,
        timeout: 10000,
      },
      opts
    )
  );
}
