
import { CardImage, Carousel } from "@ewibecom/design-system";
import { useIsSmall, useNavigateToBottle } from "hooks";
import useStore, { UserStore, WineStore } from "store";
import { formatTradingPrice } from "utils";

export interface MostPurchasedCarouselProps {
  showNewest?: boolean;
}

const MostPurchasedCarousel: React.FC<MostPurchasedCarouselProps> = (props) => {
  const navigateToBottle = useNavigateToBottle();
  const small = useIsSmall();
  const { mostTraded, newest } = useStore((state) => state.wine) as WineStore;
  const { user, addFavourite, removeFavourite } = useStore(
    (state) => state.user
  ) as UserStore;

  return (
    <Carousel dots={false} show={small ? 1 : 4}>
      {(props.showNewest ? newest : mostTraded)?.map((b, i) => {
        const favorite = user?.favourites?.includes(b.id);
        return (
          <CardImage
            key={b.id}
            image={b.image}
            description={b?.name ?? ""}
            price={b?.tradingPrice ?? 0}
            change={b.priceLastMonth?.percentage ?? 0}
            bestAsk={formatTradingPrice(b?.bestAsk?.unitPrice ?? 0) + "€"}
            format={b?.bestAsk?.format ?? ""}
            favorite={favorite}
            favoriteAction={() => {
              favorite ? removeFavourite(b.id) : addFavourite(b.id);
            }}
            onClick={() => navigateToBottle(b, i)}
            small
          />
        );
      })}
    </Carousel>
  );
};

export default MostPurchasedCarousel;
