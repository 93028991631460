import { Box, Icon, Typography, theme } from "@ewibecom/design-system";
import { useIsSmall } from "hooks";
import useTranslate from "translate";
import MostPurchasedCarousel from "../MostPurchasedCarousel";

const MostPurchasedBanner = (props: {
  showNewest?: boolean;
  title?: boolean;
  onClick?: () => void;
}) => {
  const t = useTranslate("landing.");
  const small = useIsSmall();
  return (
    <Box>
      <Box
        direction="row"
        justify={props.title ? "between" : "end"}
        align="baseline"
      >
        {props.title && (
          <Typography
            type={small ? "h5" : "h3"}
            maxWidth="100%"
            margin={{ top: "0.5rem" }}
          >
            {t("most_purchased")}
          </Typography>
        )}
        <Box
          direction="row"
          align="center"
          onClick={() => {
            if (props.onClick) props.onClick();
          }}
        >
          <Typography type={small ? "subtitle1" : "h5"}>
            {t("view_all")}
          </Typography>
          <Icon
            type="Arrow_right"
            color={(theme as any).global.colors.primary.default}
            width={small ? "1rem" : "2rem"}
            style={{ marginLeft: "1rem" }}
          />
        </Box>
      </Box>
      <MostPurchasedCarousel showNewest={props.showNewest} />
    </Box>
  );
};

export { MostPurchasedBanner };
