import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { CognitoIdentityProvider, SignInParameters } from '@ewibecom/sdk';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useStore, { AuthStore } from "store";
import { useTranslateState } from "translate";
import { getAge } from 'utils';
import { useHydrateFetch } from './useHydrateFetch';
import { useLogout } from './useLogout';

// useLogin hook wraps auth.login, user.setUser and preferences.setPreferences to manipulate
// multiple stores with a single login opeation
const useLogin = () => {
  const { login } = useStore((state) => state.auth) as AuthStore;
  const logout = useLogout();
  const hydrate = useHydrateFetch();
  const sendDataToGTM = useGTMDispatch()
  const language = useTranslateState();

  return async (data: SignInParameters): Promise<boolean> => {
    const resp = await login({
      email: data.email,
      password: data.password,
    });

    try {
      if (resp) {
        const gtmTag = {
          event: "login",
          user_id: resp.id,
          gender: resp.attributes.gender,
          age: getAge(resp.attributes.birthdate),
          login_method: "mail",
          status: "S",
          website_language: language,
          device: navigator.userAgent,
        };
        sendDataToGTM(gtmTag);
        if (resp?.attributes?.email_verified) {
          return await hydrate();
        } else {
          throw new Error("cannot fetch user info")
        }
      }
    } catch (error) {
      await logout();
    }

    return false;
  };
};

// useLogin hook wraps auth.ssoLogin that redirects the app context to the
const useSSOLogin = () => {
  const { ssoLogin } = useStore((state) => state.auth) as AuthStore;

  return async (provider: CognitoIdentityProvider): Promise<void> => {
    await ssoLogin(provider);
  };
};

const useHandleSSO = () => {
  const location = useLocation();
  const { getCurrentUser } = useStore(
    (state) => state.auth
  ) as AuthStore;
  const hydrate = useHydrateFetch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (location.hash.includes("#access_token")) {
      (async () => {
        setIsLoading(true);
        const resp = await getCurrentUser();
        if (resp) {
          await hydrate();
        }
        setIsLoading(false);
      })();
    }
  }, [location, setIsLoading, getCurrentUser, hydrate]);

  return isLoading;
}


export { useHandleSSO, useLogin, useSSOLogin };

