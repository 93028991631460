import { User, UserPreferences } from "@ewibecom/sdk";
import { AUTO_PROMOCODE } from "config";
import useStore, { PreferencesStore, UserStore } from "store";

// useAcceptToS hook wraps preferences.updatePreferences and updates user onboarding.tc
const useAcceptToS = () => {
  const { preferences, updatePreferences } = useStore((state) => state.preferences) as PreferencesStore;
  const { user, setUser, setPromoCode } = useStore((state) => state.user) as UserStore;

  return async (): Promise<boolean> => {
    try {
      const resp = await updatePreferences({ ...preferences as UserPreferences, accept_tc: true });

      if (resp) {
        setUser({ ...user as User, onboarding: { ...user?.onboarding, tc: true } });
        if (!user?.promocode && AUTO_PROMOCODE === "true") {
          setPromoCode("NOFEESFORU");
        }
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }
}

export { useAcceptToS };
