import { DatePicker, DatePickerProps } from "@ewibecom/design-system";
import { Control, Controller } from "react-hook-form";

export interface ControlledDatePickerProps extends DatePickerProps {
  control: Control<any, any>;
}

const ControlledDatePicker: React.FC<ControlledDatePickerProps> = (props) => {
  const { control, ...rest } = props;

  return (
    <Controller
      name={props.name || (props.placeholder as string)}
      control={control}
      render={({ field }) => (
        <DatePicker
          name={field.name}
          value={
            typeof field.value === "string"
              ? new Date(field.value)
              : field.value
          }
          setValue={field.onChange}
          onBlur={field.onBlur}
          placeholder={props.placeholder}
          {...rest}
        />
      )}
    />
  );
};

export default ControlledDatePicker;
