import { Box, BoxExtendedProps } from "@ewibecom/design-system"
import React from "react";

export interface EllipsisProps extends BoxExtendedProps {
  text: string;
}

const Ellipsis: React.FC<EllipsisProps> = ({
  overflow = "hidden",
  style = {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical"
  },
  ...props
}) => {
  return (
    <Box
      overflow={overflow}
      style={style}
      {...props}>
      {props.text}
    </Box>
  )
}

export { Ellipsis };