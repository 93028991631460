import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { Wine } from "@ewibecom/sdk";
import { PATHS } from "config";
import { useNavigate } from "react-router-dom";
import useStore, { UserStore } from "store";
import { useTranslateState } from "translate";
import { getAge } from "utils";

const useNavigateToBottle = () => {
  const sendDataToGTM = useGTMDispatch();
  const navigate = useNavigate();
  const { user } = useStore(
    (state) => state.user
  ) as UserStore;
  const language = useTranslateState();

  return (entity: number | string | Wine, position?: number) => {
    const { gtmTag, id } = (typeof entity === "object") &&
      "id" in (entity as Wine) ?
        {
          gtmTag: {
            event: "view_content",
            user_id: user?.id,
            address: user?.address,
            age: user?.birthdate ? getAge(user.birthdate) : 0,
            gender: user?.gender,
            item_id: (entity as Wine).id,
            item_name: (entity as Wine).name,
            item_position: position || 0,
            website_language: language,
            device: navigator.userAgent,
          },
          id: (entity as Wine).id
        } :
        {
          gtmTag: {
            event: "view_content",
            user_id: user?.id,
            address: user?.address,
            age: user?.birthdate ? getAge(user.birthdate) : 0,
            gender: user?.gender,
            item_id: entity,
            item_position: position || 0,
            website_language: language,
            device: navigator.userAgent,
          },
          id: entity
        };
    sendDataToGTM(gtmTag);
    navigate(PATHS.APP.BASE + PATHS.APP.MARKET + "/" + id);
  };
};

export { useNavigateToBottle };
