import { ResponsiveContext } from "@ewibecom/design-system";
import { useContext, useMemo } from "react";

const useIsSmall = () => {
  const size = useContext(ResponsiveContext);
  const small = useMemo(() => size === "small", [size]);
  return small;
}

export { useIsSmall };
