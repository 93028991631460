import { useMemo } from "react";
import useStore, { PortfolioStore } from "store";
import { multiplierMap } from "utils";

const useNetWorth = () => {
  const { portfolio } = useStore(state => state.portfolio) as PortfolioStore;

  const { netWorth, wineryValue, totalBottles, cashBalance, committedBalance, varValue, varPercentage } = useMemo(() => {
    let wineryValue = 0;
    let sumCostEverHeld = 0;
    let totalBottles = 0;
    const eurBalance = portfolio?.result.balances.find(b => b.currency === "EUR");

    portfolio?.result?.ownedWines.map(w => {
      wineryValue += (w?.tradingPrice ?? 0) * multiplierMap(w.wineFormat) * (w.amount + w.stockedAmount);
      sumCostEverHeld += (w.totalCostEverHeld / w.qtyEverHeld) * (w.amount + w.stockedAmount);
      totalBottles += w.amount + w.stockedAmount;
      return true;
    });
    const varValue = wineryValue - sumCostEverHeld;
    const varPercentage = sumCostEverHeld ? Number(((wineryValue / sumCostEverHeld - 1) * 100).toFixed(2)) : 0;

    const netWorth = (eurBalance?.balance ?? 0) + (eurBalance?.stockedBalance ?? 0) + wineryValue;

    return {
      netWorth,
      wineryValue,
      totalBottles,
      cashBalance: eurBalance?.balance ?? 0,
      committedBalance: eurBalance?.stockedBalance ?? 0,
      varValue,
      varPercentage,
    };
  }, [portfolio]);

  return { netWorth, wineryValue, totalBottles, cashBalance, committedBalance, varValue, varPercentage };
};

export { useNetWorth };
