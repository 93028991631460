import { FormPrice, FormPriceProps } from "@ewibecom/design-system";
import { Controller, Control } from "react-hook-form";

export interface ControlledFormPriceProps extends FormPriceProps {
  control: Control<any, any>;
}

const ControlledFormPrice: React.FC<ControlledFormPriceProps> = (props) => {
  return (
    <Controller
      name={props.name || (props.placeholder as string)}
      control={props.control}
      render={({ field }) => {
        return (
          <FormPrice
            name={field.name}
            value={field.value}
            setValue={field.onChange}
            onBlur={field.onBlur}
            placeholder={props.placeholder}
            {...props}
          />
        );
      }}
    />
  );
};

export default ControlledFormPrice;
