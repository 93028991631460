import { OwnedWinePerformance } from "components/Listing";
import { useCallback, useEffect, useState } from "react";
import useStore, { PortfolioStore, UserStore, WineStore } from "store";

const useFetchOwnedWines = () => {
  const { portfolio, isLoading: portfolioLoading, error: portfolioError } = useStore(
    (state) => state.portfolio
  ) as PortfolioStore;
  const {
    getWineByIds,
    isLoading: wineLoading,
    error: wineError,
  } = useStore((state) => state.wine) as WineStore;
  const {
    owned, setOwned
  } = useStore((state) => state.user) as UserStore;
  const [performance, setPerformance] = useState<OwnedWinePerformance[]>([]);
  const GetWineByIds = useCallback((ids: number[]) => getWineByIds(ids), [getWineByIds]);

  // fetch user bottles by id if not available
  useEffect(() => {
    if (portfolio?.result.ownedWines.length)
      (async () => {
        const wines = await GetWineByIds(portfolio?.result.ownedWines.map((w) => w.wine_parent_id));
        if (wines && wines.length) setOwned(wines);
      })();
  }, [portfolio?.result.ownedWines, GetWineByIds, setOwned]);

  // merge user bottles fetched by id with bottle performance from portfolio
  useEffect(() => {
    if (portfolio?.result.ownedWines.length && owned?.length) {
      setPerformance(
        portfolio?.result.ownedWines.map(
          (o, i) => ({ ...o, ...owned.find(own => own.id === o.wine_parent_id) } as OwnedWinePerformance)
        )
      );
    }
  }, [portfolio?.result.ownedWines, owned]);

  return { bottles: performance, isLoading: portfolioLoading || wineLoading, error: portfolioError || wineError };
}

export { useFetchOwnedWines };
