import { useCallback, useEffect } from "react";
import useStore, { UserStore, WineStore } from "store";

const useFetchFavorites = () => {
  const { user, favourites, setFavourites } = useStore(
    (state) => state.user
  ) as UserStore;
  const { getWineByIds, isLoading } = useStore(
    (state) => state.wine
  ) as WineStore;
  const GetWineByIds = useCallback((ids: number[]) => getWineByIds(ids), [getWineByIds]);

  useEffect(() => {
    if (user?.favourites?.length)
      (async () => {
        const wines = await GetWineByIds(user?.favourites ?? []);
        if (wines && wines.length) {
          setFavourites(wines);
        }
      })();
  }, [user?.favourites, GetWineByIds, setFavourites]);

  return { favourites: favourites ?? [], isLoading };
}

export { useFetchFavorites };
