import { FormTextAreaProps, FormTextArea } from "@ewibecom/design-system";
import { Control, Controller } from "react-hook-form";

export interface ControlledFormTextAreaProps
  extends Partial<FormTextAreaProps> {
  control: Control<any, any>;
}

const ControlledFormTextArea: React.FC<ControlledFormTextAreaProps> = (
  props
) => {
  const { control, ...rest } = props;

  return (
    <Controller
      name={props.name || (props.placeholder as string)}
      control={control}
      render={({ field }) => (
        // @ts-ignore
        <FormTextArea
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          placeholder={props.placeholder}
          {...rest}
        />
      )}
    />
  );
};

export default ControlledFormTextArea;
