import { Box, Typography, theme } from "@ewibecom/design-system";
import { Desktop, DesktopWebp } from "assets/images";
import { ImageFallback } from "components/Custom";
import { NewsletterForm } from "components/Custom/NewsletterForm";
import { useIsSmall } from "hooks";
import useTranslate from "translate";
import { Section } from "../Section";
import { HS_FORM_ID_HOMEPAGE } from "config";

const HeroBanner: React.FC = () => {
  const t = useTranslate("landing.");
  const small = useIsSmall();

  return (
    <>
      {small ? (
        <Section small={small}>
          <ImageFallback
            src={DesktopWebp}
            fallback={Desktop}
            width="100%"
            alt="desktop"
          />
          <Typography
            type="h5"
            color={(theme as any).global.colors.secondary.default}
            margin={{ top: "2rem", bottom: "1.5rem" }}
          >
            {t("hero_title_mobile")}
          </Typography>
          <Typography>{t("hero_sub_mobile")}</Typography>

          <Box margin={{ top: "medium" }}>
            <NewsletterForm width="100%" formId={HS_FORM_ID_HOMEPAGE} event="pre_signup" />
          </Box>
        </Section>
      ) : (
        <Section direction="row">
          <Box width="60%" justify="center" align="start" gap="medium">
            <Typography
              type="h3"
              color={(theme as any).global.colors.secondary.default}
              margin="0"
            >
              {t("hero_title")}
            </Typography>
            <Typography type="h6" light maxWidth="80%" margin="0">
              {t("hero_sub")}
            </Typography>
            <NewsletterForm width="75%" formId={HS_FORM_ID_HOMEPAGE} event="pre_signup" />
          </Box>
          <Box
            width="40%"
            style={{
              paddingRight: "3rem",
              paddingTop: "3rem",
              paddingBottom: "3rem",
            }}
          >
            <ImageFallback
            src={DesktopWebp}
            fallback={Desktop}
            width="100%"
            alt="desktop"
          />
          </Box>
        </Section>
      )}
    </>
  );
};

export default HeroBanner;
