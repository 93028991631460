import { Box, Typography, theme } from "@ewibecom/design-system";
import { useIsSmall } from "hooks";
import useStore, { WineStore } from "store";
import styled, { keyframes } from "styled-components";
import { formatPercentage } from "utils";

const StageAnimation = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
`;

const StyledBox = styled(Box)`
  display: inline-block;
  white-space: nowrap;
  animation: ${StageAnimation} 20s linear infinite;
`;

const IndexBar: React.FC = () => {
  const small = useIsSmall();
  const { marketTrend } = useStore((state) => state.wine) as WineStore;

  return (
    <Box
      background={(theme as any).global.colors.secondary.light}
      width="min-content"
      direction="row"
      style={{
        position: "relative",
        zIndex: 1,
        left: 0,
        overflow: "hidden",
      }}
    >
      <StyledBox
        background={(theme as any).global.colors.secondary.light}
        direction="row"
        justify="between"
        width="min-content"
      >
        {marketTrend?.map((e) => (
          <Typography
            key={e.name}
            type="subtitle2"
            maxWidth="100%"
            margin={{
              left: e.name === "MERCATO" && !small ? "10rem" : "1rem",
            }}
            style={{ display: "inline-block" }}
          >
            {e.name.toUpperCase() ?? ""}
            <Typography
              type="subtitle2"
              color={
                e?.priceLastYear?.percentage &&
                e?.priceLastYear?.percentage >= 0
                  ? (theme as any).global.colors.green
                  : (theme as any).global.colors.error
              }
              margin={{ left: "0.5rem" }}
            >
              {e?.priceLastYear?.percentage &&
                e?.priceLastYear?.percentage > 0 &&
                "+"}
              {formatPercentage(e?.priceLastYear?.percentage ?? 0)}
            </Typography>
            <Typography
              type="subtitle2"
              maxWidth="100%"
              margin={{ left: "1rem" }}
            >
              |
            </Typography>
          </Typography>
        ))}
        {marketTrend?.map((e) => (
          <Typography
            key={e.name}
            type="subtitle2"
            maxWidth="100%"
            style={{ display: "inline-block" }}
            margin={{ left: "1rem" }}
          >
            {e.name.toUpperCase()}
            <Typography
              type="subtitle2"
              margin={{ left: "0.5rem" }}
              color={
                e?.priceLastYear?.percentage &&
                e?.priceLastYear?.percentage >= 0
                  ? (theme as any).global.colors.green
                  : (theme as any).global.colors.error
              }
            >
              {e?.priceLastYear?.percentage &&
                e?.priceLastYear?.percentage > 0 &&
                "+"}
              {formatPercentage(e?.priceLastYear?.percentage ?? 0)}
            </Typography>
            <Typography
              type="subtitle2"
              maxWidth="100%"
              margin={{ left: "1rem" }}
            >
              |
            </Typography>
          </Typography>
        ))}
      </StyledBox>
    </Box>
  );
};

export default IndexBar;
