import { CardImageProps, Header, Icon, SearchbarProps } from "@ewibecom/design-system";
import { Wine } from "@ewibecom/sdk";
import { Logo, LogoWhite } from "assets/icons";
import { AddFundsModal } from "components/AddFundsModal";
import { PATHS } from "config";
import { useIsSmall, useNavigateToBottle } from "hooks";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "react-use";
import useStore, { WineStore } from "store";
import useTranslate from "translate";
import DropdownMenu from "./DropdownMenu";
import { formatTradingPrice } from "utils";
import { OptionsProps } from "@ewibecom/design-system/dist/components/Searchbar";

const RoutedHeader: React.FC = () => {
  const t = useTranslate("shared.");
  const small = useIsSmall();
  const navigate = useNavigate();
  const navigateToBottle = useNavigateToBottle();
  const { getWineByPattern, getWineCollection, setWine, isSearching } =
    useStore((state) => state.wine) as WineStore;

  const [showFunds, setShowFunds] = useState(false);
  const [search, setSearch] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  const [suggestions, setSuggestions] = useState<Wine[]>([]);
  const [open, setOpen] = useState(false);
  const [displayResult, setDisplayResult] = useState(false);

  const [ready] = useDebounce(
    () => {
      setDebouncedValue(search);
    },
    2000,
    [search]
  );

  useEffect(() => {
    if (debouncedValue) {
      (async () => {
        const resp = await getWineByPattern(debouncedValue);
        if (resp) {
          setSuggestions(resp);
        }
      })();
    }
  }, [debouncedValue, getWineByPattern]);

  const icons = useMemo(() => {
    return [
      <Icon
        key="Notifiche"
        type="Notifiche"
        width="1.5rem"
        height="1.5rem"
        style={{ cursor: "pointer", margin: "0 0.4rem" }}
      />,
      <Icon
        key="Wallet"
        type="Wallet_3"
        width="1.5rem"
        height="1.5rem"
        style={{ cursor: "pointer", margin: "0 0.4rem" }}
        onClick={() => setShowFunds(true)}
      />,
      <DropdownMenu />,
    ];
  }, []);

  const menu = useMemo(
    () => [
      { label: t("route_dashboard"), content: PATHS.APP.DASHBOARD },
      { label: t("route_market"), content: PATHS.APP.MARKET },
      { label: t("route_cellar"), content: PATHS.APP.CELLAR },
    ],
    [t]
  );

  const handleReset = () => {
    setSearch("");
    setSuggestions([]);
  };

  const options = useMemo(() => {

    const handleAction = (w: Wine, i: number) => {
      setWine(w);
      getWineCollection(w.id);
      handleReset();
      navigateToBottle(w, i);
      setOpen(false);
    }

    return displayResult ?
      suggestions.map((w, i) => ({
        description: w.name,
        bestAsk: formatTradingPrice(w?.bestAsk?.unitPrice ?? 0) + "€",
        format: w?.bestAsk?.format ?? "",
        price: w?.tradingPrice ?? 0,
        change: w.priceLastYear?.percentage || 0,
        image: w.image,
        ultrasmall: true,
        onClick: () => handleAction(w, i),
      } as CardImageProps)) :
      suggestions.slice(0, 3).map((w, i) => ({
        title: w.name,
        subtitle: w.region,
        image: w.image,
        onAction: () => handleAction(w, i),
      } as OptionsProps));
    }, [displayResult, suggestions, setWine, getWineCollection, navigateToBottle]);

  const searchBarProps = useMemo(() => {
    return {
      options,
      onShowAll: () => {
        setDisplayResult(true);
      },
      showAllLabel: t("show_all"),
      placeholder: t("search_bottle"),
      value: search,
      setValue: (e) => {
        setSearch(e);
        setDisplayResult(false);
      },
      loading: search && (!ready() || isSearching),
    } as SearchbarProps;
  }, [t, search, ready, options, isSearching]);

  return (
    <>
      <AddFundsModal show={showFunds} setShow={setShowFunds} />
      <Header
        menu={menu}
        icons={icons}
        logo={
          <Logo
            height={small ? "2rem" : "3rem"}
            onClick={() => navigate(PATHS.APP.DASHBOARD)}
            style={{ cursor: "pointer" }}
          />
        }
        sidebarLogo={
          <LogoWhite
            height="2.5rem"
            onClick={() => navigate(PATHS.APP.DASHBOARD)}
            style={{ cursor: "pointer" }}
          />
        }
        search={searchBarProps}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};

export default RoutedHeader;
