import { useMemo } from "react";
import LazyLoad from "react-lazyload";

interface ImageFallbackProps {
  src: string; // webp image
  fallback?: string; // png image
  alt?: string;
  type?: string;
  lazy?: boolean;
  width?: string;
  height?: string;
}

const ImageFallback: React.FC<ImageFallbackProps> = ({
  src,
  fallback,
  alt,
  type = "image/webp",
  lazy = true,
  width,
  height,
  ...delegated
}) => {
  const Image = useMemo(() => {
    return (
      <picture style={{alignItems: "center", justifyContent: "center", display: "flex"}}>
        <source srcSet={src} type={type} width={width} height={height} />
        <img
          src={fallback}
          alt={alt}
          width={width}
          height={height}
          {...delegated}
        />
      </picture>
    );
  }, [src, fallback, alt, type, width, height, delegated]);

  if (lazy) return <LazyLoad>{Image}</LazyLoad>;

  return Image;
};

export { ImageFallback };
