import { useIsSmall } from "hooks";
import useTranslate from "translate";
import { Section } from "./Section";
import {
  EmbedGraph,
  Listing,
  PriceChange,
  Spinner,
  Typography,
} from "@ewibecom/design-system";
import { useEffect } from "react";
import useStore, { WineStore } from "store";
import { notifyError } from "components/Custom/Toast";
import { formatTradingPrice } from "utils";
import { Ellipsis } from "components/Listing/Ellipsis";

const IndexBanner = () => {
  const t = useTranslate("landing.");
  const small = useIsSmall();
  const {
    marketTrend,
    getMarketTrend,
    isLoading: wineLoading,
    error: wineError,
  } = useStore((state) => state.wine) as WineStore;

  useEffect(() => {
    (async () => {
      await getMarketTrend();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (wineError) notifyError(wineError);
  }, [wineError]);

  return (
    <Section small={small}>
      <Typography type={small ? "h5" : "h3"} maxWidth="100%">
        {t("indexes")}
      </Typography>
      {wineLoading ? (
        <Spinner />
      ) : (
        <Listing
          headers={[t("index"), t("last_value"), t("months"), t("graph")]}
          items={ marketTrend && marketTrend.length > 0 ?
            marketTrend.map((v) => [
              <Ellipsis text={v.name} />,
              `${formatTradingPrice(Math.round(v.priceLastMonth.price ?? 0))}`,
              <PriceChange
                value={v.priceLastYear.percentage ?? 0}
                size={small ? "0.6rem" : "1.2rem"}
              />,
              <EmbedGraph
                values={
                  v.historyIndexValues?.slice(-12).map((val) => ({
                    x: new Date(val.seenAt).getTime(),
                    y: val.tradingPrice,
                  })) ?? []
                }
                size={
                  small
                    ? { height: "25%", width: "40%" }
                    : { height: "50%", width: "100%" }
                }
                trendValue={v.priceLastYear.percentage ?? 0}
              />,
            ]) : []
          }
        />
      )}
    </Section>
  );
};

export { IndexBanner };
