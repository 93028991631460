import { useEffect, useMemo, useState } from "react";

const purifyInput = (value: string) => value.replace(/[*+?^${}()|[\]\\]/g, '');

const useSearchFilter = (list: Array<any>, fields: Array<string>) => {
  const [search, setSearch] = useState("");
  const [searchSanitized, setSearchSanitized] = useState("");
  const items = useMemo(() => {
    try {
      if (!search) {
        return list;
      }
      const regexp = new RegExp(`${search}`, "i");
      return list.filter((e) => {
        return fields.some(field => JSON.stringify(e?.[field] ?? false).search(regexp) !== -1);
      });
    } catch (error) {
      console.error(error);
      return list;
    }
  }, [search, fields, list]);

  useEffect(() => {
    const sanitized = purifyInput(searchSanitized);
    setSearch(sanitized);
  }, [searchSanitized])

  return { search, setSearch: setSearchSanitized, items };
}

export { useSearchFilter };
