import { Anchor, Box, Icon, Layer, ResponsiveContext, Typography, theme } from "@ewibecom/design-system";
import { Apple, PlayStore } from "assets/icons";
import { AppleBadge, AppleBadgeWebp, GoogleBadge, GoogleBadgeWebp, QrCode, QrCodeWebp } from "assets/images";
import { ImageFallback } from "components/Custom";
import { useIsSmall } from "hooks";
import { useContext, useState } from "react";
import styled, { css } from "styled-components";
import useTranslate from "translate";

const StyledModal = styled(Layer)`
  ${({ theme }) => css`
    width: 20rem;
    height: 16rem;
    display: "flex";
    border-radius: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content: space-between;
    align-items: center;
    top: 20%;
    background: ${theme.global.colors.secondary.default};
  `}
`;

const RowBox = styled(Box)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const BlueBanner = styled(Box)`
  ${({ theme }) => css`
    flex-direction:row;
    position: relative;
    zIndex: 1;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 3.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    background: ${theme.global.colors.secondary.default};
    align-items: center;
    justify-content: center;
    gap: 1.3rem;
    ${(props: { size?: string }) =>
      props.size === "small" &&
      css`
      justify-content: flex-start;
      gap: 2.2rem;
    `}
  `}
`;

const Link = styled(Anchor)`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.global.colors.primary.default};
    text-decoration: none;
    :hover {
      text-decoration: none;
      font-weight: 700;
    }
  `}
`;

const DownloadApp: React.FC = () => {
  const t = useTranslate("landing.");
  const [open, setOpen] = useState(false);
  const size = useContext(ResponsiveContext);
  const small = useIsSmall();
  const badges = [{ image: AppleBadge, webp: AppleBadgeWebp, alt: "AppleBadge" }, { image: GoogleBadge, webp: GoogleBadgeWebp, alt: "GoogleBadge" }];
  const downloadLink = "https://onelink.to/qbkv7h";

  return (
    <>
      {open && <StyledModal
        onEsc={() => setOpen(false)}
        onClickOutside={() => setOpen(false)}
      >
        <RowBox>
          <Typography color={(theme as any).global.colors.white}>
            {t("qrcode")}
          </Typography>
          <Icon
            type="Close"
            onClick={() => setOpen(false)}
            style={{ cursor: "pointer" }}
            width="1.5rem"
            color={(theme as any).global.colors.white}
          />
        </RowBox>
        <ImageFallback
          src={QrCodeWebp}
          fallback={QrCode}
          alt={"QrCode"}
          height="129px"
          width="129px"
        />
        <RowBox>
          {badges.map((e) => (
            <Anchor href={downloadLink}>
              <ImageFallback
                src={e.webp}
                fallback={e.image}
                alt={e.alt}
                height="44px"
                width="133px"
              />
            </Anchor>
          ))}
        </RowBox>
      </StyledModal>}
      <BlueBanner size={size}>
        <Box style={{ gap: small ? 10 : 20 }} direction="row">
          <Apple />
          <PlayStore />
        </Box>
        <Box style={{ paddingBottom: 1 }}>
          <Typography color={(theme as any).global.colors.white}>
            {t("app_out")}
          </Typography>
        </Box>
        <Link href={small ? downloadLink : undefined} onClick={() => !small && setOpen(true)}>
          <Typography color={(theme as any).global.colors.white} >
            {t("download_now")}
          </Typography>
        </Link>
      </BlueBanner>
    </>
  );
};

export default DownloadApp;