import { Box, Carousel, Typography, theme } from "@ewibecom/design-system";
import {
  BuySell,
  BuySellWebp,
  Discover,
  DiscoverWebp,
  Monitor,
  MonitorWebp,
  Store,
  StoreWebp,
} from "assets/images";
import { ImageFallback } from "components/Custom";
import { useIsSmall } from "hooks";
import { useMemo, useState } from "react";
import useTranslate from "translate";
import { Section } from "../Section";

const list = [
  {
    title: "discover",
    description: "discover_description",
  },
  {
    title: "buy_sell",
    description: "buy_sell_description",
  },
  {
    title: "monitor",
    description: "monitor_description",
  },
  {
    title: "preserve",
    description: "preserve_description",
  },
];

const FeaturesBanner: React.FC = () => {
  const t = useTranslate("landing.");
  const small = useIsSmall();
  const [state, setState] = useState(0);

  const [Image, ImageWebp] = useMemo(() => {
    return !state
      ? [Discover, DiscoverWebp]
      : state === 1
      ? [BuySell, BuySellWebp]
      : state === 2
      ? [Monitor, MonitorWebp]
      : [Store, StoreWebp];
  }, [state]);

  return (
    <>
      {small ? (
        <>
          <Section
            small={small}
            background={(theme as any).global.colors.secondary.default}
          >
            <Carousel
              arrows
              dots={false}
              show={1}
              beforeChange={(_p, n: number) => setState(n)}
            >
              {list.map((e) => (
                <Box key={e.title} justify="center" pad="medium">
                  <Typography
                    textAlign="center"
                    type="h6"
                    color={(theme as any).global.colors.white}
                    margin={{ top: "2rem", bottom: "1rem" }}
                  >
                    {t(e.title)}
                  </Typography>
                  <Typography
                    type="h6"
                    light
                    color={(theme as any).global.colors.white}
                    textAlign="center"
                    margin={{ top: "0rem" }}
                  >
                    {t(e.description)}
                  </Typography>
                </Box>
              ))}
            </Carousel>
          </Section>
          <Box background={(theme as any).global.colors.secondary.light}>
            <ImageFallback
              src={Image}
              fallback={ImageWebp}
              alt="mobile"
              width="100%"
            />
          </Box>
        </>
      ) : (
        <Section
          direction="row"
          background={(theme as any).global.colors.secondary.default}
          pad={{ vertical: "0", left: "xlarge", right: "0" }}
        >
          <Box width="25%" justify="center" align="start" gap="medium">
            {list.map((e, i) => (
              <Typography
                key={e.title}
                type="h5"
                color={(theme as any).global.colors.white}
                margin="0"
                light={state !== i}
                onClick={() => {
                  setState(i);
                }}
                style={{ cursor: "pointer" }}
              >
                {state === i && "| "}
                {t(e.title)}
              </Typography>
            ))}
          </Box>
          <Box
            direction="row"
            justify="between"
            align="center"
            background={(theme as any).global.colors.secondary.light}
            width="100%"
            gap="large"
            pad={{ horizontal: "medium" }}
          >
            <Box width="40%" justify="center" align="center">
              <ImageFallback
                src={Image}
                fallback={ImageWebp}
                alt="mobile"
                height="auto"
                width="100%"
              />
            </Box>
            <Box width="60%" justify="center">
              <Typography type="h3" margin={{ top: "0", bottom: "medium" }}>
                {t(
                  !state
                    ? "discover"
                    : state === 1
                    ? "buy_sell"
                    : state === 2
                    ? "monitor"
                    : "preserve"
                )}
              </Typography>
              <Typography
                margin={{ top: "0" }}
                maxWidth="80%"
                color={(theme as any).global.colors.secondary.default}
              >
                {t(
                  !state
                    ? "discover_description"
                    : state === 1
                    ? "buy_sell_description"
                    : state === 2
                    ? "monitor_description"
                    : "preserve_description"
                )}
              </Typography>
            </Box>
          </Box>
        </Section>
      )}
    </>
  );
};

export default FeaturesBanner;
