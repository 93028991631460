import { Provider as ThemeProvider } from "@ewibecom/design-system";
import { BrowserTracing } from "@sentry/browser";
import * as Sentry from "@sentry/react";
import App from "App";
import { ToastContainer } from "components/Custom/Toast";
import { SENTRY_DSN, REACT_APP_RELEASE_ID } from "config";
import "index.css";
import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { TranslateProvider } from "translate";


Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    new BrowserTracing(),
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: false,
    }),
  ],
  release: REACT_APP_RELEASE_ID,
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 0.5,
});

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready
    .then((r) => r.unregister())
    .catch((err) => console.error(err));
}

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <ThemeProvider>
        <TranslateProvider>
          <ToastContainer />
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </TranslateProvider>
      </ThemeProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
console.log(`eWibe UI build: ${REACT_APP_RELEASE_ID}`);
