import { Typography, TypographyProps, theme } from "@ewibecom/design-system";
import { useIsSmall } from "hooks";

export interface TitleProps extends TypographyProps {
  big?: boolean;
}

const Title: React.FC<TitleProps> = (props) => {
  const small = useIsSmall();
  return (
    <Typography
      type={props.big && !small ? "h5" : "h6"}
      width="100%"
      maxWidth="100%"
      margin={{ vertical: "1rem " }}
      color={(theme as any).global.colors.secondary.default}
      {...props}
    />
  );
};

const Subtitle = (props: TypographyProps) => (
  <Typography
    type="subtitle1"
    width="100%"
    margin={{ top: "0.3rem", bottom: "0" }}
    color={(theme as any).global.colors.secondary.default}
    {...props}
    style={{ fontWeight: 700, lineHeight: "1.5rem" }}
  />
);

const Paragraph = (props: TypographyProps) => (
  <Typography
    type="body1"
    color={(theme as any).global.colors.secondary.default}
    margin={{ vertical: "1rem " }}
    style={{ lineHeight: "1.5rem" }}
    {...props}
  />
);

const List = (props: TypographyProps) => (
  <Typography
    type="body1"
    margin={{ vertical: "0.2rem", left: "1.5rem" }}
    color={(theme as any).global.colors.secondary.default}
    style={{ lineHeight: "1.5rem" }}
    {...props}
  />
);

export { List, Paragraph, Subtitle, Title };
