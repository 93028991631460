import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { Layer, Spinner } from "@ewibecom/design-system";
import { Portfolio } from "@ewibecom/sdk";
import { Toast } from "components/Custom";
import { useIsSmall } from "hooks";
import React, { useCallback, useEffect, useState } from "react";
import useStore, { PortfolioStore, UserStore } from "store";
import { getAge } from "utils";

const MangoPayFrame = ({
  price,
  currency,
  open,
  setOpen,
  onComplete,
}: {
  price: number;
  currency: "EUR" | "USD";
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onComplete: () => void;
}) => {
  const { user, loadMoneyMangoPay } = useStore((state) => state.user) as UserStore;
  const { portfolio, setPortfolio } = useStore(
    (state) => state.portfolio
  ) as PortfolioStore;
  const [url, setUrl] = useState("");
  const small = useIsSmall();
  const sendDataToGTM = useGTMDispatch();

  useEffect(() => {
    (async () => {
      const url = await loadMoneyMangoPay(currency, price * 100);
      if (url) setUrl(url);
    })();
  }, [loadMoneyMangoPay, currency, price]);

  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  const loadMoneyCallback = useCallback(
    (success: boolean, msg: any) => {
      handleClose();
      if (success) {
        Toast.notifySuccess("payment completed");

        const prevBalance = portfolio?.result.balances.filter(
          (b) => b.currency === currency
        )[0];
        if (prevBalance) {
          prevBalance.balance += price;
          setPortfolio({
            ...portfolio,
            result: {
              ...portfolio?.result,
              balances: portfolio?.result.balances
                .filter((b) => b.currency !== currency)
                .concat(prevBalance),
            },
          } as Portfolio);
        }
        const gtmTag = {
          event: "topup_money",
          age: user?.birthdate ? getAge(user?.birthdate) : 0,
          payment_method: "card",
          value: price,
          transaction_currency: "EUR",
          wallet_value: user?.wallet?.balance,
          user_id: user?.id,
          device: navigator.userAgent,
        };
        sendDataToGTM(gtmTag);
      } else {
        switch (msg.data.error.ResultCode) {
          case "101002":
            //catch but do nothing, interrupt by user
            //add more case to ignore them
            break;
          default:
            Toast.notifyError("cannot complete payment");
            break;
        }
      }
      onComplete();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleClose, onComplete, portfolio, price, currency, setPortfolio]
  );

  useEffect(() => {
    if (url) {
      window.addEventListener(
        "message",
        (msg: any) => {
          if (msg.source === msg.target) return true; // Do not want messages from self-level
          if (msg.data.type === "loadMoney") {
            loadMoneyCallback(msg.data.confirm === true, msg);
          }
          return false;
        },
        false
      );
    }
  }, [url, loadMoneyCallback]);

  return (
    <>
      {open && (
        <Layer
          onClickOutside={handleClose}
          onEsc={handleClose}
          style={{
            width: small ? "90%" : "70vw",
            height: small ? "80%" : "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {url ? (
            <iframe src={url} title="MangoPay" width="100%" height="100%" />
          ) : (
            <Spinner />
          )}
        </Layer>
      )}
    </>
  );
};

export default React.memo(MangoPayFrame);
