import { Box } from "@ewibecom/design-system";
import { Address } from "@ewibecom/sdk";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ControlledDropdown,
  ControlledFormText,
  ControlledSearchbar,
} from "components/Custom";
import { GCP_MAPS_API_KEY, countries } from "config";
import { useIsSmall } from "hooks";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useForm } from "react-hook-form";
import useTranslate, { useTranslateState } from "translate";
import * as yup from "yup";

export const defaultValuesAddress = {
  street: "",
  street_number: "",
  zip: undefined,
  city: "",
  country: "",
  province: undefined,
  formatted: "",
};

export const schemaAddress = yup.object().shape({
  street: yup.string().required().min(3),
  street_number: yup.string().required(),
  zip: yup.string().required().min(2).max(10),
  city: yup.string().required().min(2),
  country: yup.string().required().min(2),
  province: yup.string().required().min(2),
});

const AddressForm = ({
  setAddress,
  address,
  setIsDirty,
}: {
  setAddress: React.Dispatch<React.SetStateAction<Address | undefined>>;
  address: Address | undefined;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const t = useTranslate("verification.");
  const small = useIsSmall();
  const { control, watch, reset, setValue, formState } = useForm<Address>({
    defaultValues: defaultValuesAddress,
    resolver: yupResolver(schemaAddress),
  });
  const language = useTranslateState();
  const [options, setOptions] = useState([]);

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey: GCP_MAPS_API_KEY,
      options: {
        types: ["address"],
        fields: [
          "address_components",
          "geometry.location",
          "place_id",
          "formatted_address",
        ],
        language,
        componentRestrictions: { country: "it" },
      },
    });

  const street = watch("street");
  const street_number = watch("street_number");
  const zip = watch("zip");
  const city = watch("city");
  const country = watch("country");
  const province = watch("province");

  const addressState: Address = useMemo(() => {
    return {
      street,
      street_number,
      zip,
      city,
      country,
      province,
      formatted: "",
    };
  }, [street, street_number, zip, city, country, province]);

  const getPredictions = useCallback(
    (input: string) => getPlacePredictions({ input }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    getPredictions(street);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [street]);

  useEffect(() => {
    if (address) {
      reset({
        street: address.street,
        street_number: address.street_number,
        zip: address.zip,
        city: address.city,
        province: address.province,
        country:
          countries.find((e) => {
            return e.code === address?.country;
          })?.name ?? "",
      });
    }
  }, [address, reset]);

  useEffect(() => {
    setAddress(addressState);
  }, [addressState, setAddress]);

  const valuesUpdated = useCallback(() => {
    if (!address || !address.street) return true;
    return [
      "street",
      "street_number",
      "zip",
      "city",
      "country",
      "province",
    ].some((field) => {
      return (
        addressState[field as keyof Address] !== address[field as keyof Address]
      );
    });
  }, [address, addressState]);

  useEffect(() => {
    if (setIsDirty && valuesUpdated()) setIsDirty(formState.isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.isDirty, addressState]);

  useEffect(() => {
    if (placePredictions.length) {
      setOptions(
        placePredictions.map((p) => ({
          title: p.description,
          onAction: () => {
            let [street, number, city, region, country] = p.terms;
            if (p.terms.length === 4) {
              [street, city, region, country] = p.terms;
            }
            if (p.terms.length === 5) {
              setValue("street_number", number.value);
            }
            setValue("street", street.value);
            setValue("city", city.value);
            setValue("province", region.value);
            setValue(
              "country",
              countries.find((e) => {
                return e.name === t(country.value.toLowerCase());
              })?.name ?? ""
            );
            setOptions([]);
          },
        })) as any
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placePredictions, setValue]);

  return (
    <>
      <Box
        margin={{ bottom: "1rem" }}
        direction={small ? "column" : "row"}
        width="100%"
      >
        <Box
          margin={{ right: "0.5rem", bottom: small ? "1rem" : "0" }}
          width={small ? "100%" : "75%"}
        >
          <ControlledSearchbar
            name={"street"}
            options={options}
            loading={!!(street && isPlacePredictionsLoading)}
            placeholder={t("address")}
            input
            filter={false}
            style={{ height: "3rem" }}
            control={control}
          />
        </Box>
        <ControlledFormText
          name="street_number"
          placeholder={t("house_number")}
          width={small ? "100%" : "25%"}
          style={{ padding: small ? "0.8rem" : "1rem" }}
          control={control}
          error={formState.errors.street_number?.message || ""}
        />
      </Box>

      <Box
        margin={{ bottom: "1rem" }}
        direction={small ? "column" : "row"}
        width="100%"
      >
        <Box
          margin={{ right: "0.5rem", bottom: small ? "1rem" : "0" }}
          width={small ? "100%" : "50%"}
        >
          <ControlledFormText
            name={"city"}
            placeholder={t("city")}
            width="100%"
            style={{ padding: small ? "0.8rem" : "1rem" }}
            control={control}
            error={formState.errors.city?.message || ""}
          />
        </Box>
        <ControlledFormText
          name={"province"}
          placeholder={t("province")}
          width={small ? "100%" : "50%"}
          style={{ padding: small ? "0.8rem" : "1rem" }}
          control={control}
          error={formState.errors.province?.message || ""}
        />
      </Box>

      <Box
        margin={{ bottom: "1rem" }}
        direction={small ? "column" : "row"}
        width="100%"
      >
        <Box
          margin={{ right: "0.5rem", bottom: small ? "1rem" : "0" }}
          width={small ? "100%" : "50%"}
        >
          <ControlledFormText
            name={"zip"}
            placeholder={t("zip")}
            width="100%"
            style={{ padding: small ? "0.8rem" : "1rem" }}
            control={control}
            error={formState.errors.zip?.message || ""}
          />
        </Box>
        <ControlledDropdown
          width={small ? "100%" : "50%"}
          name={"country"}
          placeholder={t("country")}
          style={{
            padding: small ? "0.8rem" : "1rem",
          }}
          options={countries.map((e) => e.name)}
          control={control}
        />
      </Box>
    </>
  );
};

export { AddressForm };
