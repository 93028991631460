import { Portfolio, PortfolioQueryParams, Transaction, TransactionsQueryParams } from '@ewibecom/sdk';
import { EwibeSDK as sdk } from 'config';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { AsyncState, defaultAsyncState } from './utils';

interface AsyncStore {
  setPortfolio: (portfolio: Portfolio) => void;
  getPortfolio: (params: PortfolioQueryParams) => Promise<Portfolio | false>;
  getTransactions: (params: TransactionsQueryParams) => Promise<Transaction[] | false>;
  reset: () => void;
}

export interface PortfolioStore extends AsyncState, AsyncStore {
  portfolio?: Portfolio;
  transactions?: Transaction[];
}

export const usePortfolioStore = create<PortfolioStore>()(
  devtools(
    persist(
      ((set) => ({
        error: null,
        isLoading: false,
        setPortfolio: (portfolio) => set({ portfolio }),
        getPortfolio: async (params: PortfolioQueryParams): Promise<Portfolio | false> => {
          try {
            set({ isLoading: true });
            const portfolio = await sdk.user.portfolio(params);
            set({ portfolio, error: null, isLoading: false });
            return portfolio
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        getTransactions: async (params: TransactionsQueryParams): Promise<Transaction[] | false> => {
          try {
            set({ isLoading: true });
            const transactions = await sdk.user.getTransactions(params);
            set({ transactions, error: null, isLoading: false });
            return transactions
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        reset: () => set({ ...defaultAsyncState, portfolio: undefined })
      })), {
      name: "ewibe-portfolio-store",
      partialize: (state) => ({ portfolio: state.portfolio, transactions: state.transactions })
    })
  )
)