import { PingResponse } from '@ewibecom/sdk';
import { EwibeSDK as sdk } from 'config';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { AsyncState } from './utils';

interface AsyncStore {
  setResponse: () => Promise<void>;
}

export interface AppStore extends AsyncState, AsyncStore {
  ping: PingResponse
}

export const useAppStore = create<AppStore>()(
  devtools(
    persist(
      ((set) => ({
        data: null,
        error: null,
        isLoading: false,
        ping: {
          status: 200,
          statusText: "OK",
          body: {
            result: "pong"
          }
        },
        setResponse: async () => {
          const res: PingResponse = await sdk.ping();
          set({ ping: res });
        }
      })), {
      name: "ewibe-app-store",
      partialize: (state) => ({ ping: state.ping, data: state.data })
    })
  )
)
