import { FormField } from "@ewibecom/sdk";
import { EwibeSDK as sdk } from "config";
import {
  DISCORD_WEBHOOK_SUPPORT,
  HS_FORM_ID_GUIDE,
  HS_PORTAL_ID,
} from "config/config";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { AsyncState, getCookie } from "./utils";

interface AsyncStore {
  subscribeNewsletter: (email: string, formId?: string) => Promise<boolean>;
  postMessage: (
    name: string,
    surname: string,
    email: string,
    phone_number: string,
    topic: string,
    message: string
  ) => Promise<void | any>;
  postRequestGuide: (email: string) => Promise<boolean>;
  sendHubspotFrom: (fields: FormField[], formId?: string) => Promise<boolean>;
  hideWelcomeModal: () => void;
  setDashboardTutorial: (arg: boolean) => void;
  setMarketTutorial: (arg: boolean) => void;
  setBottleTutorial: (arg: boolean) => void;
  setCellarTutorial: (arg: boolean) => void;
  setOrderBookTutorial: (arg: boolean) => void;
  reset: () => void;
}

export interface postMessage {
  name: string;
  surname: string;
  email: string;
  phone_number: string;
  topic: string;
  message: string;
}

export interface postRequestGuide {
  email: string;
}

export interface MarketingStore extends AsyncState, AsyncStore {
  showRequestGuide?: boolean;
  showWelcomeModal?: boolean;
  showDashboardTutorial?: boolean;
  showMarketTutorial?: boolean;
  showBottleTutorial?: boolean;
  showCellarTutorial?: boolean;
  showOrderBookTutorial?: boolean;
}

export interface MarketingStore extends AsyncState, AsyncStore {}

export const useMarketingStore = create<MarketingStore>()(
  devtools(
    persist(
      (set) => ({
        isLoading: false,
        ShowRequestGuide: true,
        showWelcomeModal: true,
        subscribeNewsletter: async (
          email: string,
          formId?: string
        ): Promise<boolean> => {
          try {
            set({ isLoading: true });
            const hutk = getCookie("hubspotutk");
            const context = {
              hutk,
              pageUri: document.location.href,
              pageName: document.title,
            };
            const resp = await sdk.marketing.submitForm(
              [
                {
                  objectTypeId: "0-1",
                  name: "email",
                  value: email,
                },
              ],
              context,
              HS_PORTAL_ID,
              formId
            );
            set({ isLoading: false });
            if (resp) {
              return true;
            } else {
              throw new Error("something went wrong");
            }
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        postMessage: async (
          name: string,
          surname: string,
          email: string,
          phone_number: string,
          topic: string,
          message: string
        ): Promise<void | any> => {
          try {
            let resp = await fetch(DISCORD_WEBHOOK_SUPPORT, {
              method: "POST",
              headers: {
                "Content-type": "application/json; charset=UTF-8",
              },
              body: JSON.stringify({
                embeds: [
                  {
                    fields: [
                      { name: "Name", value: name },
                      { name: "Surname", value: surname },
                      { name: "Email", value: email },
                      { name: "PhoneNumber", value: phone_number },
                      { name: "Topic", value: topic },
                      {
                        name: "Message",
                        value: message,
                      },
                    ],
                  },
                ],
              }),
            });
            resp = await resp.json();
            return resp;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        postRequestGuide: async (email: string): Promise<void | any> => {
          try {
            const hutk = getCookie("hubspotutk");
            const context = {
              hutk,
              pageUri: document.location.href,
              pageName: document.title,
            };
            const resp = await sdk.marketing.submitForm(
              [
                {
                  objectTypeId: "0-1",
                  name: "email",
                  value: email,
                },
              ],
              context,
              HS_PORTAL_ID,
              HS_FORM_ID_GUIDE
            );
            if (resp) {
              set({ showRequestGuide: false, error: null });
              return true;
            } else {
              throw new Error("something went wrong");
            }
          } catch (error) {
            set({ error });
            return false;
          }
        },
        sendHubspotFrom: async (
          fields: FormField[],
          formId?: string
        ): Promise<boolean> => {
          try {
            const hutk = getCookie("hubspotutk");
            const context = {
              hutk,
              pageUri: document.location.href,
              pageName: document.title,
            };
            const resp = await sdk.marketing.submitForm(
              fields,
              context,
              HS_PORTAL_ID,
              formId
            );
            if (resp) {
              set({ showRequestGuide: false, error: null });
              return true;
            } else {
              throw new Error("something went wrong");
            }
          } catch (error) {
            set({ error });
            return false;
          }
        },
        hideWelcomeModal: (): void => {
          try {
            set({ showWelcomeModal: false });
          } catch (error) {
            set({ error, isLoading: false });
          }
        },
        setDashboardTutorial: (arg): void => {
          try {
            set({ showDashboardTutorial: arg });
          } catch (error) {
            set({ error, isLoading: false });
          }
        },
        setMarketTutorial: (arg): void => {
          try {
            set({ showMarketTutorial: arg });
          } catch (error) {
            set({ error, isLoading: false });
          }
        },
        setBottleTutorial: (arg): void => {
          try {
            set({ showBottleTutorial: arg });
          } catch (error) {
            set({ error, isLoading: false });
          }
        },
        setCellarTutorial: (arg): void => {
          try {
            set({ showCellarTutorial: arg });
          } catch (error) {
            set({ error, isLoading: false });
          }
        },
        setOrderBookTutorial: (arg): void => {
          try {
            set({ showOrderBookTutorial: arg });
          } catch (error) {
            set({ error, isLoading: false });
          }
        },
        reset: () =>
          set({
            isLoading: false,
            showWelcomeModal: true,
            showDashboardTutorial: true,
            showMarketTutorial: true,
            showBottleTutorial: true,
            showCellarTutorial: true,
            showOrderBookTutorial: true,
          }),
      }),
      {
        name: "ewibe-marketing-store",
        partialize: (state) => ({
          showRequestGuide: state.showRequestGuide,
          showWelcomeModal: state.showWelcomeModal,
          showDashboardTutorial: state.showDashboardTutorial,
          showMarketTutorial: state.showMarketTutorial,
          showBottleTutorial: state.showBottleTutorial,
          showCellarTutorial: state.showCellarTutorial,
          showOrderBookTutorial: state.showOrderBookTutorial,
        }),
      }
    )
  )
);
