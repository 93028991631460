import { Box, Typography } from "@ewibecom/design-system";
import { AddUser, BlueBottle, Money } from "assets/icons";
import { Macbook, MacbookWebp } from "assets/images";
import { ImageFallback } from "components/Custom";
import { useIsSmall } from "hooks";
import styled, { css } from "styled-components";
import useTranslate from "translate";
import { Section } from "../Section";

const list = [
  { icon: <AddUser />, description: "step1" },
  { icon: <Money />, description: "step2" },
  { icon: <BlueBottle />, description: "step3" },
];

const StyledBox = styled(Box)`
  ${({ theme }) => css`
    border-radius: 1rem;
    background: ${theme.global.colors.background};
    box-shadow: ${theme.global.boxShadow.default};
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
  `}
`;

const ProcessBanner = () => {
  const t = useTranslate("landing.");
  const small = useIsSmall();

  return (
    <Section align="center">
      <Typography
        type={small ? "h5" : "h3"}
        textAlign="center"
        maxWidth="100%"
        margin={{ vertical: "0" }}
      >
        {t("process_title")}
      </Typography>
      <Typography
        type={small ? "body1" : "h5"}
        textAlign="center"
        maxWidth={small ? "100%" : "65%"}
        margin={{
          top: small ? "1rem" : "2rem",
          bottom: small ? "1rem" : "xlarge",
        }}
      >
        {t("process_subtitle")}
      </Typography>
      <Box direction={small ? "column" : "row"} fill justify="between">
        <Box width={small ? "100%" : "35%"} align="center">
          {list.map((e, i) => (
            <Box key={e.description} align="center" width="80%">
              <StyledBox
                direction="row"
                margin={{ bottom: small ? "1rem" : "0" }}
                width="100%"
              >
                {e.icon}
                <Typography
                  type={small ? "h6" : "h5"}
                  light={small}
                  margin={{ left: "2rem" }}
                >
                  {t(e.description)}
                </Typography>
              </StyledBox>
              {!small && i < list.length - 1 && (
                <Box width="1px" background="#000000" height="2rem" />
              )}
            </Box>
          ))}
        </Box>
        <Box width={small ? "100%" : "60%"} align="center" justify="center">
          <ImageFallback src={MacbookWebp} fallback={Macbook} width="100%" />
        </Box>
      </Box>
    </Section>
  );
};

export default ProcessBanner;
