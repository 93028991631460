import { DomainKeys, Languages, Locale, LocaleKeys } from "./locales";

const _domains: LocaleKeys[] = ["shared", "dashboard", "verification", "profile", "listing", "auth", "winery", "market", "landing", "tutorial"];

let _language = 'it';

let _translations: Locale = {};

export const getLanguage = () => {
  return _language;
};

export const setLanguage = (language: Languages) => {
  let prev_language = _language;
  try {
    _language = language;
    localStorage.setItem("lang", language);

    _domains.forEach(domain => {
      const loadedDomain = require(`./locales/${domain}/${language}.json`);
      _translations[domain] = loadedDomain;
    })
  } catch (error) {
    _language = prev_language;
    console.error(`setLanguage error: ${error}`);
  }
};

export const getTranslations = () => {
  return _translations;
};

export const t = (label: string, params?: string | string[]): string => {
  const chunks = label.split(".");
  const domain: LocaleKeys = chunks[0] as LocaleKeys;
  const key: DomainKeys = chunks[1] as DomainKeys;

  let value: string = _translations[domain] ?
    (_translations[domain] as Locale)[key]
    : key;

  if (value) {
    if (typeof params == "string") {
      value = value.replace("%s", params);
    } else {
      params?.forEach(param => {
        value = value.replace("%s", param);
      });
    }
  }

  return value;
};