import {
  Box,
  Carousel,
  TabMenu,
  Typography,
  theme,
} from "@ewibecom/design-system";
import { Insurance, Snowflake, YellowBottle } from "assets/icons";
import { useIsSmall } from "hooks";
import { useState } from "react";
import styled, { css } from "styled-components";
import useTranslate from "translate";
import { Section } from "../Section";

export type TabState = 0 | 1 | 2;

const StyledTabMenu = styled(TabMenu)`
  ${({ theme }) => css`
    background: ${theme.global.colors.secondary.default};
    color: ${theme.global.colors.white};
    font-size: 1.2rem;

    ${(props: { selected?: boolean }) =>
      props.selected &&
      css`
        background: ${theme.global.colors.tertiary.light};
        color: ${theme.global.colors.secondary.default};
      `}
  `}
`;

const list = [
  {
    title: "air_conditioning",
    description: "air_conditioning_description",
    icon: <Snowflake />,
  },
  {
    title: "insurance",
    description: "insurance_description",
    icon: <Insurance />,
  },
  {
    title: "authenticity",
    description: "authenticity_description",
    icon: <YellowBottle />,
  },
];

const SecurityBanner: React.FC = () => {
  const t = useTranslate("landing.");
  const small = useIsSmall();
  const [state, setState] = useState<TabState>(0);

  return (
    <>
      {small ? (
        <>
          <Section
            small={small}
            background={(theme as any).global.colors.secondary.light}
            pad="2rem"
          >
            <Typography type="h5" margin={{ vertical: "0" }} textAlign="center">
              {t("bottles_safe")}
            </Typography>
          </Section>
          <Section
            small={small}
            background={(theme as any).global.colors.secondary.default}
            align="center"
          >
            <Carousel
              arrows
              dots={false}
              show={1}
              afterChange={(n: number) => setState(n as TabState)}
            >
              {list.map((e) => (
                <Box key={e.title}>
                  <Typography
                    textAlign="center"
                    type="h6"
                    color={(theme as any).global.colors.white}
                    margin={{ bottom: "1rem" }}
                  >
                    {t(e.title)}
                  </Typography>
                  <Box align="center">
                    {!state ? (
                      <Snowflake width="5rem" />
                    ) : state === 1 ? (
                      <Insurance width="5rem" />
                    ) : (
                      <YellowBottle width="5rem" />
                    )}
                    <Typography
                      type="h6"
                      light
                      color={(theme as any).global.colors.white}
                      textAlign="center"
                      margin={{ top: "1rem" }}
                    >
                      {t(e.description)}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Carousel>
          </Section>
        </>
      ) : (
        <Section
          background={(theme as any).global.colors.secondary.default}
          direction="row"
        >
          <Box width="50%">
            <Typography
              type="h4"
              color={(theme as any).global.colors.white}
              maxWidth="80%"
              margin={{ top: "0", bottom: "xlarge" }}
            >
              {t("bottles_safe")}
            </Typography>
            <Box
              height="3px"
              background={(theme as any).global.colors.white}
              width="80%"
            />
          </Box>
          <Box width="50%" justify="between">
            <Box
              direction="row"
              width="100%"
              margin={{ bottom: "3rem" }}
              height="3rem"
            >
              <StyledTabMenu
                label={t("air_conditioning")}
                selected={!state}
                onClick={() => setState(0)}
                width="100%"
              />
              <StyledTabMenu
                label={t("insurance")}
                selected={state === 1}
                onClick={() => setState(1)}
                width="100%"
              />
              <StyledTabMenu
                label={t("authenticity")}
                selected={state === 2}
                onClick={() => setState(2)}
                width="100%"
              />
            </Box>
            <Box direction="row" align="center" gap="medium">
              {!state ? (
                <Snowflake width="6rem" />
              ) : state === 1 ? (
                <Insurance width="6rem" />
              ) : (
                <YellowBottle width="6rem" />
              )}
              <Typography
                type="body2"
                light
                color={(theme as any).global.colors.white}
                width="100%"
              >
                {t(
                  !state
                    ? "air_conditioning_description"
                    : state === 1
                    ? "insurance_description"
                    : "authenticity_description"
                )}
              </Typography>
            </Box>
          </Box>
        </Section>
      )}
    </>
  );
};

export default SecurityBanner;
