export const capitalize = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export const sleep = async (ms: number): Promise<void> => {
  return await new Promise(r => setTimeout(r, ms));
};

export const getTime = (date?: Date | string) => {
  if (typeof date === "string") {
    date = new Date(date);
  }
  return date != null ? date.getTime() : 0;
}

const multiplier = [
  { format: "1x75", value: 1 },
  { format: "1x150", value: 2 },
  { format: "1x300", value: 4 },
  { format: "6x75", value: 6 },
  { format: "12x75", value: 12 }
]

export const multiplierMap = (format: string) => {
  return multiplier.find(m => m.format === format)?.value ?? 1;
}

export const formattedPriceToNumber = (price: string): number => {
  return Number(price.replaceAll(".", "").replace(",", "."));
}

export const formatNumber = (value: number, locale?: string): string => {
  if (value === 0) return "0";
  return new Intl.NumberFormat(locale || 'it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
}

export const formatTradingPrice = (value: number, locale?: string): string => {
  if (value === 0) return "0";
  return new Intl.NumberFormat(locale || 'it-IT', { maximumFractionDigits: 0 }).format(value);
}

export const formatPercentage = (value: number, locale?: string) => {
  return new Intl.NumberFormat(locale || "it-IT", {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }).format(value) + "%";
};

export function getAge(date: string | number | Date): number {
  var today = new Date();
  var birthDate = new Date(date);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

// Returns date adding days. By default returns tomorrow. Negative argument results in subtracting days.
// Also supports second date argument to change date reference, defaults to today.
export function getDateAddDays(days: number = 1, date: Date = new Date()): Date {
  return new Date(date.setDate(date.getDate() + days));
}

/**
 * Returns an ordered Array of vintages/Years, By default start from today and get 1+ year
 * eg: Returns default [2023, 2024]
 *
 * @param {number} years number of years to compose Array
 * @param {Date} startDate Date from which to start taking the count of year (Today default)
 * @returns {Array<number>}
 */
export function getArrayOfYear(years: number = 1, startDate: Date = new Date()): Array<number> {
  return Array.from(
    { length: years },
    (_, index) => startDate.getFullYear() + index
  ).sort((a, b) => b - a)
}

// Returns date adding months. By default returns tomorrow. Negative argument results in subtracting months
// Also supports second date argument to change date reference, defaults to today.
export function getDateAddMonths(months: number = 1, date: Date = new Date()): Date {
  return new Date(date.setMonth(date.getMonth() + months));
}

// Returns date adding years. By default returns next year. Negative argument results in subtracting years
// Also supports second date argument to change date reference, defaults to today.
export function getDateAddYears(years: number = 1, date: Date = new Date()): Date {
  return new Date(date.setFullYear(date.getFullYear() + years));
}

// Returns an array containing the list of months' prefixes to be translated
export function getMonths(): string[] {
  return ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
}