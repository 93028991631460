import { UserPreferences } from "@ewibecom/sdk";
import useStore, { PortfolioStore, PreferencesStore, UserStore } from "store";

// useHydrateFetch is a hook implemented to collect all required user-related fetch operations
// on hydrate, in order to be reuse in multiple places. Fetches are dispatched in
// parallel using Promise.allSettled that waits for all Promises to either resolve
// or reject.
const useHydrateFetch = () => {
  const { getInfo, getPerformance } = useStore((state) => state.user) as UserStore;
  const { getPreferences, setPreferences } = useStore((state) => state.preferences) as PreferencesStore;
  const { getPortfolio, getTransactions } = useStore((state) => state.portfolio) as PortfolioStore;

  return async (): Promise<boolean> => {
    const user = await getInfo();
    if (user) {
      if (user.preferences) setPreferences(user.preferences as UserPreferences);

      const result = await Promise.allSettled([
        getPreferences(),
        getPortfolio({
          limitAskArchiveDone: 100,
          limitAsk: 10000,
          limitAskArchiveDropped: 100,
        }),
        getTransactions({ offsetWithdraws: 0, limitWithdraws: 20 }),
        getPerformance(),
        // insert here all required initial fetches
      ])

      return !result.some(result => result.status === "rejected");
    }

    return false;
  }
}

export { useHydrateFetch };
