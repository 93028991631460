import { Box, Button, Header, Typography } from "@ewibecom/design-system";
import { Logo, LogoWhite } from "assets/icons";
import { PATHS } from "config";
import { useIsSmall } from "hooks";
import { useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import useTranslate from "translate";

const StyledHeader = styled(Header)`
  div:last-child > div:last-child > button {
    margin-left: 0.5rem;
  }
  div:last-child > div:first-child > a:last-child {
    margin-left: 2.5rem;
  }
`;

const LandingHeader: React.FC = () => {
  const t = useTranslate("shared.");
  const small = useIsSmall();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const transactionId = useMemo(() => searchParams.get("tid"), [searchParams]);
  const urlLogin = useMemo(() =>
    transactionId ? PATHS.AUTH.LOGIN + `?tid=${transactionId}` : PATHS.AUTH.LOGIN,
  [transactionId]);
  const urlSignup = useMemo(() =>
    transactionId ? PATHS.AUTH.SIGNUP + `?tid=${transactionId}` : PATHS.AUTH.SIGNUP,
  [transactionId]);

  const icons = useMemo(() => {
    return [
      small && (
        <Box
          align="center"
          justify="center"
          height="2.5rem"
          style={{ cursor: "pointer" }}
          onClick={() => navigate(urlLogin, { replace: true })}
        >
          <Typography>{t("route_login")}</Typography>
        </Box>
      ),
      <Button
        secondary
        label={t("sign_up")}
        height="2.5rem"
        width="8rem"
        margin={{ left: "2rem" }}
        onClick={() => window.location.href = urlSignup}
      />,
    ];
  }, [t, small, navigate, urlLogin, urlSignup]);

  const menu = useMemo(
    () => [
      { label: t("route_home"), content: PATHS.LANDING.HOMEPAGE },
      { label: t("route_market"), content: PATHS.LANDING.MARKET },
      {
        label: t("route_discover"),
        content: [
          {
            label: t("route_discover_why"),
            content: PATHS.LANDING.INVESTMENT,
          },
          {
            label: t("route_discover_how"),
            content: PATHS.LANDING.HOW,
          },
          { label: t("route_discover_us"), content: PATHS.LANDING.WHYUS },
        ],
      },
      { label: t("route_pricing"), content: PATHS.LANDING.PRICING },
      {
        label: t("route_info"),
        content: [
          { label: t("route_about"), content: PATHS.LANDING.INFO },
          { label: t("route_contacts"), content: PATHS.LANDING.CONTACTS },
          { label: t("route_faq"), content: PATHS.LANDING.FAQ },
        ],
      },
      { label: t("route_blog"), content: "https://blog.ewibe.com/" },
      { label: t("route_login"), content: urlLogin },
    ],
    [t, urlLogin]
  );

  return (
    <StyledHeader
      menu={menu}
      // @ts-ignore
      icons={icons}
      logo={
        <Logo
          height={small ? "2rem" : "3rem"}
          onClick={() => navigate(PATHS.LANDING.HOMEPAGE)}
          style={{ cursor: "pointer" }}
        />
      }
      sidebarLogo={
        <LogoWhite
          height="2.5rem"
          onClick={() => navigate(PATHS.LANDING.HOMEPAGE)}
          style={{ cursor: "pointer" }}
        />
      }
      open={open}
      setOpen={setOpen}
    />
  );
};

export default LandingHeader;
