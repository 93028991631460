import { Box, FormPrice, Typography } from "@ewibecom/design-system";
import { useIsSmall } from "hooks";
import React from "react";
import useTranslate from "translate";
import { formattedPriceToNumber } from "utils";

interface FundsFormProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  errorFormPrice: boolean;
}

const FundsForm: React.FC<FundsFormProps> = (props) => {
  const t = useTranslate("verification.");
  const small = useIsSmall();

  return (
    <Box align="center" margin={{ top: "1.5rem", bottom: "1rem" }}>
      <Typography
        type={small ? "body2" : "body1"}
        light
        style={{ textAlign: "center" }}
        width={small ? "90%" : "100%"}
        margin={{ bottom: "2rem" }}
      >
        {t("charge_funds_subtitle")}
      </Typography>
      <FormPrice
        placeholder={t("enter_amount")}
        maxLength={9}
        error={
          props.value !== "" &&
          formattedPriceToNumber(props.value) <= 50 &&
          props.errorFormPrice
        }
        errorLabel={t("error_label")}
        value={props.value}
        setValue={props.setValue}
      />
    </Box>
  );
};

export { FundsForm };
