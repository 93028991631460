import { Box, Typography, theme } from "@ewibecom/design-system";
import { Circle, Flower, Tax, WhiteBottle } from "assets/icons";
import { useIsSmall } from "hooks";
import useTranslate from "translate";
import { Section } from "../Section";

const WhyBanner: React.FC = () => {
  const t = useTranslate("landing.");
  const small = useIsSmall();
  const list = [
    [
      { title: "returns", icon: <Flower /> },
      { title: "no_tax", icon: <Tax /> },
    ],

    [
      { title: "diversification", icon: <Circle /> },
      { title: "tangibility", icon: <WhiteBottle /> },
    ],
  ];

  return (
    <Section
      small={small}
      background={(theme as any).global.colors.secondary.default}
      style={{ textAlign: "center" }}
    >
      <Typography
        type={small ? "h5" : "h3"}
        color={(theme as any).global.colors.white}
        maxWidth="100%"
        margin={{
          bottom: small ? "2rem" : "4rem",
          top: "0",
        }}
      >
        {t("why_title")}
      </Typography>
      {small ? (
        list.map((e) => (
          <Box key={e[0].title}>
            <Box
              direction="row"
              gap="small"
              align="baseline"
              margin={{ bottom: "1.5rem" }}
            >
              <Box align="center" width="50%">
                {e[0].icon}
                <Typography
                  type="subtitle1"
                  color={(theme as any).global.colors.white}
                  margin={{ top: "1rem" }}
                >
                  {t(e[0].title)}
                </Typography>
              </Box>
              <Box align="center" width="50%">
                {e[1].icon}
                <Typography
                  type="subtitle1"
                  color={(theme as any).global.colors.white}
                  margin={{ top: "1rem" }}
                >
                  {t(e[1].title)}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))
      ) : (
        <Box direction="row" gap="small">
          {list[0].map((e) => (
            <Box align="center" width="80%" key={e.title}>
              {e.icon}
              <Typography type="h5" color={(theme as any).global.colors.white}>
                {t(e.title)}
              </Typography>
            </Box>
          ))}
          {list[1].map((e) => (
            <Box align="center" width="80%" key={e.title}>
              {e.icon}
              <Typography type="h5" color={(theme as any).global.colors.white}>
                {t(e.title)}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Section>
  );
};

export default WhyBanner;
