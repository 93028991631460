import { Searchbar, SearchbarProps } from "@ewibecom/design-system";
import { Control, Controller } from "react-hook-form";

export interface ControlledSearchbarProps extends Partial<SearchbarProps> {
  control: Control<any, any>;
}

const ControlledSearchbar: React.FC<ControlledSearchbarProps> = (props) => {
  const { control, ...rest } = props;

  return (
    <Controller
      name={props.name || (props.placeholder as string)}
      control={control}
      render={({ field }) => (
        <Searchbar
          name={field.name}
          placeholder={props.placeholder}
          onBlur={field.onBlur}
          setValue={(v) => {
            field.onChange(v);
          }}
          value={field.value}
          {...rest}
        />
      )}
    />
  );
};

export default ControlledSearchbar;
