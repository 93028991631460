import {
  AddBankAccountRequest,
  Address,
  PersonalData,
  PortfolioPerformance,
  UploadKYCRequest,
  User,
  Wallet,
  Wine,
} from "@ewibecom/sdk";
import { EwibeSDK as sdk } from "config";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { AsyncState, defaultAsyncState } from "./utils";

interface AsyncStore {
  setUser: (user: User) => void;
  getInfo: () => Promise<User | false>;
  getPerformance: () => Promise<PortfolioPerformance[] | false>;
  addFavourite: (id: number) => Promise<boolean>;
  setFavourites: (favourites: Wine[]) => void;
  setOwned: (owned: Wine[]) => void;
  removeFavourite: (id: number) => Promise<boolean>;
  reset: () => void;
  setPersonalData: (data: PersonalData) => Promise<boolean>;
  setPromoCode: (code: string) => Promise<boolean>;
  setAddress: (address: Address) => Promise<void | any>;
  setUploadKYC: (kyc: UploadKYCRequest) => Promise<void | any>;
  setBankAccount: (bank: AddBankAccountRequest) => Promise<void | any>;
  loadMoneyMangoPay: (
    currency: "EUR" | "USD",
    amountCents: number
  ) => Promise<string>;
  createPayout: (
    currency: "EUR" | "USD",
    amountCents: number
  ) => Promise<boolean>;
}

export interface UserStore extends AsyncState, AsyncStore {
  user?: User;
  favourites?: Wine[];
  owned?: Wine[];
  performance?: PortfolioPerformance[];
}

export const useUserStore = create<UserStore>()(
  devtools(
    persist(
      (set) => ({
        error: null,
        isLoading: false,
        setUser: (user: User) => set({ user }),
        getInfo: async (): Promise<User | false> => {
          try {
            set({ isLoading: true });
            let user = await sdk.user.info();
            set({
              user,
              error: null,
              isLoading: false,
            });
            return user;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        getPerformance: async (): Promise<any | false> => {
          try {
            set({ isLoading: true });
            let performance = await sdk.user.getPerformance();
            set({
              performance,
              error: null,
              isLoading: false,
            });
            return performance;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        addFavourite: async (id: number): Promise<boolean> => {
          try {
            set({ isLoading: true });
            await sdk.user.addFavourite(id);
            set((state) => ({
              user: {
                ...(state.user as User),
                favourites: state.user?.favourites?.concat(id),
              },
              favourites: [],
              error: null,
              isLoading: false,
            }));
            return true;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        removeFavourite: async (id: number): Promise<boolean> => {
          try {
            set({ isLoading: true });
            await sdk.user.removeFavourite(id);
            set((state) => ({
              user: {
                ...(state.user as User),
                favourites: state.user?.favourites?.filter((f) => f !== id),
              },
              favourites: [],
              error: null,
              isLoading: false,
            }));
            return true;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        setFavourites: (favourites: Wine[]) => set({ favourites }),
        setOwned: (owned: Wine[]) => set({ owned }),
        reset: () => set({ ...defaultAsyncState, user: undefined }),
        setPersonalData: async (data: PersonalData): Promise<boolean> => {
          try {
            set({ isLoading: true });
            await sdk.user.setPersonalData({
              ...data,
              birthdate: new Date(data.birthdate).getTime(),
            });
            set((state) => ({
              user: {
                ...(state.user as User),
                name: data.name ? data.name : (state.user?.name as string),
                family_name: data.family_name
                  ? data.family_name
                  : (state.user?.family_name as string),
                // FIXME: User birthdate is number to turns out being a string
                birthdate: new Date(
                  data.birthdate
                ).toLocaleDateString() as unknown as number,
                gender: data.gender,
                phone_number: data.phone_number,
              },
              error: null,
              isLoading: false,
            }));
            return true;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        setPromoCode: async (code: string): Promise<boolean> => {
          try {
            set({ isLoading: true });
            await sdk.user.setPromoCode(code);
            set((state) => ({
              user: {
                ...(state.user as User),
              },
              error: null,
              isLoading: false,
            }));
            return true;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        setAddress: async (address: Address): Promise<boolean> => {
          try {
            set({ isLoading: true });
            await sdk.user.setAddress(address);
            set((state) => ({
              user: {
                ...(state.user as User),
                address,
              },
              error: null,
              isLoading: false,
            }));
            return true;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        setUploadKYC: async (kyc: UploadKYCRequest): Promise<boolean> => {
          try {
            set({ isLoading: true });
            await sdk.user.uploadKYC(kyc);
            set((state) => ({
              user: {
                ...(state.user as User),
                wallet: {
                  ...(state?.user?.wallet as Wallet),
                  ewallet: {
                    ...state?.user?.wallet?.ewallet,
                    kycUploadState: 1,
                  },
                },
              },
              error: null,
              isLoading: false,
            }));
            return true;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        setBankAccount: async (
          bank: AddBankAccountRequest
        ): Promise<void | any> => {
          try {
            set({ isLoading: true });
            await sdk.user.addBankAccount(bank);
            set((state) => ({
              user: {
                ...(state.user as User),
                wallet: {
                  ...(state?.user?.wallet as Wallet),
                  bank: {
                    ...state?.user?.wallet?.bank,
                    name: bank.bank_name,
                    account: bank.bank_account,
                    country: bank.bank_country,
                  },
                },
              },
              error: null,
              isLoading: false,
            }));
            return true;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        loadMoneyMangoPay: async (
          currency: "EUR" | "USD",
          amountCents: number
        ): Promise<string | any> => {
          try {
            set({ isLoading: true });
            let resp = await sdk.user.loadMoneyMangoPay(currency, amountCents);
            set({ isLoading: false });
            return resp;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
        createPayout: async (
          currency: "EUR" | "USD",
          amountCents: number
        ): Promise<boolean> => {
          try {
            set({ isLoading: true });
            await sdk.user.createPayout(currency, amountCents);
            set({ isLoading: false });
            return true;
          } catch (error) {
            set({ error, isLoading: false });
            return false;
          }
        },
      }),
      {
        name: "ewibe-user-store",
        partialize: (state) => ({
          user: state.user,
          favourites: state.favourites,
          owned: state.owned,
          performance: state.performance,
        }),
      }
    )
  )
);
