import { Portfolio } from "@ewibecom/sdk";
import useStore, { PortfolioStore, TradingStore } from "store";

const useDeleteOrder = () => {
  const { deleteOrder } = useStore((state) => state.trading) as TradingStore;
  const { portfolio, setPortfolio } = useStore((state) => state.portfolio) as PortfolioStore;

  return async (id: number): Promise<boolean> => {
    try {
      const resp = await deleteOrder(id);
      if (resp) {
        setPortfolio({
          ...portfolio,
          result: {
            ...portfolio?.result,
            orders: portfolio?.result.orders.filter(o => o.order_id !== id)
          }
        } as Portfolio);
      }
      return resp;
    } catch (error) {
      return false;
    }
  }
}

export { useDeleteOrder };
