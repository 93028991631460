import useStore, { AuthStore, MarketingStore, PortfolioStore, PreferencesStore, UserStore, WineStore } from "store";

// useLogout hook wraps auth.logout and all domains specific reset methods to fully
// clean up the store on logout
const useLogout = () => {
  const { logout } = useStore((state) => state.auth) as AuthStore;
  const { reset: portfolioReset } = useStore((state) => state.portfolio) as PortfolioStore;
  const { reset: preferencesReset } = useStore((state) => state.preferences) as PreferencesStore;
  const { reset: userReset } = useStore((state) => state.user) as UserStore;
  const { reset: wineReset } = useStore((state) => state.wine) as WineStore;
  const { reset: marketingReset } = useStore((state) => state.marketing) as MarketingStore;


  return async (): Promise<boolean> => {
    try {
      const result = await Promise.allSettled([
        logout(),
        portfolioReset(),
        preferencesReset(),
        userReset(),
        wineReset(),
        marketingReset(),
      ]);

      return !result.some(result => result.status === "rejected");
    } catch (error) {
      return false;
    }
  }
}

export { useLogout };
