import { Box, Dropdown, DropdownProps } from "@ewibecom/design-system";
import "flag-icons/css/flag-icons.min.css";
import { useIsSmall } from "hooks";
import { useCallback } from "react";
import { useTheme } from "styled-components";
import type { ThemeType } from "grommet";
import {
  Actions,
  Languages,
  useTranslateDispatch,
  useTranslateState,
} from "translate";

const labels = {
  it: "Italiano",
  en: "English",
};

const LanguageOption = (props: { language: Languages; small?: boolean }) => {
  const theme: ThemeType = useTheme();

  return (
    <Box
      direction="row"
      pad={props.small ? "medium" : "small"}
      background={theme.global?.colors?.background}
      color={theme.global?.colors?.text as string}
      align="center"
      style={{
        borderRadius: "0.5rem",
        gap: "0.6rem",
      }}
      fill
    >
      <span
        className={`fi fi-${props.language === "en" ? "gb" : props.language}`}
      />
      <span>{(labels as any)[props.language]}</span>
    </Box>
  );
};

const LanguageSelector: React.FC<Partial<DropdownProps>> = (props) => {
  const dispatch = useTranslateDispatch();
  const language = useTranslateState();
  const small = useIsSmall();

  const changeLanguage = useCallback(
    (lang: Languages) => {
      dispatch({
        type: Actions.CHANGE_LANGUAGE,
        language: lang,
      });
    },
    [dispatch]
  );

  return (
    <Dropdown
      options={["it", "en"]}
      defaultValue={"it"}
      value={language}
      valueLabel={<LanguageOption language={language} small={small} />}
      onChange={({ option }) => changeLanguage(option)}
      width={props.width || "100%"}
    >
      {(option): React.ReactNode => {
        if (option === language) return <></>;
        return <LanguageOption language={option} small={small} />;
      }}
    </Dropdown>
  );
};

export { LanguageSelector };
