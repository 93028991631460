import { mountStoreDevtool } from 'simple-zustand-devtools';
import { AppStore, useAppStore } from './app';
import { AuthStore, useAuthStore } from './auth';
import { MarketingStore, useMarketingStore } from './marketing';
import { PortfolioStore, usePortfolioStore } from './portfolio';
import { PreferencesStore, usePreferencesStore } from './preferences';
import { TradingStore, useTradingStore } from './trading';
import { UserStore, useUserStore } from './user';
import { WineStore, useWineStore } from './wine';

export interface Store {
  app: typeof useAppStore;
  auth: typeof useAuthStore;
  portfolio: typeof usePortfolioStore;
  preferences: typeof usePreferencesStore;
  user: typeof useUserStore;
  wine: typeof useWineStore;
  trading: typeof useTradingStore;
  marketing: typeof useMarketingStore;
}

type StoreTypes = typeof useAuthStore | typeof useUserStore | typeof useAppStore |
  typeof usePreferencesStore | typeof usePortfolioStore |
  typeof useWineStore | typeof useTradingStore | typeof useMarketingStore;

const useStore = (combine: ((arg: Store) => StoreTypes)): any => {
  return combine({
    app: useAppStore,
    auth: useAuthStore,
    portfolio: usePortfolioStore,
    preferences: usePreferencesStore,
    user: useUserStore,
    wine: useWineStore,
    trading: useTradingStore,
    marketing: useMarketingStore,
  })();
}

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('ewibe-store', useAuthStore);
}

export type {
  AppStore,
  AuthStore, MarketingStore, PortfolioStore,
  PreferencesStore, TradingStore, UserStore,
  WineStore
};

export default useStore;