import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { Wine } from "@ewibecom/sdk";
import useStore, { UserStore } from "store";
import { getAge, multiplierMap } from "utils";

const useFavoriteAction = () => {
  const sendDataToGTM = useGTMDispatch()
  const { user, addFavourite, removeFavourite } = useStore(
    (state) => state.user
  ) as UserStore;

  return async (wine: Wine, favorite: boolean, position?: number): Promise<boolean> => {
    const resp = await favorite ? removeFavourite(wine.id) : addFavourite(wine.id);
    if (favorite) {
      const gtmTag = {
        event: "add_to_wishlist",
        user_id: user?.id,
        age: user?.birthdate && getAge(user?.birthdate),
        item_id: wine.id,
        item_name: wine.name,
        item_position: position,
        format_bottle: wine.format,
        value: wine.tradingPrice ?? 0 * multiplierMap(wine.format),
        device: navigator.userAgent,
      };
      sendDataToGTM(gtmTag);
    }
    return resp;
  };
}

export { useFavoriteAction };
