import { Box, theme } from "@ewibecom/design-system";
import styled from "styled-components";

const Separator = styled(Box)`
  width: 100%;
  height: 0px;
  border: 1px solid ${(theme as any).global.colors.gray4};
`;

export { Separator };
