import { Box, Carousel, Typography } from "@ewibecom/design-system";
import {
  Hand,
  Index,
  Lock,
  Network,
  Question,
  Touch,
  Truck,
  VerifiedBottle,
} from "assets/icons";
import { useIsSmall } from "hooks";
import styled, { css } from "styled-components";
import useTranslate from "translate";
import { Section } from "../Section";

const list = [
  [
    {
      title: "verified_bottles",
      description: "verified_bottles_description",
      icon: <VerifiedBottle />,
    },
    {
      title: "real_prices",
      description: "real_prices_description",
      icon: <Hand />,
    },
    {
      title: "efficient_logistics",
      description: "efficient_logistics_description",
      icon: <Truck />,
    },
    {
      title: "worldwide_network",
      description: "worldwide_network_description",
      icon: <Network />,
    },
  ],
  [
    {
      title: "advanced_security",
      description: "advanced_security_description",
      icon: <Lock />,
    },
    {
      title: "expert_assistance",
      description: "expert_assistance_description",
      icon: <Question />,
    },
    {
      title: "user_friendliness",
      description: "user_friendliness_description",
      icon: <Touch />,
    },
    {
      title: "ownership_indices",
      description: "ownership_indices_description",
      icon: <Index />,
    },
  ],
];

const StyledBox = styled(Box)`
  ${({ theme }) => css`
    border-radius: 1rem;
    box-shadow: ${theme.global.boxShadow.default};
    background: ${theme.global.colors.background};
    padding: 2rem;
    display: inline-flex !important;
  `}
`;

const Circle = styled(Box)`
  ${({ theme }) => css`
    border-radius: 50%;
    background: ${theme.global.colors.white};
    width: 8rem;
    height: 8rem;
    align-items: center;
    justify-content: center;
  `}
`;

const PrinciplesBanner: React.FC = () => {
  const t = useTranslate("landing.");
  const small = useIsSmall();

  return (
    <Section small={small} align="center">
      <Typography
        type={small ? "h5" : "h3"}
        textAlign="center"
        maxWidth="100%"
        margin={{ vertical: "0" }}
      >
        {t("accessibility")}
      </Typography>
      <Typography
        type={small ? "body1" : "h5"}
        textAlign="center"
        maxWidth={small ? "100%" : "65%"}
        margin={{
          top: small ? "1rem" : "2rem",
          bottom: small ? "1rem" : "2rem",
        }}
      >
        {t("functionalities")}
      </Typography>
      {small ? (
        <Carousel arrows dots={false} show={1}>
          {list[0].map((e) => (
            <StyledBox align="center" key={e.title}>
              {e.icon}
              <Typography
                type="h5"
                textAlign="center"
                margin={{ top: "1rem", bottom: "1rem" }}
              >
                {t(e.title)}
              </Typography>
              <Typography textAlign="center">{t(e.description)}</Typography>
            </StyledBox>
          ))}
          {list[1].map((e) => (
            <StyledBox align="center" key={e.title}>
              {e.icon}
              <Typography
                type="h5"
                textAlign="center"
                margin={{ top: "1rem", bottom: "1rem" }}
              >
                {t(e.title)}
              </Typography>
              <Typography textAlign="center">{t(e.description)}</Typography>
            </StyledBox>
          ))}
        </Carousel>
      ) : (
        <>
          <Box direction="row" gap="medium">
            {list[0].map((e) => (
              <StyledBox align="center" width="25%" key={e.title}>
                <Circle>{e.icon}</Circle>
                <Typography type="h5" textAlign="center">
                  {t(e.title)}
                </Typography>
                <Typography textAlign="center">{t(e.description)}</Typography>
              </StyledBox>
            ))}
          </Box>
          <Box direction="row" gap="medium" margin={{ top: "2rem" }}>
            {list[1].map((e) => (
              <StyledBox width="25%" align="center" key={e.title}>
                <Circle>{e.icon}</Circle>
                <Typography type="h5" textAlign="center">
                  {t(e.title)}
                </Typography>
                <Typography textAlign="center">{t(e.description)}</Typography>
              </StyledBox>
            ))}
          </Box>
        </>
      )}
    </Section>
  );
};

export default PrinciplesBanner;
